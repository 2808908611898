@import "@sitestyles";

/*HISTORY-CONTACT*/

.all-contact-re{
	//height: 100%;
    overflow-y: auto;
	//max-height: calc(100% - 100px);
	padding-bottom: 20px;
	//margin-right: 10px;
}

.need_confirm_email .all-contact-re{
	//max-height: calc(100% - 179px);
}

img.copy {
	cursor: pointer;
}

.copy {
	cursor: pointer;
}

.all-contact-re::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}
.all-contact-re::-webkit-scrollbar{
	width: 3px;
	background: $littleGreyLittleBlueColor;
	border-radius: 5px;
	margin-right: 3px;
}
.first-link-show .filter {
    display: flex;
    align-items: center;
    padding-left: 26px;
	padding-top: 30px;
	padding-bottom: 25px;
}
.first-link-show .filter div{
    padding: 8px;
    font-weight: 600;
    font-size: 18px;
	cursor: pointer;
    margin-left: 8px;
    border: 1px solid transparent;
    color: $primaryColor;
    transition: 0.5s;
}
.first-link-show .filter .active {
    border: 1px solid $primaryColor;
    border-radius: 5px;
}
.first-link-show .filter div:hover {
    border: 1px solid $primaryColor;
    border-radius: 5px;
	cursor: pointer;
}
.first-link-show .filter p{
    font-weight: 600;
    font-size: 18px;
    margin-right: 12px;
    color: $accentLeftColor;
}
.all-contact-re .all-info {
    display: flex;
    flex-direction: column;
}
.all-contact-re .all-humens {
    display: flex;
    flex-direction: column;
    //padding-left: 26px;
    //padding-right: 11px;
}
.all-contact-re .all-humens::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 7px;
}
.all-contact-re .all-humens::-webkit-scrollbar{
	width: 3px;
	background: $littleGreyLittleBlueColor;
	border-radius: 5px;
	margin-right: 3px;
}
.all-contact-re .humen {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 22px;
    background: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
}
.all-contact-re .humen:not(:first-child){
    margin-top: 18px;
}
.all-contact-re .humen .avatarInfo {
    display: flex;
    align-items: center;
    width: 280px;
    max-width: 100%;
    padding-bottom: 7px;
    padding-top: 6px;
	flex: none;
}
.all-contact-re .humen .avatarInfo .id{
	color: $arsenicColor;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 4px;
}
.all-contact-re .humen .avatarInfo h3{
    font-weight: bold;
    font-size: 18px;
    color: $primaryColor;
}
.all-contact-re .humen .avatarInfo .country{
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.all-contact-re .humen .avatarInfo .country p{
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
	color: $arsenicColor;
}
.all-contact-re .humen .avatarInfo .country img{
    width: 20px;
    height: 14px;
    margin-right: 6px;
    border-radius: 3px;
}
.all-contact-re .humen .avatarInfo .avatar{
    width: 72px;
    max-width: 100%;
    position: relative;
    margin-right: 13px;
}
.all-contact-re .humen .avatarInfo .avatar:after{
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    bottom: 1.6px;
    right: 6px;
    background: $onlineColor;
    border-radius: 50%;
    border: 1.6px solid $whiteColor;
}
.all-contact-re .humen .avatarInfo .avatar img{
    width: 100%;
    border-radius: 50%;
	max-height:72px;

}
.all-contact-re .humen .requestDate{
    width: 277px;
    max-width: 100%;
    display: flex;
    align-items: center;
}
.all-contact-re .humen .stnPar{
    color: $defaultTextColorAlpha8;
    font-weight: 500;
    font-size: 16px;
    margin-right: 30px;
}.all-contact-re .humen .someDate{
    color: $stormGreyColor;
    font-weight: 600;
    font-size: 16px;
}
.all-contact-re .humen .status{
    width: 200px;
    max-width: 100%;
    display: flex;
    align-items: center;
	flex: none;
}
.all-contact-re .humen .nameStatus{
    color: $primaryColor;
    font-weight: 600;
    font-size: 18px;
    margin-right: 14px;
    text-transform: uppercase;
}.all-contact-re .humen .someStatus{
    font-weight: 600;
    font-size: 18px;
}
.all-contact-re .humen .appr{
    color: $onlineColor;
}
.all-contact-re .humen .decl{
    color: $counterAndErrorColor;
}
.all-contact-re .humen .proc{
    color: $accentLeftColor;
}
.all-contact-re .humen .emailInfo{
	width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 32px;
	margin-top: 20px;
}

.all-contact-re .humen .emailInfo .email{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.all-contact-re .humen .emailInfo .requestDate{
    width: 158px;
    max-width: 100%;
	margin-right: 0;
}

.copy2:not(:has(> svg)) {
	position: relative;
	top: 7px;
}

.copy2:has(> svg) {
	display: inline-block;
}

.copy2 svg {
	position: relative;
	top: 7px;
	width: 32px;
	height: 32px;
	fill: #446178;
	margin-right: 4px;
}

.all-contact-re .humen .emailInfo .whoEmail {
    font-weight: 600;
    font-size: 18px;
    color: $primaryColor;
    width: 190px;
    max-width: 100%;
	width: 230px;
	position: relative;
	//bottom: 7px;
}
.all-contact-re .humen .emailInfo .someEmail {
    font-weight: 600;
    font-size: 16px;
    color: $stormGreyColor;
}
.all-contact-re .humen .emailInfo .copy{
    width: 17px;
	height: 17px;
	fill: #6F0267;
	margin-left: 20px;
}

.all-contact-re .humen .bigText{
    width: 454px;
    max-width: 100%;
}
.all-contact-re .humen .bigText h4{
    color: $primaryColor;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 6px;
}
.all-contact-re .humen .bigText p{
    font-weight: normal;
    font-size: 14px;
    color: $defaultTextColorAlpha8;
}


.meetingRequestItemRight{
	display: flex;
	flex-wrap: wrap;
}

@media screen and (max-width: 1899px){
	.all-contact-re .humen .requestDate{
		width: 245px;
	}
}

@media screen and (max-width: 1849px){
	.humen_right_block{
		flex-wrap: wrap;
	}

	.all-contact-re .humen .requestDate{
		width: 50%;
	}

	.all-contact-re .humen .status{
		width: 50%;
	}

	.all-contact-re .humen .emailInfo{
		width: 100%;
		padding-right: 0;
	}

	.all-contact-re .humen .emailInfo .requestDate{
		//width: 50%;
		margin-bottom: 0;
		//margin-top: 23px;
	}

	.all-contact-re .humen .emailInfo .email{
		//width: 50%;
		//display: flex;
		//align-items: center;
		//margin-top: 23px;
	}

	.all-contact-re .humen .emailInfo .whoEmail{
		position: static;
		width: auto;
		margin-right: 19px;
	}

	.all-contact-re .humen .bigText{
		width: 100%;
		margin-top: 23px;
	}

	.all-contact-re .humen{
		height: 137px;
	}

	.all-contact-re .humen .emailInfo .someEmail{

	}

	.all-contact-re{
		//max-height: calc(100% - 113px);
	}
}

@media screen and (max-width: 1599px){
	.all-contact-re .humen .emailInfo{
		flex-wrap: wrap;
	}

	.all-contact-re .humen .emailInfo .email{
		width: calc(100% - 37px);
		margin-top: 10px;
	}
}

@media screen and (max-width: 1399px){
	.all-contact-re .humen{
		flex-wrap: wrap;
		height: auto;
	}

	.all-contact-re .humen .avatarInfo{
		width: 100%;
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 599px){
	.all-contact-re .humen .requestDate,
	.all-contact-re .humen .status,
	.all-contact-re .humen .emailInfo .requestDate{
		width: 100%;
	}

	.all-contact-re .humen .requestDate{
		margin-bottom: 16px;
	}

	.all-contact-re .humen .emailInfo .requestDate{
		margin-top: 16px;
	}

	.all-contact-re .humen .emailInfo .email{
		margin-top: 16px;
	}

	.first-link-show .filter{
		flex-wrap: wrap;
		padding: 0;
	}

	.all-contact-re .humen .stnPar{
		font-size: 14px;
		margin-right: 20px;
	}

	.all-contact-re .humen .someDate{
		font-size: 14px;
	}

	.all-contact-re .humen .nameStatus{
		font-size: 14px;
	}

	.all-contact-re .humen .someStatus{
		font-size: 14px;
	}

	.all-contact-re .humen .emailInfo .whoEmail{
		font-size: 14px;
		width: 100%;
	}

	.all-contact-re .humen .avatarInfo .avatar{
		width: 50px;
	}

	.all-contact-re .humen .avatarInfo .avatar:after{
		width: 10px;
		height: 10px;
		right: 2px;
		bottom: 2px;
		border-width: 2px;
	}

	.all-contact-re .humen .avatarInfo .id{
		font-size: 10px;
	}

	.all-contact-re .humen .avatarInfo h3{
		font-size: 14px;
	}

	.all-contact-re .humen .avatarInfo .country p{
		font-size: 10px;
	}

	.all-contact-re .humen .bigText{
		margin-top: 16px;
	}

	.all-contact-re .humen .avatarInfo .country img{
		width: auto;
		height: 10px;
	}
}

@media screen and (max-width: 360px){
	.all-contact-re .humen .emailInfo .email{
		display: block;
	}

	//.all-contact-re .humen .emailInfo .someEmail{
	//	max-width: 194px;
	//	height: 19px;
	//	overflow: hidden;
	//	text-overflow: ellipsis;
	//}
}

/*
@media screen and (max-width: 1699px){
	.all-contact-re .humen .requestDate,
	.all-contact-re .humen .emailInfo .requestDate{
		width: 50%;
	}

	.all-contact-re .humen .emailInfo{
		width: 100%;
		padding-right: 0;
	}
	.all-contact-re .humen .emailInfo{
		margin-top: 31px;
		justify-content: flex-start;
	}

	.all-contact-re .humen{
		padding: 50px 24px 50px 41px;
	}

	.all-contact-re .humen .bigText{
		width: 100%;
		margin-top: 31px;
	}

	.all-contact-re .humen .avatarInfo{
		flex: none;
	}

}
@media screen and (max-width: 1440px){
    .all-contact-re .humen .emailInfo{
    }
    .all-contact-re .humen .bigText{
    }


}
@media screen and (max-width: 906px){
    .all-contact-re .humen .emailInfo{
        margin-top: 10px;
    }
    .all-contact-re .humen .bigText{
        margin-top: 10px;
    }
}
@media screen and (max-width: 530px){
    .all-contact-re .humen .emailInfo{
        margin-top: 0;
    }
    .all-contact-re .humen .bigText{
        margin-top: 0;
    }
    .all-contact-re .humen {
        display: flex;
        flex-direction: column;
    }
    .all-contact-re .humen .mainBlock{
        margin-top: 10px;
        width: 100%;
    }
    .all-contact-re .humen .emailInfo{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .all-contact-re .humen .email{
        margin-top: 10px;
    }
    .all-contact-re .filter {
        display: flex;
        flex-wrap: wrap;
    }
}
*/
