@import "@sitestyles";

.c3_head_btn{
  padding: 0 14px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #fff;
  margin-left: 16px;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
}

.c3_head_btn:hover{
  background-color: rgba(255, 255, 255, 0.3);
}

.c3_head_btn:first-child{
  margin-left: 0;
}

.c3_head_btn.wink,
.c3_head_btn.like{
  border: 1px solid #fff;
  opacity: .7;
}

.c3_head_btn.like{
  margin-left: 31px;
}

.c3_head_btn.wink:hover,
.c3_head_btn.like:hover{
  opacity: 1;
}

.c3_head_btn.wink .c3_head_btn_img,
.c3_head_btn.like .c3_head_btn_img{
  opacity: 1;
}

.c3_head_btn.wink .c3_head_btn_info,
.c3_head_btn.like .c3_head_btn_info{
  opacity: 1;
}

.c3_head_btn.favs.active .c3_head_btn_img{
  opacity: 1;
}

.c3_head_btn_img{
  opacity: .7;
  margin-right: 6px;
}

.c3_head_btn_img svg {
  fill: #FFFFFF;
  vertical-align: middle;
}

.c3_head_btn.favs .c3_head_btn_img svg {
  width: 20px;
  height: 20px;
}

.c3_head_btn.letter .c3_head_btn_img svg {
  width: 24px;
  height: 24px;
}

.c3_head_btn.favs.active .c3_head_btn_img svg {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
  stroke: #FFFFFF;
  vertical-align: middle;
}

.c3_head_btn:hover .c3_head_btn_img{
  opacity: 1;
}

.c3_head_btn:hover .c3_head_btn_img svg{
  opacity: 1;
}

.c3_head_btn_info{
  opacity: .7;
}

.c3_head_btn:hover .c3_head_btn_info{
  opacity: 1;
}

.c3_head_more_menu{
  position: relative;
  margin-left: 16px;
}

.c3_head_more_menu_open_btn{
  padding: 0 14px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #fff;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  opacity: .7;
}

.c3_head_more_menu_open_btn:hover,
.c3_head_more_menu_open_btn.active{
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

.c3_head_more_menu_open_btn img,
.c3_head_more_menu_open_btn svg {
  margin-right: 16px;
}
.c3_head_more_menu_open_btn svg {
  width: 4px;
  height: 18px;
  fill: #FFFFFF;
  vertical-align: middle;
}


.c3_head_more_menu_list_wrap{
  z-index: 9999;
  width: 169px;
  position: absolute;
  top: calc(100% + 12px);
  right: 15px;
  border: 1px solid #E9EBEE;
  box-shadow: $smallBtnShadowHover;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px;
  display: none;
}

.c3_head_more_menu_list_wrap.active{
  display: block;
}

.c3_head_more_menu_list_wrap:before{
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  position: absolute;
  right: 9px;
  top: -8px;
  background: linear-gradient(45deg, transparent, transparent 50%, #E9EBEE 50%, #E9EBEE);
  transform: rotate(-45deg);
}

.c3_head_more_menu_list_wrap:after{
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  top: -7px;
  background-color: #fff;
  transform: rotate(45deg);
}

.c3_head_more_btn{
  width: 161px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 7px;
  position: relative;
  border-radius: 5px;
}

.c3_head_more_btn:after{
  content: '';
  display: block;
  width: 143px;
  height: 1px;
  position: absolute;
  top: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #E9EBEE;
}

.c3_head_more_btn:last-child{
  margin-bottom: 0;
}

.c3_head_more_btn:hover{
  background-color: #E3ECFC;
  z-index: 2;
}

.c3_head_more_btn_info{
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.06em;
  color: $accentRightColor;
}

.c3_head_more_btn img{
  margin-right: 8px;
  width: 20px;
}

.c3_head_more_btn.letter{
  display: none;
}

.c3_head_more_btn.favs{
  display: none;
}

.c3_head_more_btn.attention svg {
  width: 20px;
  height: 20px;
  fill: #f26f6f;
  margin-right: 8px;
}

.c3_head_more_btn.block svg {
  width: 20px;
  height: 20px;
  fill: $iconsMoreInfoChatHeaderFillColor;
  margin-right: 8px;
}

.c3_head_more_btn.favs svg {
  width: 20px;
  height: 20px;
  fill: $iconsMoreInfoChatHeaderFillColor;
  stroke: $iconsMoreInfoChatHeaderFillColor;
  stroke-width: 0.3;
  margin-right: 8px;
}

.c3_head_more_btn.favs.active svg {
  stroke-width: 1;
}

.c3_head_more_btn.letter svg {
  width: 22px;
  height: 22px;
  fill: $iconsMoreInfoChatHeaderFillColor;
  margin-right: 8px;
  margin-left: -1px;
}

@media screen and (max-width: 1799px){

  .c3_head_btn{
    margin-left: 5px;
  }

  .c3_head_more_menu{
    margin-left: 8px;
  }

  .c3_head_btn.like{
    margin-left: 16px;
  }
}

@media screen and (max-width: 1349px){
  .c3_head_more_btn.favs.both_visible{
    display: flex;
  }

  .c3_head_btn.favs.both_visible{
    display: none;
  }

  .c3_head_btn.letter.one_visible{
    display: none;
  }

  .c3_head_more_btn.letter.one_visible{
    display: flex;
  }
}

@media screen and (max-width: 1139px){
  .c3_head_btn.wink,
  .c3_head_btn.like{
    display: none;
  }

  .c3_head_btn.favs.both_visible,
  .c3_head_btn.favs.one_visible,
  .c3_head_btn.favs.none_visible{
    display: flex;
  }

  .c3_head_more_btn.favs.both_visible,
  .c3_head_more_btn.favs.one_visible,
  .c3_head_more_btn.favs.none_visible{
    display: none;
  }
}

@media screen and (max-width: 599px){

  .c3_head_btn.favs.both_visible,
  .c3_head_btn.favs.one_visible,
  .c3_head_btn.favs.none_visible{
    display: flex;
    flex-direction: column;
    font-size: 10px;
    padding: 0;
    width: 44px;
    height: 44px;
  }

  .c3_head_btn.letter.both_visible,
  .c3_head_btn.letter.one_visible,
  .c3_head_btn.letter.none_visible{
    display: none;
  }

  .c3_head_more_btn.letter.both_visible,
  .c3_head_more_btn.letter.one_visible,
  .c3_head_more_btn.letter.none_visible{
    display: flex;
  }

  .c3_head_btn:hover{
    background-color: transparent;
  }

  .c3_head_btn_img{
    margin-right: 0;
  }

  .c3_head_btn_img svg{
    margin-right: 0;
    margin-bottom: 5px;
  }

  .c3_head_more_menu_open_btn{
    display: flex;
    flex-direction: column;
    font-size: 10px;
    padding: 0;
    width: 44px;
    height: 44px;
  }

  .c3_head_more_menu_open_btn:hover{
    background-color: transparent;
  }

  .c3_head_more_menu_open_btn img,
  .c3_head_more_menu_open_btn svg {
    margin: 2px auto 5px;
    display: block;
  }

  .c3_head_more_menu_list_wrap{
    right: 4px;
  }
}
