@import '@sitestyles';

.c3_write_msg {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 27px;
    border-top: 2px solid #f3f4fc;
}

.chat_message_counter {
    width: 40px;
    text-align: center;
    position: absolute;
    top: 92px;
    left: calc(50% - 20px);
    font-size: 12px;
    color: rgba(51, 51, 51, 0.3);
    font-weight: 400;
    z-index: 2;

    @media screen and (max-width: 1139px) {
        top: 65px;
    }

    @media screen and (max-width: 599px) {
        top: 75px;
        font-size: 11px;
    }
}

.c3_attach {
    height: 40px;
    padding: 0 16px;
    //margin-left: -10px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    grid-area: c3_attach;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: 0.3s;
    color: $accentLeftColor;
    font-size: 12px;
    font-weight: 600;
    background: #fff;
}

.c3_attach:hover {
    background-color: $buttonHoverBg3;
}

.c3_attach_img {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: $accentLeftColor;
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    mask: url(../../img/attach-ico-blue.svg) center no-repeat;
    mask-size: contain;
    -webkit-mask: url(../../img/attach-ico-blue.svg) center no-repeat;
    -webkit-mask-size: contain;
    margin-right: 8px;
}

.c3_attach_text {
    //font-size: 12px;
    //letter-spacing: -0.01em;
    color: $accentLeftColor;
    //position: absolute;
}

.c3_chat_wrap .c3_attach_text {
    width: max-content;
    position: static;
    //margin-left: 13px;
}

.chat_hello_like_btn {
    width: fit-content;
    padding: 0 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $mainFont;
    border: 1px solid $accentLeftColor;
    border-radius: 5px;
    font-weight: 600;
    background: #fff;
    font-size: 12px;
    box-sizing: border-box;
    color: $accentLeftColor;
    cursor: pointer;
    grid-area: c3_msg_like;
}

.chat_hello_like_btn + .chat_hello_like_btn {
    margin-left: 26px;
}

.chat_hello_like_btn:hover {
    box-shadow: $searchItemLikeBtnShadowHover;
}

.chat_hello_btn_img {
    width: 21px;
    height: 23px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: $chatHello;
    margin-right: 11px;
}

.chat_like_btn_img {
    width: 24px;
    height: 24px;
    background: $accentLeftColor;
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    mask: url(../../img/chat-like.svg) center no-repeat;
    mask-size: contain;
    margin-right: 8px;
}

.c3_textaera_wrap {
    font-family: $mainFont;
    display: block;
    resize: none;
    width: 100%;
    height: 66px;
    margin-bottom: 20px;
    background-color: $chatTextareaBg;
    border-radius: 8px;
    border: none;
    padding: 16px 16px 16px 18px;
    font-size: 16px;
    line-height: 150%;
    color: $greyColor;
    outline: none;
    grid-area: c3_text;
}

.c3_textaera_wrap::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #fff;
}

.c3_textaera_wrap::-webkit-scrollbar-thumb {
    background: rgba(2, 49, 124, 0.6);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.c3_smile_btn_wrap {
    position: relative;
    grid-area: c3_smile;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.c3_smile_btn_wrap2 {
    position: relative;
    margin-right: 8px;
}

.c3_smile_btn_wrap2.active:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #f2f2f2;
    z-index: 2;
    border-radius: 0 6px 6px 0;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_smile_btn_wrap2.active:after {
    content: '';
    width: 13px;
    height: 13px;
    position: absolute;
    left: -20px;
    bottom: 100%;
    background-image: url(../../img/sticker-corner.svg);
}

.c3_smiles_list_wrap {
    position: absolute;
    bottom: 0px;
    right: 100%;
    width: max-content;
    background-color: #f2f2f2;
    z-index: 4;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0px 8px;
    padding: 16px;
    padding-right: 9px;
    display: none;
}

.c3_smile_btn_wrap2.active .c3_smiles_list_wrap {
    display: block;
}

.c3_smile_btn {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    padding: 0 16px;
    font-size: 12px;
    //letter-spacing: -0.01em;
    font-weight: 600;
    color: $chatItemBtnColor;
    border-radius: 4px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.c3_smile_btn:hover {
    background-color: $buttonHoverBg3;
}

.c3_smile_btn .c3_smile_btn_img {
    background-color: $chatItemBtnColor;
    mask: url(../../img/c3-msg-smile.svg) center no-repeat;
    mask-size: contain;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    margin-right: 8px;
}

.c3_sticker_btn {
    width: fit-content;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    font-size: 12px;
    //letter-spacing: -0.01em;
    color: $chatItemBtnColor;
    font-weight: 600;
    border-radius: 4px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.c3_sticker_btn:hover {
    background-color: $buttonHoverBg3;
}

.c3_sticker_btn_img {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    margin-right: 8px;
    background: $chatItemBtnColor;
    mask: url(../../img/c3-msg-sticker-violet.svg) center no-repeat;
    mask-size: contain;
}

.c3_smile_btn_wrap.active .c3_sticker_btn {
    display: block;
}

.c3_sticker_wrap.active:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #f2f2f2;
    z-index: 2;
    border-radius: 0 6px 6px 0;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3_sticker_wrap.active:after {
    content: '';
    width: 13px;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 100%;
    background-image: url(../../img/sticker-corner.svg);
}

.c3_stickers_list_wrap {
    position: absolute;
    bottom: 56px;
    right: 0;
    width: max-content;
    background-color: #f2f2f2;
    padding: 0 9px 0 16px;
    z-index: 4;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 8px;
    text-align: center;

    @media screen and (max-width: 1399px) {
        bottom: 56px;
        right: -100px;
    }

    @media screen and (max-width: 1139px) {
        left: 100%;
    }
}

.c3_send {
    grid-area: c3_send;
    margin-left: 8px;
}

.c3_send_btn {
    width: fit-content;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    //letter-spacing: 0.01em;
    color: #fff;
    background: $backgroundButtonColor;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.c3_send_btn:hover {
    box-shadow: $smallBtnShadowHover;
}

.c3_send_btn img {
    display: block;
    margin-right: 8px;
}

.c3_send_btn svg {
    fill: #ffffff;
    width: 25px;
    height: 24px;
    margin-right: 8px;
}

.c3_chat_send_btn_cost_text {
    position: absolute;
    right: 0;
    top: calc(100% + 6px);
    font-size: 11px;
    color: $defaultTextColorAlpha3;
    width: max-content;
    text-align: center;
    font-weight: 400;
    pointer-events: none;
}

.c3_write_msg_like,
.c3_write_msg_wink {
    display: none;
}

@media screen and (max-width: 1749px) and (min-width: 1650px) {
    .chat_hello_like_btn + .chat_hello_like_btn {
        //width: 150px;
        //margin-left: 10px;
    }

    //.c3_chat_wrap .c3_attach_text {
    //  margin-left: 8px;
    //}

    //.c3_attach {
    //  margin-right: 16px;
    //  height: 55px;
    //}
    //
    //.c3_attach {
    //  flex-direction: column;
    //  justify-content: center;
    //  margin-right: 16px;
    //}

    //.c3_chat_wrap .c3_attach_text {
    //  margin-left: 0;
    //  margin-top: 10px;
    //}
}

@media screen and (max-width: 1599px) {
    .c3_write_msg {
        padding: 26px 16px 22px;
    }
    //.chat_hello_like_btn {
    //  width: 130px;
    //  height: 40px;
    //}
    //
    //.chat_hello_like_btn + .chat_hello_like_btn {
    //  margin-left: 16px;
    //}

    .chat_hello_btn_img {
        width: 18px;
        height: 20px;
    }

    //.chat_like_btn_img {
    //  width: 18px;
    //  height: 16px;
    //}

    //.c3_attach {
    //  flex-direction: column;
    //  justify-content: center;
    //  margin-right: 16px;
    //  height: 50px;
    //}
    //
    //.c3_chat_wrap .c3_attach_text {
    //  margin-left: 0;
    //  margin-top: 10px;
    //}

    .c3_smile_btn_wrap2 {
        margin-right: 8px;
    }

    //.c3_send {
    //  margin-left: 16px;
    //}

    .c3_textaera_wrap {
        padding: 10px 16px;
    }
}

@media screen and (max-width: 1465px) {
    .c3_smile_btn {
        flex-direction: column;
        //width: 50px;
        height: 55px;
        padding: 0 8px;
    }

    .c3_smile_btn .c3_smile_btn_img {
        margin-right: 0;
        //margin-bottom: 3px;
    }

    .c3_sticker_btn {
        flex-direction: column;
        //width: 50px;
        height: 55px;
        padding: 0 8px;
    }

    .c3_sticker_btn .c3_sticker_btn_img {
        margin-right: 0;
        //margin-bottom: 3px;
    }
}

@media screen and (max-width: 1465px) and (min-width: 1140px) {
    .c3_attach {
        //width: 50px;
        height: 55px;
        flex-direction: column;
        margin-right: 8px;
        padding: 0 8px;
        justify-content: center;
        align-items: center;
    }

    .c3_attach .c3_attach_img {
        margin-right: 0;
        margin-bottom: 3px;
    }
}

@media screen and (max-width: 1249px) {
    .chat_hello_like_btn {
        //width: 115px;
    }

    //.chat_hello_like_btn + .chat_hello_like_btn {
    //  margin-left: 8px;
    //}

    .c3_send {
        margin-left: 8px;
    }
}

@media screen and (max-width: 1139px) {
    .c3_write_msg {
        height: max-content;
        display: grid;
        //grid-template-areas:
        //		"c3_text c3_text c3_text c3_text c3_text"
        //		"c3_smile c3_attach c3_msg_wink c3_msg_like c3_send";
        grid-template-areas:
            'c3_smile c3_text c3_text c3_text c3_text c3_text'
            'c3_attach c3_attach c3_msg_wink c3_msg_like c3_send c3_send';
        grid-template-columns: 46px auto auto 1fr auto; /* 70px auto auto 1fr auto  when stickers*/
        grid-row-gap: 8px;
        align-items: center;
        padding: 14px 25px;
    }

    .c3_write_msg_wink {
        grid-area: c3_msg_wink;
        align-self: center;
    }

    .c3_write_msg_like {
        grid-area: c3_msg_like;
        align-self: center;
    }

    .c3_write_msg_like,
    .c3_write_msg_wink {
        width: 104px;
        height: 38px;
        border: 1px solid #6f0267;
        border-radius: 5px;
        //display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        letter-spacing: -0.01em;
        color: #6f0267;
        cursor: pointer;
        margin-left: 17px;
    }

    .c3_write_msg_like img,
    .c3_write_msg_wink img {
        margin-right: 9px;
    }

    .c3_smile_btn_wrap {
        margin: 0;
        height: 100%;
        display: flex;
        //flex-direction: column;
        justify-content: space-evenly;
        background-color: #e8eef8;
        border-radius: 8px 0 0 8px;
        border: 1px solid #d6dfee;
        width: 87px;
    }

    .c3l_letters_list_write_bottom .c3_smile_btn_wrap {
        border: none;
        background-color: transparent;
    }

    .c3l_letters_list_write_area .mob-smiles {
        width: 100% !important;
    }

    .c3_smile_btn_wrap2 {
        margin: auto 0;
        width: max-content;
    }

    .c3_smile_btn {
        width: 24px;
        height: 24px;
    }

    .c3_smile_btn .c3_smile_btn_img {
        //width: 100%;
        //height: 100%;
        //background-position: center;
        //background-size: contain;
        margin: 0;
    }

    .c3_smile_btn span {
        display: none;
    }

    .c3_sticker_wrap {
        margin: auto 0;
        width: max-content;
    }

    .c3_sticker_btn {
        width: 24px;
        height: 24px;
        margin-top: 0;
    }

    .c3_sticker_btn .c3_sticker_btn_img {
        //  width: 100%;
        //  height: 100%;
        //  // background-image: url(../../img/c3-msg-sticker-blue.svg);
        //  background-position: center;
        margin: 0;
    }

    .c3_sticker_btn span {
        display: none;
    }

    .c3_textaera_wrap {
        background-color: #e8eef8;
        border: 1px solid #d6dfee;
        border-left: none;
        border-radius: 0 8px 8px 0;
        margin-bottom: 0;
        margin-left: 41px;
        height: 44px;
        width: auto;
        font-size: 14px;
        padding: 6px 10px;
    }

    //.c3_attach {
    //
    //}

    //.c3_chat_wrap .c3_attach_text {
    //  color: $attachTouchText;
    //}

    .c3_chat_send_btn_cost_text {
        right: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%);
        width: max-content;
    }

    .c3_chat_send_btn_cost_text span {
        display: none;
    }

    .chat_hello_like_btn + .chat_hello_like_btn {
        //margin-left: 16px;
    }

    //.chat_hello_like_btn {
    //  color: $notificationFavouriteBg;
    //  border-color: $notificationFavouriteBg;
    //}

    .chat_hello_btn_img {
        //background-image: url(../../img/chat-hello-violet.svg);
    }

    .chat_like_btn_img {
        //background-image: url(../../img/chat-like-violet.svg);
    }
}

@media screen and (max-width: 599px) {
    .c3_write_msg {
        //grid-template-areas:
        //    "c3_smile c3_text c3_text c3_text"
        //    "c3_attach c3_msg_wink c3_msg_like c3_send";
        grid-template-columns: 42px auto auto 1fr;
        grid-template-rows: 67px 32px;
        //grid-template-rows: max-content max-content;
        padding: 6px 15px;
        border-top: none;
    }

    .c3_write_msg.big {
        grid-template-rows: 76px 32px;
    }

    .c3_send_btn {
        height: 32px;
        margin-left: auto;
        width: 48px;
        font-size: 10px;
        //position: unset;
    }

    .c3_send_btn img {
        display: none;
    }

    .c3_send_btn svg {
        display: none;
    }

    .c3_textaera_wrap {
        height: 67px;
        padding: 3px 3px 3px 10px;
        width: auto;
        font-size: 14px;
        line-height: 1.5;
        margin-left: 40px;
    }

    .c3_textaera_wrap::placeholder {
        line-height: 30px;
    }

    .c3_textaera_wrap.large {
        height: 76px;
        margin-left: 0;
    }

    .c3_textaera_wrap:focus::placeholder {
        line-height: 1.5;
    }

    .c3_smile_btn_wrap {
        flex-direction: row;
        flex-wrap: wrap;
        width: 84px;
    }

    .c3_textaera_wrap.large ~ .c3_smile_btn_wrap {
        width: 44px;
    }

    .c3_sticker_wrap,
    .c3_smile_btn_wrap2 {
        margin: auto 10px;
    }

    .c3_smile_btn,
    .c3_sticker_btn {
        width: 21px;
        height: 21px;
        background-size: contain;
    }

    .c3_attach {
        //width: max-content;
        //margin: 0;
        padding: 0 8px;
        //height: auto;
        flex-direction: column;
    }

    .c3_attach_img {
        //  width: 21px;
        //  height: 9px;
        margin-right: 0;
    }

    .c3_chat_wrap .c3_attach_text {
        //margin-left: 0;
        //margin-top: 3px;
        font-size: 9px;
    }

    .c3_write_msg_like,
    .c3_write_msg_wink {
        width: 60px;
        height: 27px;
        font-size: 9px;
        margin-left: 7px;
    }

    .c3_write_msg_wink {
        margin-left: 0;
    }

    .c3_write_msg_like img,
    .c3_write_msg_wink img {
        width: 16px;
        margin-right: 6px;
    }

    .chat_hello_like_btn {
        width: max-content;
        height: 32px;
        padding: 0 8px;
        font-size: 9px;
    }

    .chat_like_btn_img {
        margin-right: 4px;
    }

    .chat_hello_btn_img {
        width: 15px;
        height: 16px;
        margin-right: 4px;
    }

    //.chat_like_btn_img {
    //  width: 16px;
    //  height: 14px;
    //  margin-right: 6px;
    //}

    .chat_hello_like_btn span span {
        display: none;
    }

    .chat_hello_like_btn + .chat_hello_like_btn {
        margin-left: 7px;
    }
}

@media (orientation: landscape) {
    @media screen and (max-height: 500px) and (min-width: 500px) {
        .c3_write_msg {
            grid-template-areas: 'c3_attach c3_msg_wink c3_msg_like c3_smile c3_text c3_send';
            grid-template-columns: auto auto auto auto 1fr auto;
            grid-template-rows: 33px;
            padding: 26px 15px 6px;
            border-top: none;
        }

        .c3_send_btn {
            height: 32px;
            margin-left: 7px;
            width: 48px;
            font-size: 10px;
        }

        .c3_send_btn img {
            display: none;
        }

        .c3_textaera_wrap {
            height: 33px;
            padding: 3px;
            margin-left: 0;
            width: auto;
            font-size: 14px;
            line-height: 1.5;
        }

        .c3_textaera_wrap::placeholder {
            line-height: 28px;
        }

        .c3_textaera_wrap.large {
            height: 76px;
            margin-left: 0;
        }

        .c3_textaera_wrap:focus::placeholder {
            line-height: 1.5;
        }

        .c3_smile_btn_wrap {
            flex-direction: row;
            flex-wrap: wrap;
            width: 84px;
            margin-left: 7px;
        }

        .c3_textaera_wrap.large ~ .c3_smile_btn_wrap {
            width: 44px;
        }

        .c3_sticker_wrap,
        .c3_smile_btn_wrap2 {
            margin: auto 10px;
        }

        .c3_smile_btn,
        .c3_sticker_btn {
            width: 21px;
            height: 21px;
            background-size: contain;
        }

        .c3_attach {
            width: max-content;
            margin-left: 0;
            margin-right: 6px;
            height: auto;
            padding: 0;
        }

        //.c3_attach_img {
        //  width: 21px;
        //  height: 9px;
        //}

        //.c3_attach img {
        //  width: 21px;
        //}

        .c3_chat_wrap .c3_attach_text {
            margin-left: 0;
            margin-top: 3px;
            font-size: 9px;
        }

        .c3_write_msg_like,
        .c3_write_msg_wink {
            width: 60px;
            height: 32px;
            font-size: 9px;
            margin-left: 7px;
        }

        .c3_write_msg_wink {
            margin-left: 7px;
        }

        .c3_write_msg_like img,
        .c3_write_msg_wink img {
            width: 16px;
            margin-right: 6px;
        }

        .chat_hello_like_btn {
            width: max-content;
            height: 27px;
            padding: 0 6px;
            font-size: 9px;
        }

        .chat_hello_btn_img {
            width: 15px;
            height: 16px;
            margin-right: 6px;
        }

        //.chat_like_btn_img {
        //  width: 16px;
        //  height: 14px;
        //  margin-right: 6px;
        //}

        .chat_hello_like_btn span span {
            display: none;
        }

        .chat_hello_like_btn + .chat_hello_like_btn {
            margin-left: 7px;
        }
    }
}

.chat_virtual_gift_btn {
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    background: #fff;
    outline: 0;
    font-family: $mainFont;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 174px;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.6s ease-in-out;

    @media screen and (max-width: 1300px) and (min-width: 1200px) {
        font-size: 0;
        width: fit-content;
        padding: 0 8px;
    }

    @media screen and (max-width: 1140px) {
        font-size: 0;
        width: fit-content;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 68px;
        min-width: 68px;
        height: 68px;
        background: url('../../img/icon_virtual_gift.svg') center no-repeat;
        background-size: contain;
        bottom: -36px;
        left: -4px;
        transition: all 0.6s ease-in-out;

        @media screen and (max-width: 1300px) and (min-width: 1200px) {
            width: 24px;
            height: 24px;
            position: unset;
            min-width: 24px;
        }

        @media screen and (max-width: 1140px) {
            width: 24px;
            height: 24px;
            position: unset;
            min-width: 24px;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 16px;
        top: 10px;
        border-radius: 7px;
        background: var(--red, #eb5757);
        box-shadow: 0 1px 2px 0 rgba(157, 0, 0, 0.16);

        @media screen and (max-width: 1300px) and (min-width: 1200px) {
            right: 6px;
            top: 6px;
        }

        @media screen and (max-width: 1140px) {
            right: 6px;
            top: 6px;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #272727;
            color: #fff;

            &:before {
                transform: scale(1.1) rotate(15deg);
            }
        }
    }

    &.active_btn {
        background: #272727;
        color: #fff;

        &:before {
            transform: scale(1.1) rotate(15deg);
        }
    }
}
