@import "@sitestyles";

.girls_slider_wrap_global .slick-slide > div > div {
  vertical-align: middle;
}

.girls_slider_wrap_global .slick-list {
  padding-bottom: 0;
}

.likeher_page_wrap {
  padding: 11px 74px 16px;
  //height: calc(100vw - 172px);
  //height: calc(calc(var(--vh, 1vh) * 100) - 172px);
  height: calc(100vw - 0px);
  height: calc(calc(var(--vh, 1vh) * 100) - 0px);
  display: flex;
  flex-direction: column;
  max-height: 1200px;
}

@mixin holiday-likeher($name, $image-path-left: null, $image-path-right: null) {
  .likeher_page_wrap.#{$name} {

    .likeher_page_photos {
      z-index: 2;
    }

    .likeher_page_photos > .likeher_page_small_photo {
      background-color: white;
    }

    @if $image-path-left and $image-path-left != "" {
      .likeher_page_top:before {
        content: url(#{$image-path-left});
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;

        @media screen and (max-width: 1139px) {
          display: none;
        }
      }
    }

    @if $image-path-right and $image-path-right != "" {
      .likeher_page_top:after {
        content: url(#{$image-path-right});
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;

        @media screen and (max-width: 1139px) {
          display: none;
        }
      }
    }
  }
}

@include holiday-likeher('halloween', '../../img/holiday/halloween_likeher_left.svg');
@include holiday-likeher('lunar_new_year', '../../img/holiday/lunar_new_year_likeher_left.svg', '../../img/holiday/lunar_new_year_likeher_right.svg');
@include holiday-likeher('thanksgiving_day', '../../img/holiday/thanksgiving_day_likeher_left.svg', '../../img/holiday/thanksgiving_day_likeher_right.svg');
@include holiday-likeher('christmas', '../../img/holiday/christmas_likeher_left.svg');
@include holiday-likeher('woman_day', '../../img/holiday/woman_day_likeher_left.svg', '../../img/holiday/woman_day_likeher_right.svg');
@include holiday-likeher('valentine_day', '../../img/holiday/valentine_day_likeher_left.svg', '../../img/holiday/valentine_day_likeher_right.svg');
@include holiday-likeher('independence_day', '../../img/holiday/independence_day_likeher_left.svg', '../../img/holiday/independence_day_likeher_right.svg');
@include holiday-likeher('easter', '../../img/holiday/easter_likeher_left.svg');

.girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
  height: calc(100vh - 172px);
  height: calc(calc(var(--vh, 1vh) * 100) - 172px);
}

.chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
.remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
.chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
  //height: calc(100vh - 252px);
  //height: calc(calc(var(--vh, 1vh) * 100) - 252px);
  height: calc(100vh - 80px);
  height: calc(calc(var(--vh, 1vh) * 100) - 80px);
}

.chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
.remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
.chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
  height: calc(100vh - 252px);
  height: calc(calc(var(--vh, 1vh) * 100) - 252px);
}

.likeher_page_top {
  height: calc(100% - 172px);
  background-color: #fff;
  padding: 33px 110px 0;
  border-radius: 16px 16px 0 0;
  //box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  position: relative;
}

.likeher_page_photos {
  position: relative;
  height: 100%;
  max-width: 1650px;
  left: 50%;
  transform: translateX(-50%);
}

.likeher_page_small_photo {
  height: 80%;
  width: 48%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;
}

.likeher_page_small_photo.small_photo_left {
  left: 0;
}

.likeher_page_small_photo.small_photo_right {
  right: 0;
  text-align: right;
}

.likeher_page_small_photo_item {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
  filter: blur(1px);
  opacity: .7;
}

.likeher_page_small_photo_item.blur_unpaid {
  filter: blur(30px);
}

.likeher_page_big_photo {
  overflow: hidden;
  position: relative;
  z-index: 2;
  height: 100%;
  margin-left: 80px;
  margin-right: 80px;
}

.likeher_page_big_photo.white {
  background-color: #fff;
}

.likeher_page_big_photo_item {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.likeher_page_photos_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
  height: 55px;
  background-color: #F0F9FF;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  transition: .3s;
}

.likeher_page_photos_arrow:hover {
  background-color: #F0F9FF;
}

.likeher_page_photos_arrow.left_arrow {
  left: 0;
  border-radius: 0 40px 40px 0;
}

.likeher_page_photos_arrow.right_arrow {
  right: 0;
  border-radius: 40px 0 0 40px;
}

.likeher_page_photos_arrow img {
  width: 15px;
}

.likeher_page_photos_arrow svg {
  width: 24px;
  height: 24px;
  fill: #415f75;
  stroke: #415f75;
}

.likeher_page_middle {
  height: 86px;
  background-color: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  gap: 5px;
}

.likeher_page_profile_name {
  font-weight: 600;
  font-size: 20px;
  color: #474F5C;
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
}

.likeher_page_profile_info_bottom {
  display: flex;
  align-items: center;
}

.likeher_page_profile_place {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #446077;
  margin-right: 22px;
  display: flex;
  align-items: center;
}

.likeher_page_profile_place img {
  height: 15px;
  margin-right: 8px;
}

.likeher_page_profile_status {
  position: relative;
  padding-left: 16px;
  font-weight: 600;
  font-size: 12px;
  color: #999;
}

.likeher_page_profile_status.online {
  color: #27AE60;
}

.likeher_page_profile_status:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 100%;
  background-color: #999;
}

.likeher_page_profile_status.online:before {
  background-color: #27ae60;
}

.likeher_page_profile_buttons {
  display: flex;
  align-items: center;
}

.likeher_page_profile_chat_btn {
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  background: $buttonBackgroundColor;
}

.likeher_page_profile_chat_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.likeher_page_profile_chat_btn_img {
  width: 21px;
  height: 19px;
  background-image: url(../../img/chat-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 9px;
  background-size: contain;
}

.likeher_page_profile_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: $attachTouchText;
  height: 43px;
  padding: 0 10px;
  border-radius: 4px;
  transition: background-color .3s;
}

.likeher_page_profile_btn:hover {
  background-color: $buttonHoverBg2;
}

.likeher_page_profile_btn:nth-child(2) {
  margin-left: 16px;
}

.likeher_page_profile_btn img {
  margin-right: 10px;
  margin-top: -2px;
}

.likeher_page_profile_profile_btn svg {
  width: 20px;
  height: 28px;
  margin-right: 10px;
  fill: $attachTouchText;
}

.likeher_page_profile_letter_btn svg {
  width: 28px;
  height: 28px;
  margin-right: 7px;
  fill: $attachTouchText;
}

.likeher_page_profile_video_btn svg {
  width: 28px;
  height: 28px;
  margin-right: 7px;
  fill: $attachTouchText;
}

.likeher_page_bottom {
  height: 75px;
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.likeher_page_control_btn {
  height: 100%;
  width: calc(50% - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 24px;
  letter-spacing: 0.06em;
  cursor: pointer;
}

.likeher_page_control_btn:hover {
  box-shadow: $smallBtnShadowHover;
}

.likeher_page_control_btn img {
  margin-right: 26px;
}

.likeher_page_next_btn svg {
  width: 41px;
  height: 27px;
  margin-right: 26px;
  fill: #374E6C;
}

.likeher_page_like_btn {
  font-weight: 600;
  color: $attachTouchText;
}

.likeher_page_like_btn img {
  width: 45px;
}

.likeher_page_like_btn svg {
  width: 45px;
  height: 40px;
  margin-right: 26px;
  fill: $attachTouchText;
}

.likeher_page_like_btn.cross_banner {
  font-weight: 600;
  color: #FFFFFF;
  background: $searchItemBtnBg;
  border: 1px solid $primarySearchButtonBorder;
  transition: .3s;
  text-transform: capitalize;
}

.likeher_page_top_status {
  width: 192px;
  height: 27px;
  position: absolute;
  top: 25px;
  right: -8px;
  background: $likeOnlineBg;
  border-radius: 3px;
  font-weight: 600;
  color: $likeOnlineColor;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.c3lh_your_likes_list_wrap {
  list-style: none;
}

@media screen and (max-width: 1749px) {
  .c3lh_your_likes_list_wrap {
    grid-template-columns: repeat(3, 1fr);
  }

  .likeher_page_top {
    padding: 33px 70px 0;
  }
}

@media screen and (min-width: 2200px) {
  .c3lh_your_likes_list_wrap {
    grid-template-columns: repeat(auto-fill, 330px);
  }
}

@media screen and (max-width: 1599px) {
  .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 80px);
    height: calc(calc(var(--vh, 1vh) * 100) - 80px);
  }

  .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 132px);
    height: calc(calc(var(--vh, 1vh) * 100) - 132px);
  }

  .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 212px);
    height: calc(calc(var(--vh, 1vh) * 100) - 212px);
  }
}

@media screen and (max-width: 1499px) {
  .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 60px);
    height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  }

  .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 132px);
    height: calc(calc(var(--vh, 1vh) * 100) - 132px);
  }

  .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 212px);
    height: calc(calc(var(--vh, 1vh) * 100) - 212px);
  }

  .likeher_page_photos_arrow {
    width: 42px;
    height: 42px;
  }

  .likeher_page_photos_arrow img {
    width: 12px;
  }

  .likeher_page_photos_arrow svg {
    width: 18px;
    height: 18px;
  }

  .likeher_page_top {
    height: calc(100% - 140px);
  }

  .likeher_page_bottom {
    height: 54px;
  }

  .likeher_page_profile_name {
    font-size: 18px;
  }

  .likeher_page_profile_chat_btn {
    width: 120px;
    height: 36px;
  }

  .likeher_page_profile_chat_btn span span {
    //display: none;
  }

  .likeher_page_profile_letter_btn img {
    width: 20px;
  }

  .likeher_page_profile_video_btn img {
    width: 21px;
  }

  .likeher_page_profile_profile_btn img {
    width: 20px;
  }

  .likeher_page_profile_btn {
    /*margin-left: 24px;*/
    padding: 0 10px;
  }

  .likeher_page_control_btn {
    font-size: 16px;
    flex-direction: row-reverse;
  }

  .likeher_page_like_btn img {
    width: 28px;
    margin-right: 0;
    margin-left: 14px;
  }

  .likeher_page_next_btn img {
    width: 26px;
    margin-right: 0;
    margin-left: 14px;
  }

  .likeher_page_next_btn svg {
    width: 26px;
    height: 26px;
    margin-left: 11px;
    margin-right: 0;
    fill: #374E6C;
  }

  .likeher_page_like_btn svg {
    width: 22px;
    height: 22px;
    margin-left: 14px;
    margin-right: 0;
    fill: $attachTouchText;
  }

  .likeher_page_top_status {
    width: 172px;
  }
}

@media screen and (max-width: 1399px) {
  .likeher_page_wrap {
    padding: 16px 27px;
  }
}

@media screen and (max-width: 1299px) and (min-width: 1140px){
  .likeher_page_profile_buttons_with_video {
    gap: 10px;
  }

  .likeher_page_profile_buttons_with_video .likeher_page_profile_btn {
    flex-direction: column;
    gap: 2px;
    margin-left: 0;
    padding: 0 10px;
  }

  .likeher_page_profile_buttons_with_video .likeher_page_profile_profile_btn svg {
    margin-right: 0;
  }

  .likeher_page_profile_buttons_with_video .likeher_page_profile_letter_btn svg {
    margin-right: 0;
  }

  .likeher_page_profile_buttons_with_video .likeher_page_profile_video_btn svg {
    margin-right: 0;
  }

  .likeher_page_profile_buttons_with_video .likeher_page_profile_btn:nth-child(2) {
    margin-left: 0;
  }
}

@media screen and (max-width: 1139px) {
  .likeher_page_wrap {
    height: calc(100vh - 127px);
    height: calc(calc(var(--vh, 1vh) * 100) - 127px);
    //height: calc(100vh - 127px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 127px);
    padding: 0 0 20px 0;
  }

  .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 278px);
    height: calc(calc(var(--vh, 1vh) * 100) - 278px);
  }

  .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 187px);
    height: calc(calc(var(--vh, 1vh) * 100) - 187px);
  }

  .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    height: calc(100vh - 376px);
    height: calc(calc(var(--vh, 1vh) * 100) - 376px);
    padding: 0;
  }


  .likeher_page_top {
    border-radius: 0;
    padding: 23px 27px 0;
  }

  .likeher_page_middle {
    border-radius: 0;
  }

  .likeher_page_bottom {
    height: 65px;
    padding: 0 50px;
  }
}

@media screen and (max-width: 1299px) {
  .c3lh_your_likes_list_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1199px) {
  .c3lh_your_likes_list_wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-height: 900px) {

  .c2lh_girls_you_liked_wrap.wide {
    display: none;
  }

  .c2lh_girls_you_liked_wrap.wide + .c2lh_all_likes_btn_wrap {
    position: static;
    padding: 10px 0;
  }

  .c2lh_girls_you_liked_wrap.wide + .c2lh_all_likes_btn_wrap:before {
    display: none;
  }

  .cl2h_tabs_wrap {
    display: flex;
    flex-direction: column;
  }

  .cl2h_tab_wide_filter {
    flex: 1;
  }

  .cl2h_tab_wide_filter::-webkit-scrollbar {
    width: 3px;
    background: #fff;
    border-radius: 3px;
  }

  .cl2h_tab_wide_filter::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
  }

  .cl2h_tab_wide_filter .fill_data_form_select_list {
    margin-bottom: 0;
  }

  .cl2h_tab_wide_filter .fill_data_form_select_list .fill_data_form_select_item_wrap:last-child {
    margin-bottom: 0;
  }

  .column_2_likeher_wrap .cl2h_tab_wide_filter .fill_data_form_select_list .fill_data_form_select_item_wrap:nth-of-type(2) .cl2h_tab_wide_filter_item_wrap {
    flex-wrap: wrap;
    height: auto;
  }

  .c2lh_girls_you_liked_wrap {
    padding-top: 30px;
  }
}

@media (hover: none) and (pointer: coarse) {
  .likeher_page_wrap {
    padding: 0 0 20px 0;
  }

  .likeher_page_middle {
    border-radius: 0;
  }

  .likeher_page_top {
    border-radius: 0;
  }

  @media screen and (min-width: 1140px) {
    .likeher_page_wrap {
      //height: calc(100vh - 259px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 259px);
      height: calc(100vh - 127px);
      height: calc(calc(var(--vh, 1vh) * 100) - 127px);
      padding-bottom: 10px;
    }

    .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 259px);
      height: calc(calc(var(--vh, 1vh) * 100) - 259px);
    }

    .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 187px);
      height: calc(calc(var(--vh, 1vh) * 100) - 187px);
    }

    .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 319px);
      height: calc(calc(var(--vh, 1vh) * 100) - 319px);
    }

    .likeher_page_bottom {
      padding: 0 10px;
    }
  }

  @media screen and (min-width: 1500px) {
    .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 207px);
      height: calc(calc(var(--vh, 1vh) * 100) - 207px);
    }

    .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 339px);
      height: calc(calc(var(--vh, 1vh) * 100) - 339px);
    }
  }

  @media screen and (min-width: 1600px) {
    .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 299px);
      height: calc(calc(var(--vh, 1vh) * 100) - 299px);
    }

    .chat_wrap_confirm_email_wrap ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 207px);
      height: calc(calc(var(--vh, 1vh) * 100) - 207px);
    }

    .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
    .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
      height: calc(100vh - 379px);
      height: calc(calc(var(--vh, 1vh) * 100) - 379px);
    }
  }

  @media screen and (orientation: landscape) {
    .likeher_page_top {
      padding: 23px 250px 0;
    }

    .likeher_page_big_photo {
      margin: 0 50px;
    }

    .likeher_page_photos_arrow {
      width: 43px;
      height: 43px;
    }

    .likeher_page_photos_arrow img {
      width: 12px;
    }

    .likeher_page_photos_arrow svg {
      width: 18px;
      height: 18px;
    }

    .likeher_page_middle {
      padding: 0 250px;
    }

    @media screen and (max-width: 1099px) {

      .likeher_page_wrap {
        padding-bottom: 10px;
      }

      .likeher_page_bottom {
        height: 46px;
        justify-content: center;
      }

      .likeher_page_control_btn {
        width: 226px;
      }

      .likeher_page_control_btn:last-child {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .likeher_page_top {
    padding: 23px 80px 0;
    height: calc(100% - 165px);
  }

  .likeher_page_photos_arrow {
    width: 60px;
    height: 60px;
  }

  .likeher_page_photos_arrow img {
    width: 17px;
  }

  .likeher_page_photos_arrow svg {
    width: 26px;
    height: 26px;
  }

  .likeher_page_big_photo {
    margin-left: 70px;
    margin-right: 70px;
  }

  .likeher_page_middle {
    height: 90px;
  }

  .c3lh_your_likes_list_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .likeher_page_top {
    padding: 23px 30px 0;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (max-height: 450px) and (max-width: 899px) and (min-width: 420px) {

    .likeher_page_top:after {
      content: '';
      display: block;
      width: 100%;
      height: 175px;
      background: linear-gradient(0deg, rgba(0, 49, 123, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
    }

    .likeher_page_small_photo {
      display: none;
    }

    .likeher_page_big_photo {
      margin: 0;
    }

    .likeher_page_big_photo_item {
      height: 100%;
      width: auto;
    }

    .likeher_page_middle {
      position: relative;
      z-index: 2;
      background-color: transparent;
      padding: 0 15px;
      height: max-content;
      box-shadow: none;
    }

    .likeher_page_profile_place {
      display: none;
    }

    .likeher_page_profile_btn {
      display: none;
    }

    .likeher_page_profile_name {
      font-size: 16px;
      color: #fff;
      text-shadow: 0px 1px 5px rgba(0, 29, 73, 0.33);
      margin-bottom: 2px;
    }

    .likeher_page_profile_status {
      padding-left: 11px;
    }

    .likeher_page_profile_status:before {
      width: 8px;
      height: 8px;
      top: 5px;
    }

    .likeher_page_profile_chat_btn {
      width: 86px;
      height: 32px;
      border-radius: 5px;
      letter-spacing: -0.03em;
    }

    .likeher_page_profile_chat_btn_img {
      width: 19px;
      height: 19px;
      background-image: url(../../img/chat_header.svg);
      margin-right: 7px;
    }

    .likeher_page_bottom {
      height: 42px;
      position: relative;
      z-index: 2;
      padding: 0 10px;
    }

    .likeher_page_control_btn {
      font-size: 14px;
    }

    .likeher_page_next_btn img {
      width: 21px;
      margin-left: 11px;
    }

    .likeher_page_next_btn svg {
      width: 21px;
      height: 21px;
      margin-left: 11px;
      fill: #374E6C;
    }

    .likeher_page_like_btn img {
      width: 22px;
      margin-left: 14px;
    }

    .likeher_page_photos_arrow img {
      display: none;
    }

    .likeher_page_photos_arrow svg {
      display: none;
    }

    .likeher_page_photos_arrow {
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .likeher_page_photos_arrow.left_arrow {
      left: 0;
      background-image: url(../../img/lh-arr-left.svg);
      background-color: transparent;
      border-radius: 0;
    }

    .likeher_page_photos_arrow.right_arrow {
      right: 0;
      background-image: url(../../img/lh-arr-right.svg);
      background-color: transparent;
      border-radius: 0;
    }


    .likeher_page_wrap {
      //height: 100vw;
      //height: calc(var(--vh, 1vh) * 100);
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 0;
    }

    .popup_likeher_photo_unpaid_warning.like_her {
      padding: 10px 5px;
    }

    .likeher_page_top_status {
      right: unset;
      top: unset;
      left: 0;
      bottom: 5px;
      border-radius: 0 5px 5px 0;
    }

    .likeher_page_top {
      position: static;
      height: 100%;
      padding: 5px;
      background-color: transparent;
      z-index: 1;
      box-shadow: none;
      width: max-content;
      margin: 0 auto;
      min-width: 50%;
    }

    .likeher_page_top:after {
      display: none;
    }

    .likeher_page_big_photo_item {
      border-radius: 10px;
    }

    .likeher_page_photos_arrow img {
      width: 10px;
    }

    .likeher_page_photos_arrow svg {
      width: 14px;
      height: 14px;
    }

    .likeher_page_photos_arrow.left_arrow {
      left: -33px;
    }

    .likeher_page_photos_arrow.right_arrow {
      right: -33px;
    }

    .likeher_page_middle {
      display: block;
      text-align: center;
      padding-top: 18px;
      width: min-content;
      margin-left: auto;
      margin-right: auto;
    }

    .likeher_page_profile_name {
      color: rgba(51, 51, 51, 0.9);
      font-size: 20px;
      text-shadow: none;
    }

    .likeher_page_profile_info_bottom {
      justify-content: center;
    }

    .likeher_page_profile_chat_btn {
      width: 141px;
      height: 32px;
      margin: 0 auto 12px;
    }

    .likeher_page_profile_buttons {
      margin-top: 14px;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .likeher_page_profile_btn {
      display: flex;
      height: 32px;
      background-color: #fff;
      border: 1px solid #446077;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      margin-left: 0;
      margin-bottom: 5px;
      width: 100%;
    }

    .likeher_page_profile_btn:nth-child(2) {
      margin-left: 0;
    }


    .likeher_page_profile_letter_btn img {
      width: 18px;
    }

    .likeher_page_profile_video_btn img {
      width: 20px;
    }

    .likeher_page_profile_profile_btn img {
      width: 18px;
    }

    .likeher_page_bottom {
      //display: block;
      height: unset;
      padding-bottom: 17px;
    }

    .likeher_page_control_btn {
      width: 110px;
      height: 42px;
      font-size: 12px;
      margin: 0 auto;
    }

    .likeher_page_like_btn.likeher_page_control_btn {
      margin-top: 16px;
    }

    .likeher_page_control_btn:last-child {
      margin-left: 10px;
      margin-top: 0;
    }
  }
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) and (min-width: 420px) {

  .column-3.likeher .chat_wrap_confirm_email_wrap,
  .column-3.likeher .chat_wrap_holiday_banner_wrap {
    display: none;
  }

  .mob_notify_bottom.likeher {
    bottom: 30px;
    width: 50vw;
  }

  .mob_notify_bottom.likeher .mob_notify_bottom_list {

  }
}


@media screen and (max-width: 767px) {
  .column-3.likeher .temp_wrap {
    height: 100%;
  }

  .column-3.likeher .temp_wrap .animated.fadeInRight {
    height: 100%;
  }

  .column-3.likeher .temp_wrap .likeher_page_wrap {
    //height: 100%;
  }
  .column-3.likeher {
    height: 100%;
  }
}

//.remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap {
//  height: calc(100vh - 252px);
//  height: calc(calc(var(--vh, 1vh) * 100) - 252px);
//
//  @media screen and (max-width: 1600px) {
//    height: calc(100vh - 252px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 252px);
//  }
//
//  @media screen and (max-width: 1500px) {
//    height: calc(100vh - 212px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 212px);
//  }
//
//  @media screen and (max-width: 1140px) {
//    height: calc(100vh - 362px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 362px);
//  }
//
//  @media screen and (max-width: 800px) {
//    height: calc(100vh - 332px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 332px);
//  }
//
//  @media screen and (max-width: 767px) {
//    height: calc(100vh - 318px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 318px);
//  }
//
//  @media screen and (max-width: 600px) {
//    height: calc(100vh - 254px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 254px);
//  }
//}
//
//.column-3.likeher .remarket_banner_likeher ~ .temp_wrap .likeher_page_wrap {
//  @media screen and (max-width: 767px) {
//    height: calc(100vh - 318px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 318px);
//  }
//
//  @media screen and (max-width: 600px) {
//    height: calc(100vh - 254px);
//    height: calc(calc(var(--vh, 1vh) * 100) - 254px);
//  }
//}

@media screen and (max-width: 599px) {
  .likeher_page_wrap {
    //height: calc(100vh - 176px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 176px);
    //height: calc(100vh - 100px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 100px);
    justify-content: flex-end;
    padding-bottom: 11px;
    position: relative;
    min-height: 99%;

  }

  .chat_wrap_confirm_email_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .remarket_banner_likeher ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap,
  .chat_wrap_holiday_banner_wrap ~ .girls_slider_wrap_global ~ .temp_wrap .likeher_page_wrap {
    padding: 0 0 12px 0;
  }

  .likeher_page_top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px 5px 0;
    background-color: transparent;
    z-index: 1;
    box-shadow: none;
  }

  .likeher_page_photos:after {
    content: '';
    display: block;
    width: 100%;
    height: 175px;
    background: linear-gradient(0deg, rgba(0, 49, 123, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 0 0 8px 8px;
  }

  .likeher_page_small_photo {
    display: none;
  }

  .likeher_page_big_photo {
    margin: 0;
  }

  .likeher_page_big_photo_item {
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
  }

  .likeher_page_middle {
    position: relative;
    z-index: 2;
    background-color: transparent;
    padding: 0 15px;
    height: max-content;
    box-shadow: none;
    margin-top: 11px;
  }

  .likeher_page_profile_place {
    display: none;
  }

  .likeher_page_profile_btn {
    display: none;
  }

  .likeher_page_profile_name {
    font-size: 16px;
    color: #fff;
    text-shadow: 0 1px 5px rgba(0, 29, 73, 70%);
    margin-bottom: 0;
  }

  .likeher_page_profile_status {
    padding-left: 11px;
  }

  .likeher_page_profile_status:before {
    width: 8px;
    height: 8px;
    top: 5px;
  }

  .likeher_page_profile_chat_btn {
    width: 110px;
    height: 36px;
    background: $buttonBackgroundColor;
    border-radius: 5px;
    color: #fff;
    letter-spacing: -0.03em;
    border-color: transparent;
  }

  .likeher_page_profile_chat_btn_img {
    width: 19px;
    height: 19px;
    background-image: url(../../img/chat_header.svg);
    margin-right: 7px;
  }

  .likeher_page_bottom {
    height: 42px;
    position: relative;
    z-index: 1;
    padding: 0 10px;
  }

  .likeher_page_bottom.cross_banner {
    margin-top: -60px;
  }

  .likeher_page_control_btn {
    font-size: 14px;
  }

  .likeher_page_next_btn img {
    width: 21px;
    margin-left: 11px;
  }

  .likeher_page_like_btn img {
    width: 22px;
    margin-left: 14px;
  }

  .likeher_page_like_btn svg {
    width: 22px;
    height: 22px;
    margin-left: 14px;
    fill: $attachTouchText;
  }

  .likeher_page_photos_arrow img {
    display: none;
  }

  .likeher_page_photos_arrow svg {
    display: none;
  }

  .likeher_page_photos_arrow {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .likeher_page_photos_arrow.left_arrow {
    left: 0;
    background-image: url(../../img/lh-arr-left.svg);
    background-color: transparent;
    border-radius: 0;
  }

  .likeher_page_photos_arrow.right_arrow {
    right: 0;
    background-image: url(../../img/lh-arr-right.svg);
    background-color: transparent;
    border-radius: 0;
  }

  .likeher_page_profile_status {
    letter-spacing: 0.02em;
    font-weight: 500;
  }

  .likeher_page_profile_status.online {
    color: #b2ffd3;
  }

  .likeher_page_profile_status.online:before {
    background-color: #b2ffd3;
  }

  .c2lh_girls_you_liked_wrap2 {
    padding-bottom: 65px;
  }

  .c3lh_your_likes_list_wrap {
    padding-left: 10px;
    grid-gap: 8px;
  }

  .likeher_page_top_status {
    background: $likeOnlineMobBg;
    width: 98px;
    height: 20px;
    top: 13px;
    right: 5px;
    font-size: 10px;
  }
}