@import "@sitestyles";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.profile_header_menu_close{
	display: none;
	position: absolute;
	right: 16px;
	top: 16px;
	padding: 5px;
}

.profile_header_menu_close img{
	width: 20px;
	height: 20px;
}

.profile_header_menu_close:has( > svg) {
	right: 12px;
	top: 12px;
}

.profile_header_menu_close svg{
	width: 28px;
	height: 28px;
	fill: #FFFFFF;
	vertical-align: middle;
}

input.like_select_head{
	width: 100%;
	outline: none;
}

.c3_header{
	display: flex;
	justify-content: space-around;
	/* width: 1376px;
 */
	/* width:99%;
 */
	max-width: 1376px;
	width:100%;
	margin: auto;
	flex: none;
}
.header-menu:first-child{
	/* margin-left:20px;
 */
}
.header-menu-element{
	/* margin-right: 1.85%;
 */
}
/* .c3_header div{
	margin-right:1.85%;
} */
.header-menu{
	display: flex;
	justify-content: center;
	padding-top: 8px;
	padding-bottom: 8px;
	flex-wrap: wrap;
}
.header-menu button{
}
.header-menu-btn.active{
	background: $background5Color;
}
.header-menu__img{
	background: $buttonBackgroundColor;
	-webkit-background-clip: text;
	color: transparent;

}
.header-menu-element{
	margin-right: 16px;
}
.header-menu-element:last-child{
	margin-right: 0;
}
.header-menu-btn{
	background: $whiteColor;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	width: 156px;
	height: 62px;
	/* margin-right:30px;
 */
}
.header-menu-item{
	display:flex;
	justify-content: center;
	align-content: center;
	align-items: center;

}
.header-menu-text{
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 120%;
	/* or 19px */

	display: flex;
	/* justify-content:center;
 */
	align-items: center;
	text-align: left;
	/* linear_gradient */

	color: $profileButtonColor;
	flex: 0;
	margin-left: 10px;
}
.header-menu-btn.active .header-menu-text{
	color: $profileButtonActiveColor;
}
.header-prof{
	font-size:16px;
	margin-left:11px;
	width:65px;
}
.header-sup{
	font-size:18px;
	margin-left:11px;
}
.header-gif{
	font-size:16px;
	margin-left:11px;
}

.c3ph_img{
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px;
}

.c3ph_img.c3ph_img_edit{
	background-image: $profileEdit;
}
.active .c3ph_img.c3ph_img_edit{
	background-image: $profileEditH;
}

.c3ph_img.c3ph_img_purchase{
	background-image: $profileCredits;
}
.active .c3ph_img.c3ph_img_purchase{
	background-image: $profileCreditsH;
}

.c3ph_img.c3ph_img_support{
	background-image: url(../../img/c3ph-support.svg);
}
.active .c3ph_img.c3ph_img_support{
	background-image: url(../../img/c3ph-support-h.svg);
}

.c3ph_img.c3ph_img_gift{
	background-image: $profileGifts;
}
.active .c3ph_img.c3ph_img_gift{
	background-image: $profileGiftsH;
}

.c3ph_img.c3ph_img_contact{
	background-image: $profileContact;
}
.active .c3ph_img.c3ph_img_contact{
	background-image: $profileContactH;
}

.c3ph_img.c3ph_img_meet{
	background-image: $profileMeeting;
}
.active .c3ph_img.c3ph_img_meet{
	background-image: $profileMeetingH;
}

.c3ph_img.c3ph_img_webcam{
	background-image: url(../../img/c3ph-webcam.svg);
}
.active .c3ph_img.c3ph_img_webcam{
	background-image: url(../../img/c3ph-webcam-h.svg);
}

.c3ph_img.c3ph_img_block{
	background-image: $profileBlock;
}
.active .c3ph_img.c3ph_img_block{
	background-image: $profileBlockH;
}

.c3ph_img.c3ph_img_logout{
	 background-image: $profileLogout;
 }

.header-menu-text.text-nowrap{
	white-space: nowrap;
}

.main-content{
	background: $whiteColor;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	min-height: 723px;
	margin-bottom: 24px;
}

.content-photo{
	width: 35%;
	max-width: 450px;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 5px;
	margin:24px 35px 24px 24px;
	background-position: center;
	flex: none;
}

.man_profile_photo{
	max-width: 350px;
	max-height: 350px;
	margin: 24px;
	display: block;
}

.content-info{
	padding-top: 24px;
	flex: 1;
	padding-right: 37px;
}
.content-info__credits{
	width: 216px;
	height: 109px;
	background: $background1Color;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content-info-header{
	display:flex;
	justify-content: space-between;
	flex: 1;
}

.content-info-about{
	width: calc(100% - 48px);
	display: flex;
	padding: 24px 0;
	margin: 0 24px;
	border-top: 1px solid $familySizeCondensedSoupColor;
	justify-content: space-between;
}

.m_profile_flex_wrap{
	display: flex;
}

.info__name{
	font-weight: 600;
	font-size: 36px;
	line-height: 140%;
	color: $accentLeftColor;
	/* or 50px */
}
.info-firstline{
	display: flex;
	align-items: center;
	height: 36px;
}
.info__id{
	font-size: 18px;
	line-height: 22px;
	margin-left:43px;
	letter-spacing: 0.06em;

}
.credits{
	display:flex;
	margin:0px 34px 0px 0px;
}
.credits-name{
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	/* or 28px */


	color: $defaultTextColor;
}
.credits-count{
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.06em;

	color: $accentRightColor;
}
.credits-link{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	/* or 22px */

	text-decoration-line: underline;

	/* 3DB6B6 */

	color: $accentLeftColor;
	/* margin-top:7px;
 */
}
.credits-link-item{
	margin-top: 7px;
}

.date-birthday{
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	height: 11px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: $primaryColor;
}
.date-birthday-till{
	height: 11px;
	color: $primaryColor;
	padding-left: 5px;
	font-size: 16px;
	line-height: 22px;
}
.info-date{
	margin-top:25.5px;
	/* height: 11px;
 */
}
.info-place{
	display:flex;
	margin-top:25px;

}
.place-info{
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	color: $primaryColor;
}

.man_profile_email{
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.06em;
	color: $accentLeftColor;
	margin-top: 33px;
}

.man_profile_email span{
	font-weight: normal;
}

.place-flag{
	padding-right:9px;
	width:40px;
	height: auto;
}
.pointer-cursor{
	cursor: pointer;
}

.ui-disabled-icon {
	pointer-events: none;
	opacity: 0.6;
}

.main-content-about{
	background: $whiteColor;
	box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
}

.main-content-header-row-flex{
	display: flex;
	width: 100%;
	align-items: center;
}

.main-content-header{
	display:flex;
	flex-wrap: wrap;
}

.list-header{
	height: 18px;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	margin-bottom: 20px;
	color: $primaryColor;
}
.list-item{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	margin-bottom: 5px;
	color: $accentLeftColor;
	list-style: none;
}
.list-item-header{
	margin-right: 5px;
}
.list-item-value{
	color: $accentLeftColor;
;
	font-weight: 600;
}
.list-item-value-danger{
	color: $counterAndErrorColor;
	font-weight: 600;
}
.list{
	margin-left:20px;
}
.info-about-parts{
	display:flex;
	width: max-content;
}
.info-about-parts:last-child{
	/* margin-right:50px;
 */
}

.info-about-block{
}

.block-2{
	width: calc(100% / 3);
}
.social-info{
	width: calc(100% / 3);
}
.about-profile-text{
	display:flex;
	justify-content: space-between;
	margin-bottom: 65px;
}
.about-content-container{
	padding: 34px 24px 30px 24px;
}
.text-node{
	width: calc(50% - 17px);
}
.node-header{
	height: 22px;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.06em;
	text-transform: uppercase;

	color: $primaryColor;
}
.node-header:focus{
	color:red;
}
.node-header__section{
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.06em;

	color: $accentRightColor;
	margin-top: 24px;
	margin-bottom: 20px;
}

.node-about{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 150%;
	text-align: justify;

	color: $stormGreyColor;
	margin-top: 18px;
}
.text-elem{
	display:flex;
	flex-direction:column;
	width: calc(100% / 3);
	padding-right: 20px;
}
.text-elem:first-child{
}
.text-elem:last-child{
}
.list-of-lists{
	display:flex;

	margin-bottom:50px;
}
.content-slider{
	overflow-x: auto;
	overflow-y:hidden;
	margin-top:28px;
	padding-bottom: 20px;
}
.content-slider::-webkit-scrollbar{
	background-color:blue;
	height: 5px;
	left: 1532px;
	top: 1955px;

	background: $familySizeCondensedSoupColor;
	border-radius: 22px;
}
.content-slider::-webkit-scrollbar-thumb{
	background-color: blue;
	width:188px;
	background: $accentLeftColor;
	border-radius: 9px;

}
.slider-img{
	margin-right: 18px;
	cursor: pointer;
}
.scrollbar-item{
	height:300px;
	background: $silverColor;
	border-radius: 10px;
}
.header-label{
	margin-left: 24px;
	margin-top: 63px;
	display:flex;
	align-items: baseline;
	width:1308px;
	justify-content: space-between;
}
.node-link{
	width: 83px;
	height: 22px;

	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;

	color: $accentRightColor;
}

.bot-slider{
	display:flex;
	width:100%;
	margin-right: 24px;
	margin-left: 24px;
}

.bot-slider-item{
	background: $whiteColor;
	box-shadow: 6px 0px 12px rgba(47, 43, 99, 0.16);
	border-radius: 10px;
	margin-top: 23px;
	margin-right: 25px;
	padding:20px;
	z-index:1;
}
.bot-slider-item::after{
	content:'';
}

.slider-item__img{
	border-radius: 5px;
	z-index: 1;
}

.arrow-btn{
	display:flex;
	justify-content: center;
}

.arrow-btn-right{
	display:block;
}
.slider-btns{
	position: absolute;
	width:100%;
	top: 50%;
	transform: translateY(-50%);
	display:flex;
	justify-content: space-between;
}

.slider-btns button{
	width: 37px;
	height: 37px;
	line-height: 37px;
	cursor: pointer;
	outline: none;
}

.slider-btns .arrow-btn{
	display: inline;
	vertical-align: middle;
	line-height: 1;
	outline: none !important;
}

.slider-item__icon{
	width:40px;
	height:40px;
	border-radius:40px;
}
.slider-item-info{
	display:flex;
	margin-top: 10px;
}
.item-info-all{
	margin-left:11px;
}



.info-name{
	height:18px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	color: $defaultTextColor;
}
.country-town{
	color: $oldGrayColor;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	height:11px;
	color: $defaultTextColor;
	margin-left:5.5px;
	opacity: 0.5;
}
.place-id{
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;

	color: $defaultTextColor;

	opacity: 0.5;
	margin-right:12px;
}
.country-flag{
	width:16px;
	height:12px;
}
.info-about-place{
	display:flex;
	height:14px;
	margin-top:4px;
}
.bot-slider-btn{
	position: absolute;
	width:27.4%;
	justify-content: space-between;
	display:flex;
	z-index:5;
	/* background-color: blue;
 */
}
.item-btns{
	display:flex;
	justify-content: center;
	align-items: center;
	/* align-content: center;
 */
}
.left-btn-view{
	/* background: $whiteColorAlpha5;
 */
	/* margin-left: 0;
 */
	transform: rotate(-180deg);
	display:inherit;

}
.right-btn-view{

	transform: rotate(-180deg);
	display:inherit;
	/* border-radius: 16px 0px 0px 16px;
 */

}

.slider-btn-right{
	background: $background5Color;
	width:35px;
	height:35px;
	border-radius: 16px 0px 0px 16px;
}
.slider-btn-left{
	background: $whiteColorAlpha5;
	border-radius: 16px 0px 0px 16px;
	width:35px;
	height:35px;
	transform: rotate(-180deg);
}
.slider-btn{
	border-radius: 16px 0px 0px 16px;
}
.img-basket{
	width:38px;
	/*height:30px;
*/
	margin-left:20px;
	margin-right: 12px;
}
.img-pencil{

}
/* .bot-platform{
	position: relative;
width: 423px;
height: 704px;
background-color: red;
z-index: 0;
margin-top: -550px;
margin-left: 50px;
} */
.img-requests{
	margin-left:20px;
	margin-right: 15px;
}
.img-gift{
	/* margin-right:18px;
 */
}

.c2_close{
	display: none;
	width: 30px;
	height: 30px;
	background-image: url(../../img/cross_white.svg);
	-webkit-background-size: 26px 26px;
	background-size: 20px 20px;
	background-position: center;
	position: absolute;
	right: -40px;
	top: 10px;
	background-repeat: no-repeat;
	cursor: pointer;
	visibility: hidden;
}

.profile_man main{
	//padding-right: 11px;
	flex: 1;
	overflow-x: hidden;
}

.profile_man main .container{
	/*height: calc(100vh - 100px);
	height: calc(calc(var(--vh, 1vh) * 100) - 100px);
*/
	height: 100%;
	overflow-y: auto;
	padding: 0 11px 0 24px;
}

.need_confirm_email .profile_man main .container{
	/*height: calc(100vh - 192px);
	height: calc(calc(var(--vh, 1vh) * 100) - 192px);
*/
}

.profile_man.notif main .container{
	/*height: calc(100vh - 117px);
	height: calc(calc(var(--vh, 1vh) * 100) - 117px);
*/
}

.profile_man main .container::-webkit-scrollbar{
	width: 3px;
	background: $littleGreyLittleBlueColor;
	border-radius: 20px;
}

.profile_man main .container::-webkit-scrollbar-thumb {
	background: $scrollbarThumbColor;
	border-radius: 3px;
}

.upload_popup_tabs,
.react-tabs{
	height: 100%;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
	height: calc(100% - 90px);
}

.upload_popup_tabs_content {
	height: 100%;
}

.upload_popup_tabs_content::-webkit-scrollbar {
	width: 3px;
	background: $littleGreyLittleBlueColor;
	margin-right: 3px;
}

.upload_popup_tabs_content::-webkit-scrollbar-thumb {
	background: $scrollbarThumbColor;
}

.message_text video{
	display: block;
}

.message_text video,
.message-text audio{
	max-width: 100%;
}

.c1_menu_logout{
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	color: $primaryColor;
	cursor: pointer;
}

.c1_menu_logout img{
	margin-right: 12px;
}

.popup_add_file_close{
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 5px;
	cursor: pointer;
}

.popup_add_file_close:has(> svg) {
	right: 6px;
	top: 6px;
}

.popup_add_file_close img{
	width: 12px;
	height: 12px;
}

.popup_add_file_close svg {
	width: 18px;
	height: 18px;
	fill: #FFFFFF;
	vertical-align: middle;
}

.c3l_men_list_item_main_bottom_cost_text{
	font-size: 11px;
	color: $defaultTextColorAlpha5;
	display: block;
	margin-bottom: 8px;
}

.c3l_letters_list_item .c3l_letters_list_item_bottom{
	margin-right: 0;
	margin-left: 0;
}

.c3l_letters_list_item .c3l_item_btn.btn-prev{
	left: 0;
}

.c3l_letters_list_item .c3l_item_btn.btn-next{
	right: 0;
}


.mp_slider_list{
	/* width: 3000px;
 */
	display: flex;
}

.mp_slider_wrap{
	width: calc(100vw - 660px);
	position: relative;
}

.add_photo {
	position: relative;
}

.hidden_upload{
	opacity: 0;
	display: none;
	position: absolute;
	top:0;
 bottom: 0;
	left:0;
 right: 0;
}

.profile-contact-req-column{
	display: flex;
	flex-direction: column;
	background-color: $bleachColor;
}

.profile-contact-req-main{
	flex: 1;
}

.all-blocks.first-link-show{
	height: 100%;
}

.humen_right_block{
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: space-between;
	flex-wrap: wrap;
}

.block-block-list .c2_list_wrap{
	height: 100%;
}

.block-block-list .c3_chat_scroll.block_list{
	height: 100%;
}

.block-block-list .column-3.empty .c3_chat_empty_bottom{
	display: none;
}

.block-block-list .c3_chat_empty_img{
	max-height: calc(100% - 50px);
	margin-bottom: auto;
	opacity: .8;
}

.block-block-list  .column-3.empty{
	background-color: $bleachColor;
}

@media screen and (max-width: 1899px){
}

@media screen and (max-width: 1139px){
	.mp_slider_list{
		overflow-y: auto;
	}
}

@media screen and (max-width: 999px){

}

@media screen and (max-width: 899px){
	.block-block-list .column-2{
		position: static;
		height: 100%;
	}

	.block-block-list .c2_chat_categories{
		display: none;
	}
}

@media screen and (max-width: 599px){
	.block-block-list .column-2{
		position: absolute;
		top: 0px;
	}

	.need_confirm_email .block-block-list .column-3.empty{
		height: calc(100vh - 92px);
		height: calc(calc(var(--vh, 1vh) * 100) - 92px);
		padding-top: 0;
	}

	.block-block-list .column-3.empty{
		height: calc(100vh - 50px);
		height: calc(calc(var(--vh, 1vh) * 100) - 50px);
	}
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px){
	.need_confirm_email .block-block-list .column-3.empty{
		padding-top: 0;
	}

	.c3_chat_empty_img{
		max-height: calc(100% - 70px);
		max-width: calc(100% - 230px);
	}

	.block-block-list .column-3.empty{
		height: calc(100vh - 50px);
		height: calc(calc(var(--vh, 1vh) * 100) - 50px);
	}

	.block-block-list .column-2.active{
		left: 0;
	}

	.block-block-list .c2_chat_categories{
		display: none;
	}
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px){
}
