@import '@sitestyles';

.column-3.gifts-page-column {
    display: flex;
    flex-direction: column;
}

.gifts_page_wrap {
    display: flex;
    flex-direction: column;
    padding-left: 43px;
    background-color: $bleachColor;
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.c3gp_gifts_page_head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: none;
    min-height: 80px;
    padding: 20px 37px 0 0;
}

.c3gp_gifts_page_head_title {
    font-size: 18px;
    font-weight: 500;
    color: $stormGreyColor;
    margin-right: 19px;
    margin-bottom: 14px;
}

.c3gp_gifts_page_head_category_item {
    height: 40px;
    padding: 12px 26px 0;
    background-color: $whiteColor;
    box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
    border-radius: 10px;
    font-weight: 600;
    color: $defaultTextColorAlpha8;
    margin-right: 12px;
    cursor: pointer;
    margin-bottom: 14px;
}

.c3gp_gifts_page_head_category_item.active {
    background-color: $background5Color;
}

.c3gp_gifts_page_head_show_all {
    font-weight: 600;
    color: $primaryColor;
    margin-bottom: 14px;
    margin-left: 5px;
    cursor: pointer;
}

.c3gp_gifts_page_head_cart_btn {
    width: 134px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 14px;
    margin-left: auto;
    cursor: pointer;
    position: relative;
}

.c3gp_gifts_page_head_cart_btn img {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 12px;
}

.c3gp_gifts_page_head_cart_btn_count {
    display: inline-block;
    height: 27px;
    line-height: 27px;
    padding: 0 10px;
    background-color: $counterAndErrorColor;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: $whiteColor;
    margin-left: 15px;
}

.c3gp_gifts_page_head_cart_btn_info_text {
    display: none;
    position: absolute;
    top: calc(100% + 9px);
    right: 0;
    width: 230px;
    height: 56px;
    padding: 10px 23px 8px;
    text-align: center;
    background: linear-gradient(0deg, $defaultTextColorAlpha7, $defaultTextColorAlpha7),
        $background5Color;
    border-radius: 30px;
    color: $whiteColorAlpha8;
    line-height: 1.3;
    font-size: 14px;
    font-weight: normal;
}

.c3gp_gifts_page_head_cart_btn_info_text:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -7px;
    left: 66%;
    transform: rotate(-45deg);
    background: linear-gradient(45deg, transparent 50%, $defaultTextColorAlpha7 50%);
}

.c3gp_gifts_page_head_cart_btn:hover .c3gp_gifts_page_head_cart_btn_info_text {
    display: block;
}

.c3gp_gifts_page_main {
    -webkit-flex: 1px;
    -moz-flex: 1px;
    -ms-flex: 1px;
    -o-flex: 1px;
    flex: 1px;
    margin-right: 17px;
    padding-right: 17px;
    overflow-y: auto;
}

.c3gp_gifts_page_main::-webkit-scrollbar {
    width: 3px;
    background: $whiteColor;
    border-radius: 3px;
}

.c3gp_gifts_page_main::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.c3gp_gifts_page_main_title {
    font-size: 24px;
    font-weight: 600;
    color: $primaryColor;
    margin-bottom: 14px;
    padding-top: 20px;
}

.c3gp_gifts_page_main_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    margin-bottom: 53px;
}

.c3gp_gifts_page_main_item {
    width: 100%;
    background-color: $whiteColor;
    box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
    border-radius: 10px;
    padding: 6px 7px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.c3gp_gifts_page_main_item_discount_label {
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 105px;
        height: 105px;
        position: absolute;
        right: -6px;
        top: -6px;
        background: url(../img/gifts/gift-discount-label-pink.svg) center no-repeat;
        background-size: contain;
        z-index: 0;
    }
}

.c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_discount_amount {
    color: #FFFFFF;
    position: absolute;
    right: 5px;
    top: 15px;
    transform: rotateZ(45deg);
    z-index: 1;
    font-size: 20px;
    font-weight: 700;
}

.c3gp_gifts_page_main_item_img {
    width: 100%;
    display: block;
    border-radius: 10px;
    height: auto;
    object-fit: contain;
}

.c3gp_gifts_page_main_item_title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    text-align: center;
    min-height: 44px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 11px;
    margin-top: 7px;
    padding: 0 9px;
    flex: 1;
}

.c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_title {
    min-height: auto;
    margin-bottom: 12px;
}

.c3gp_gifts_page_main_item_bottom_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c3gp_gifts_page_main_item_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    padding: 0 9px;
}

.c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_bottom {
    flex-direction: column;
    gap: 16px;
}

.c3gp_gifts_page_main_item_price {
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $accentRightColor;
}

.c3gp_gifts_page_main_item_price_with_discount {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $accentRightColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.c3gp_gifts_page_main_item_price_with_discount span {
    width: 104px;
    text-align: center;
}

.c3gp_gifts_page_main_item_price_with_discount .c3gp_gifts_page_main_item_old_price {
    font-size: 12px;
    font-weight: 400;
    color: #6f6f6f;
    text-decoration-line: line-through;
}

.c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_add_btn {
    width: 150px;
    height: 36px;
}

.c3gp_gifts_page_main_item_add_btn {
    width: 154px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: linear-gradient(90.54deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 5px;
    color: $whiteColor;
    font-weight: 500;
    cursor: pointer;
}

.c3gp_gifts_page_main_item_add_btn:hover {
    box-shadow: $searchItemBtnShadowHover;
}

.c3gp_gifts_page_main_item_add_btn img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 14px;
}

.c3gp_gifts_page_main_item_add_btn svg {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 14px;
    width: 17px;
    height: 17px;
    fill: #FFFFFF;
}

.c3gp_gifts_page_main_item_added {
    width: 154px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: $background1Color;
    border-radius: 5px;
    color: $defaultTextColor;
    font-weight: 500;
}

.c3gp_gifts_page_main_item_added img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 9px;
}

.c3gp_gifts_page_main_item_added svg {
    width: 15px;
    height: 15px;
    fill: #274359;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 9px;
}

/********************
*********************
gifts cart
********************
********************/

.c3gp_gifts_cart_head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    flex: none;
}

.c3gp_gifts_cart_head_return_btn {
    width: 170px;
    height: 34px;
    line-height: 34px;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    text-align: center;
    background-color: $whiteColor;
    font-weight: 600;
    color: $primaryColor;
    cursor: pointer;
}

.c3gp_gifts_cart_head_title {
    font-weight: 600;
    font-size: 26px;
    color: $accentLeftColor;
}

.c3gp_gifts_cart_head_empty {
    width: 170px;
}

.c3gp_gifts_cart_main_wrap {
    padding-right: 20px;
    flex: 1;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    //height: calc(100% - 80px);
    overflow: hidden;
}

.c3gp_gifts_cart_main_wrap::-webkit-scrollbar {
    width: 3px;
    background: $whiteColor;
    border-radius: 3px;
}

.c3gp_gifts_cart_main_wrap::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.c3gp_gifts_cart_main {
    min-height: 100%;
    background-color: $whiteColor;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c3gp_gifts_cart_main_list {
    overflow-y: auto;
    margin-right: 13px;
    padding-right: 15px;
    padding-left: 32px;
}

.c3gp_gifts_cart_main_list::-webkit-scrollbar {
    width: 3px;
    background: $bleachColor;
    border-radius: 3px;
}

.c3gp_gifts_cart_main_list::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border-radius: 3px;
}

.c3gp_gifts_cart_main_item {
    display: grid;
    grid-template-areas: 'gift_gallery gift_title gift_remove' 'gift_gallery gift_desc gift_remove';
    -ms-align-items: center;
    align-items: center;
    grid-template-columns: 300px 1fr 116px;
    padding-bottom: 34px;
    margin-bottom: 31px;
    border-bottom: 1px solid $defaultTextColorAlpha1;
}

.c3gp_gifts_cart_main_item:last-child {
    margin-bottom: 0;
    border: none;
}

.c3gp_gifts_cart_main_item_gallery {
    width: 100%;
    grid-area: gift_gallery;
    margin-left: 20px;
}

.c3gp_gifts_cart_main_item_gallery_big {
    width: 100%;
    cursor: pointer;
    display: block;
    margin-bottom: 20px;
}

.c3gp_gifts_cart_main_item_gallery_big_wrap_discount_label {
    position: relative;
	margin-top: 6px;

    &:after {
        content: '';
        display: block;
        width: 105px;
        height: 105px;
        position: absolute;
        right: -6px;
        top: -6px;
        background: url(../img/gifts/gift-discount-label-red.svg) center no-repeat;
        background-size: contain;
        z-index: 0;

		@media screen and (max-width: 1139px) {
			width: 67px;
			height: 67px;
			background: url(../img/gifts/gift-discount-label-red-mobile.svg) center no-repeat;
		}
    }
}

.c3gp_gifts_cart_main_item_gallery_big_wrap_discount_label .c3gp_gifts_cart_main_item_gallery_big_wrap_discount_amount {
    color: #FFFFFF;
    position: absolute;
    right: 5px;
    top: 15px;
    transform: rotateZ(45deg);
    z-index: 1;
    font-size: 20px;
    font-weight: 700;

    @media screen and (max-width: 1139px) {
        right: 0;
        top: 10px;
        font-size: 12px;
    }
}

.c3gp_gifts_cart_main_item_gallery_list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.c3gp_gifts_cart_main_item_gallery_list img {
    width: 61px;
    cursor: pointer;
    display: block;
    margin-right: 12px;
}

.c3gp_gifts_cart_main_item_gallery_list img:last-child {
    margin-right: 0;
}

.c3gp_gifts_cart_main_item_title {
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    margin-top: auto;
    grid-area: gift_title;
    padding-left: 49px;
    padding-bottom: 5px;
}

.c3gp_gifts_cart_main_item_desc_wrap {
    grid-area: gift_desc;
    margin-bottom: auto;
    padding-left: 49px;
    padding-top: 5px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.c3gp_gifts_cart_main_item_desc_text {
    font-size: 16px;
    color: $stormGreyColor;
    width: 414px;
}

.c3gp_gifts_cart_main_item_desc_price {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.01em;
    color: $defaultTextColor;
    margin-top: 19px;
}

.c3gp_gifts_cart_main_item_desc_price span {
    color: $accentRightColor;
    font-weight: 600;
    margin-left: 24px;
}

.c3gp_gifts_cart_main_item_desc_price_with_discount .c3gp_gifts_cart_main_item_desc_old_price {
	font-size: 14px;
	font-weight: 400;
	color: #6f6f6f;
	text-decoration-line: line-through;
}

.c3gp_gifts_cart_main_item_desc_count_wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% + 225px);
    transform: translateY(-50%) translateX(-50%);
}

.c3gp_gifts_cart_main_item_desc_count_input {
    width: 68px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid $defaultTextColorAlpha8;
    border-radius: 5px;
    margin: 0 18px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: $defaultTextColor;
    outline: none;
}

.c3gp_gifts_cart_main_item_desc_count_plus,
.c3gp_gifts_cart_main_item_desc_count_minus {
    cursor: pointer;
    height: 38px;
    line-height: 38px;
}

.c3gp_gifts_cart_main_item_desc_count_plus img,
.c3gp_gifts_cart_main_item_desc_count_minus img {
    vertical-align: middle;
}

.c3gp_gifts_cart_main_item_desc_count_plus img {
    margin-bottom: 2px;
}

.c3gp_gifts_cart_main_item_remove {
    font-weight: 500;
    letter-spacing: 0.01em;
    color: $counterAndErrorColor;
    grid-area: gift_remove;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
    cursor: pointer;
}

.c3gp_gifts_cart_main_item_remove img {
    margin-right: 13px;
}

.c3gp_gifts_cart_main_item_remove svg {
    margin-right: 13px;
    fill: #eb5757;
    width: 32px;
    height: 32px;
}

.c3gp_gifts_cart_main_order_wrap {
    padding: 12px 32px 0;
    border-top: 3px solid $background1Color;
}

.c3gp_gifts_cart_main_order_title {
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    margin-bottom: 33px;
}

.c3gp_gifts_cart_main_order_addressee {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 41px;
}

.c3gp_gifts_cart_main_order_addressee_title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: $primaryColor;
    margin-right: 45px;
}

.c3gp_gifts_cart_main_order_addressee_choose {
    width: 157px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $primaryColor;
    cursor: pointer;
}

.c3gp_gifts_cart_main_order_addressee_choose:hover {
    box-shadow: $searchItemLikeBtnShadowHover;
}

.c3gp_gifts_cart_main_order_addressee_item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.c3gp_gifts_cart_main_order_addressee_item_photo {
    position: relative;
    margin-right: 14px;
}

.c3gp_gifts_cart_main_order_addressee_item_photo:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: 0;
    right: 4px;
    border-radius: 100%;
    background-color: $bleachColor;
    border: 2px solid $whiteColor;
}

.c3gp_gifts_cart_main_order_addressee_item_photo.online:after {
    background-color: $onlineColor;
}

.c3gp_gifts_cart_main_order_addressee_item_photo img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    display: block;
}

.c3gp_gifts_cart_main_order_addressee_item_info {
    display: grid;
    grid-template-areas: 'addr_name addr_name' 'addr_id addr_place';
}

.c3gp_gifts_cart_main_order_addressee_item_id {
    font-size: 12px;
    color: $arsenicColor;
    grid-area: addr_id;
    padding-top: 3px;
}

.c3gp_gifts_cart_main_order_addressee_item_name {
    font-weight: bold;
    font-size: 18px;
    color: $primaryColor;
    grid-area: addr_name;
    margin-top: auto;
    padding-bottom: 3px;
}

.c3gp_gifts_cart_main_order_addressee_item_place {
    font-weight: 500;
    font-size: 12px;
    color: $arsenicColor;
    grid-area: addr_place;
    padding-top: 2px;
    margin-left: 12px;
}

.c3gp_gifts_cart_main_order_addressee_item_place img {
    vertical-align: middle;
    height: 12px;
    margin-right: 6px;
    margin-bottom: 1px;
    border-radius: 4px;
}

.c3gp_gifts_cart_main_order_addressee_item_remove {
    margin-left: 31px;
    cursor: pointer;
}

.c3gp_gifts_cart_main_order_addressee_item_remove img {
    width: 32px;
    display: block;
}

.c3gp_gifts_cart_main_order_addressee_item_remove svg {
    width: 32px;
    height: 32px;
    display: block;
    fill: #eb5757;
}

.c3gp_gifts_cart_main_order_messages_wrap {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.c3gp_gifts_cart_main_order_message_item {
    width: calc(50% - 10px);
}

.c3gp_gifts_cart_main_order_message_item_title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: $primaryColor;
    margin-bottom: 11px;
}

.c3gp_gifts_cart_main_order_message_item_textarea {
    background-color: #eff3f8;
    border-radius: 5px;
    padding: 14px;
    border: 1px solid #9aafd0;
    color: #00317b;
}

.c3gp_gifts_cart_main_order_message_item_textarea:hover,
.c3gp_gifts_cart_main_order_message_item_textarea:focus {
    outline: none;
    border-color: #5577ad;
    box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.c3gp_gifts_cart_main_order_message_item_textarea textarea {
    background-color: #eff3f8;
    resize: none;
    display: block;
    width: 100%;
    height: 80px;
    border: none;
    outline: none;
    color: $stormGreyColor;
    line-height: 21px;
    font-size: 14px;
}

.popup_compose_head_search input::placeholder {
    font-weight: 500;
    color: #333;
}

.c3gp_gifts_cart_main_order_message_item_textarea textarea::-webkit-scrollbar {
    width: 2px;
    background: $whiteColor;
    border-radius: 22px;
}

.c3gp_gifts_cart_main_order_message_item_textarea textarea::-webkit-scrollbar-thumb {
    background: $accentLeftColor;
    border-radius: 9px;
}

.c3gp_gifts_cart_main_order_message_item_info {
    margin-top: 8px;
    color: $defaultTextColorAlpha6;
    font-size: 12px;
}

.c3gp_gifts_cart_main_order_results {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: flex-end;
}

.c3gp_gifts_cart_main_order_results_price {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.01em;
    color: $defaultTextColor;
}

.c3gp_gifts_cart_main_order_results_price span {
    font-weight: 600;
    color: $accentRightColor;
    margin-left: 21px;
}

.c3gp_gifts_cart_main_order_results_error {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    width: 325px;
    margin-top: 5px;
    color: $defaultTextColorAlpha9;
    margin-left: auto;
}

.c3gp_gifts_cart_main_order_results_buy_btn {
    width: 325px;
    height: 60px;
    line-height: 60px;
    background: linear-gradient(90.74deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 5px;
    text-align: center;
    color: $whiteColor;
    font-weight: 600;
    font-size: 20px;
    margin-left: 52px;
    cursor: pointer;
}

.c3gp_gifts_cart_main_order_results_buy_btn:not(.disabled):hover {
    box-shadow: $searchItemBtnShadowHover;
}

.c3gp_gifts_cart_main_order_results_buy_btn.disabled {
    opacity: 0.6;
    cursor: default;
}

.c3gp_gifts_cart_main_order_results_buy_btn img {
    vertical-align: middle;
    width: 24px;
    margin-bottom: 4px;
    margin-right: 14px;
}

.c3gp_gifts_cart_main_order_results_buy_btn svg {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
    margin-right: 14px;
    fill: #FFFFFF;
}

/********************
*********************
popup gifts details
********************
********************/

.popup_gift_details_wrap {
    background-color: $whiteColor;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    width: 990px;
    max-width: calc(100vw - 30px);
}

.popup_gift_details {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.popup_gift_details_gallery {
    width: 300px;
    margin-right: 25px;
    -webkit-flex: none;
    -moz-flex: none;
    -ms-flex: none;
    -o-flex: none;
    flex: none;
}

.popup_gift_details_gallery_big {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
}

.popup_gift_details_gallery_big_wrap_discount_label {
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 105px;
        height: 105px;
        position: absolute;
        right: -6px;
        top: -6px;
        background: url(../img/gifts/gift-discount-label-red.svg) center no-repeat;
        background-size: contain;
        z-index: 0;
    }
}

.popup_gift_details_gallery_big_wrap_discount_label .popup_gift_details_gallery_big_wrap_discount_amount {
    color: #FFFFFF;
    position: absolute;
    right: 5px;
    top: 15px;
    transform: rotateZ(45deg);
    z-index: 1;
    font-size: 20px;
    font-weight: 700;
}

.popup_gift_details_gallery_list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.popup_gift_details_gallery_list img {
    width: 60px;
    margin-right: 12px;
    cursor: pointer;
}

.popup_gift_details_gallery_list img:last-child {
    margin-right: 0;
}

.popup_gift_details_info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-self: flex-end;
    align-self: flex-end;
}

.popup_gift_details_title {
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    margin-bottom: 15px;
}

.popup_gift_details_desc {
    font-size: 16px;
    line-height: 1.5;
    color: $stormGreyColor;
    margin-bottom: 35px;
}

.popup_gift_details_price {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.01em;
    color: $defaultTextColor;
}

.popup_gift_details_price span {
    font-weight: 600;
    color: $accentRightColor;
    margin-left: 30px;
}

.popup_gift_details_price_with_discount {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $accentRightColor;
}

.popup_gift_details_price_with_discount .popup_gift_details_old_price {
    font-size: 12px;
    font-weight: 400;
    color: #6f6f6f;
    text-decoration-line: line-through;
    margin-left: 15px;
}

.popup_gift_details_add_btn {
    width: 154px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(90.54deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 5px;
    text-align: center;
    color: $whiteColor;
    font-weight: 500;
    margin-top: 37px;
    margin-left: auto;
    margin-bottom: 5px;
    cursor: pointer;
}

.popup_gift_details_add_btn:hover {
    box-shadow: $searchItemBtnShadowHover;
}

.popup_gift_details_add_btn img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 14px;
}

.popup_gift_details_item_added {
    width: 154px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: $background1Color;
    border-radius: 5px;
    color: $defaultTextColor;
    font-weight: 500;
    margin-top: 37px;
    margin-left: auto;
    margin-bottom: 5px;
}

.popup_gift_details_item_added img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 9px;
}

.popup_gift_details_item_added svg {
    width: 17px;
    height: 17px;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 9px;
    fill: $defaultTextColor;
}

.popup_gift_details_close {
    position: absolute;
    right: 19px;
    top: 19px;
    padding: 5px;
    cursor: pointer;
}

.popup_gift_details_close img {
    display: block;
}

.popup_gift_details_close svg {
    width: 36px;
    height: 36px;
    fill: $iconCrossCloseFillColor;
}

/********************
*********************
popup gifts cart
********************
********************/

.popup_gifts_cart_wrap {
    background-color: $whiteColor;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    width: 746px;
    height: 517px;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 30px);
}

.popup_gifts_cart {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.popup_gifts_cart_title {
    font-weight: 600;
    font-size: 24px;
    color: $primaryColor;
    margin-bottom: 19px;
}

.popup_gifts_cart_list {
    -webkit-flex: 1px;
    -moz-flex: 1px;
    -ms-flex: 1px;
    -o-flex: 1px;
    flex: 1px;
    margin-bottom: 34px;
    overflow-y: auto;
    margin-right: -13px;
    padding-right: 13px;
}

.popup_gifts_cart_list::-webkit-scrollbar {
    width: 3px;
    background: $bleachColor;
    border-radius: 3px;
}

.popup_gifts_cart_list::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
}

.popup_gifts_cart_item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $defaultTextColorAlpha1;
    margin-bottom: 10px;
}

.popup_gifts_cart_item:last-child {
    margin-bottom: 0;
    border: none;
}

.popup_gifts_cart_item_top {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.popup_gifts_cart_item_img {
    width: 154px;
    display: block;
    margin-right: 22px;
}

.popup_gifts_cart_item_img_wrap_discount_label {
    position: relative;
    width: 154px;
    display: block;
    margin-right: 22px;
    margin-top: 10px;

    &:after {
        content: '';
        display: block;
        width: 67px;
        height: 67px;
        position: absolute;
        right: -6px;
        top: -6px;
        background: url(../img/gifts/gift-discount-label-red-mobile.svg) center no-repeat;
        background-size: contain;
        z-index: 0;
    }
}

.popup_gifts_cart_item_img_wrap_discount_label .popup_gifts_cart_item_img_wrap_discount_amount {
    color: #FFFFFF;
    position: absolute;
    right: 0;
    top: 10px;
    transform: rotateZ(45deg);
    z-index: 1;
    font-size: 12px;
    font-weight: 700;
}

.popup_gifts_cart_item_img_wrap_discount_label .popup_gifts_cart_item_img {
    margin-right: 0;
}

.popup_gifts_cart_item_info {
    width: 220px;
}

.popup_gifts_cart_item_title {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
    margin-bottom: 13px;
}

.popup_gifts_cart_item_category {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $defaultTextColorAlpha8;
}

.popup_gifts_cart_item_category span {
    font-weight: 600;
    color: $defaultTextColor;
    margin-left: 12px;
}

.popup_gifts_cart_item_bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.popup_gifts_cart_item_price {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $defaultTextColorAlpha8;
}

.popup_gifts_cart_item_price span {
    font-weight: 600;
    font-size: 14px;
    color: $accentRightColor;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
}

.popup_gifts_cart_item_price_with_discount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 110px;
}

.popup_gifts_cart_item_price_with_discount .popup_gifts_cart_item_old_price {
    font-size: 12px;
    font-weight: 400;
    color: #6f6f6f;
    text-decoration-line: line-through;
    order: 1;
}

.popup_gifts_cart_item_price_with_discount span {
    order: 2;
}

.popup_gifts_cart_item_remove {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    margin-left: 50px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $counterAndErrorColor;
    width: 90px;
    cursor: pointer;
}

.popup_gifts_cart_item_remove img {
    margin-right: 10px;
    width: 20px;
}

.popup_gifts_cart_item_remove svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    fill: #eb5757;
}

.popup_gifts_cart_buttons {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}

.popup_gifts_cart_continue_btn {
    width: 230px;
    height: 36px;
    line-height: 36px;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    background-color: $whiteColor;
    text-align: center;
    color: $primaryColor;
    font-weight: 500;
    cursor: pointer;
}

.popup_gifts_cart_continue_btn:hover {
    box-shadow: $searchItemLikeBtnShadowHover;
}

.popup_gifts_cart_continue_btn_back {
    display: none;
}

.popup_gifts_cart_go_to_cart_btn {
    width: 169px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(90.59deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 5px;
    text-align: center;
    color: $whiteColor;
    font-weight: 500;
    cursor: pointer;
}

.popup_gifts_cart_go_to_cart_btn:hover {
    box-shadow: $searchItemBtnShadowHover;
}

.popup_gifts_cart_go_to_cart_btn img {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 15px;
}

.popup_gifts_cart_close {
    position: absolute;
    right: 25px;
    top: 26px;
    padding: 2px;
    cursor: pointer;
}

.popup_gifts_cart_close img {
    display: block;
}

.popup_gifts_cart_close svg {
    display: block;
    width: 34px;
    height: 34px;
    fill: $iconCrossCloseFillColor;
}

.gifts_page_mob_buy_btn {
    display: none;
    width: 133px;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(90.52deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    text-align: center;
    color: $whiteColor;
    font-weight: 600;
    letter-spacing: 0.01em;
    position: fixed;
    bottom: 14px;
    right: 14px;
}

.gifts_page_mob_buy_btn img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 7px;
    width: 15px;
}

.popup_gifts_cart_continue_btn span {
    display: none;
}

/********************
*********************
gifts page media
********************
********************/

@media screen and (max-width: 1799px) {
    .c3gp_gifts_cart_main_item {
        grid-template-columns: 300px 1fr 116px;
    }

    .c3gp_gifts_cart_main_item_desc_wrap {
        padding-right: 40px;
    }

    .c3gp_gifts_cart_main_item_desc_count_wrap {
        position: static;
        transform: unset;
        margin-left: 0;
        margin-top: 40px;
    }
}

@media screen and (max-width: 1599px) {
    .c3gp_gifts_page_head_category_item {
        height: 33px;
        line-height: 33px;
        padding: 10px 11px 0;
        padding-top: 0;
        font-size: 12px;
    }

    .c3gp_gifts_page_head_title {
        font-size: 13px;
        width: 85px;
    }

    .c3gp_gifts_page_head_show_all {
        font-size: 12px;
        width: 65px;
    }

    .c3gp_gifts_page_head_cart_btn {
        height: 33px;
        line-height: 33px;
    }

    .c3gp_gifts_page_head_cart_btn img {
        width: 17px;
        margin-right: 9px;
    }

    .c3gp_gifts_page_head_cart_btn_count {
        height: 23px;
        line-height: 23px;
        padding: 0 6px;
        margin-left: 29px;
    }

    .c3gp_gifts_cart_main {
        padding: 24px 0;
    }

    .c3gp_gifts_cart_main_order_wrap {
        padding: 12px 24px 0;
    }
}

@media screen and (max-width: 1449px) {
    .c3gp_gifts_cart_main_item_desc_text {
        width: 301px;
        font-size: 12px;
    }

    .c3gp_gifts_cart_main_item {
        grid-template-columns: 230px 1fr 105px;
    }

    .c3gp_gifts_cart_main_item_gallery_list img {
        width: 46px;
    }

    .c3gp_gifts_cart_main_item_gallery_big {
        margin-bottom: 15px;
    }

    .c3gp_gifts_cart_main_item_title {
        font-size: 20px;
    }

    .c3gp_gifts_cart_main_item_desc_price {
        font-size: 14px;
    }

    .c3gp_gifts_cart_main_item_desc_count_input {
        width: 55px;
        height: 31px;
        margin: 0 15px;
    }

    .c3gp_gifts_cart_main_item_desc_count_plus img,
    .c3gp_gifts_cart_main_item_desc_count_minus img {
        width: 13px;
    }

    .c3gp_gifts_cart_main_item_remove {
        font-size: 12px;
    }

    .c3gp_gifts_cart_main_item_remove img {
        width: 32px;
    }

    .c3gp_gifts_cart_main_item_remove svg {
        width: 32px;
        height: 32px;
    }

    .c3gp_gifts_cart_main_order_title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .c3gp_gifts_cart_main_order_addressee_title {
        font-size: 16px;
    }

    .c3gp_gifts_cart_main_order_addressee_item_photo img {
        width: 40px;
        height: 40px;
    }

    .c3gp_gifts_cart_main_order_addressee_item_photo:after {
        width: 8px;
        height: 8px;
        right: 0;
    }

    .c3gp_gifts_cart_main_order_addressee_item_name {
        font-size: 16px;
    }

    .c3gp_gifts_cart_main_order_addressee_item_remove img {
        width: 22px;
    }

    .c3gp_gifts_cart_main_order_addressee_item_remove svg {
        width: 22px;
        height: 22px;
    }

    .c3gp_gifts_cart_main_order_addressee_choose {
        width: 134px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .c3gp_gifts_cart_main_order_addressee {
        margin-bottom: 28px;
    }

    .c3gp_gifts_cart_main_order_message_item_title {
        font-size: 16px;
    }

    .c3gp_gifts_cart_main_order_message_item_textarea textarea {
        height: 50px;
    }

    .c3gp_gifts_cart_main_order_results_price {
        font-size: 18px;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn {
        margin-left: 35px;
        width: 265px;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn img {
        width: 20px;
        margin-bottom: 2px;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
    }

    .c3gp_gifts_page_main_list {
        grid-template-columns: repeat(3, 1fr);
    }

    .c3gp_gifts_page_main_item_bottom {
        padding: 0;
    }
}

@media screen and (min-width: 600px) and (max-width: 1499px) {
    /*.c3gp_gifts_page_main_list{
		grid-template-columns: repeat(auto-fill, 245px);
		grid-gap: 14px;
	}*/

    .c3gp_gifts_page_main_item_title {
        font-size: 15px;
    }

    .c3gp_gifts_page_main_item_price {
        text-align: center;
        width: 55px;
    }

    .c3gp_gifts_page_main_item_price_with_discount {
        width: 100%;
    }

    .c3gp_gifts_page_main_item_added {
        width: 134px;
        font-size: 12px;
    }

    .c3gp_gifts_page_main_item_add_btn {
        width: 134px;
    }

    .c3gp_gifts_page_main_item_add_btn img {
        width: 15px;
    }

    .c3gp_gifts_page_main_item_add_btn svg {
        width: 15px;
        height: 15px;
    }

    .gifts_page_wrap {
        padding-left: 24px;
    }

    .c3gp_gifts_page_main {
        margin-right: 7px;
        padding-right: 16px;
    }
}

@media (hover: none) and (pointer: coarse) {
    @media screen and (min-width: 1000px) {
        .c3gp_gifts_page_main_list {
            grid-template-columns: repeat(4, 1fr);
        }

        .c3gp_gifts_page_head_cart_btn {
            width: 114px;
        }

        .c3gp_gifts_page_head_cart_btn_count {
            font-size: 14px;
            margin-left: 10px;
        }

        .c3gp_gifts_page_head_category_item {
            margin-right: 8px;
        }

        .c3gp_gifts_page_main_list {
            grid-gap: 10px;
        }

        .c3gp_gifts_page_main_item_add_btn {
            font-size: 12px;
        }

        .c3gp_gifts_page_main_item_price {
            line-height: 1.2;
        }

        .c3gp_gifts_page_main_item_price span {
            font-size: 12px;
        }

        .c3gp_gifts_page_main_item_price_with_discount span {
            font-size: 14px;
        }

        .c3gp_gifts_page_main_item_bottom {
            padding: 0 9px;
        }

        .c3gp_gifts_page_main_list {
            margin-bottom: 40px;
        }

        .c3gp_gifts_page_main_title {
            font-size: 18px;
        }

        .c3gp_gifts_page_head {
            padding-right: 26px;
        }
    }
}

@media screen and (max-width: 999px) {
    .c3gp_gifts_cart_main_list {
        padding-left: 5px;
    }

    .c3gp_gifts_cart_main_item {
        grid-template-columns: 150px 1fr 105px;
        padding-bottom: 10px;
    }

    .c3gp_gifts_page_head {
        padding-right: 37px;
    }

    .c3gp_gifts_cart_head_empty {
        display: none;
    }

    .c3gp_gifts_cart_head {
        justify-content: center;
    }

    .c3gp_gifts_cart_head_return_btn {
        display: none;
    }
}

@media screen and (max-width: 899px) {
    //.c3gp_gifts_cart_main_item_desc_wrap {
    //    padding-right: 10px;
    //    padding-left: 20px;
    //}

    //.c3gp_gifts_cart_main_item_title {
    //    padding-left: 20px;
    //}

    .popup_gift_details_gallery {
        width: 200px;
    }

    .popup_gift_details_gallery_list img {
        width: 45px;
    }

    .popup_gift_details_title {
        font-size: 24px;
    }

    .popup_gift_details_desc {
        font-size: 14px;
    }

    .popup_gift_details_price {
        font-size: 18px;
    }

    .c3gp_gifts_cart_main_wrap {
        height: auto;
        margin-right: 0;
        padding-right: 10px;
    }

    .gifts_page_wrap {
        position: relative;
    }

    .gifts_page_mob_buy_btn {
        display: block;
    }
}

@media screen and (max-width: 799px), screen and (max-height: 800px) {
    .c3gp_gifts_cart_head {
        height: 60px;
    }

    .c3gp_gifts_cart_head_title {
        font-size: 18px;
    }

    .c3gp_gifts_cart_main_item {
        grid-template-columns: 158px 1fr 105px;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 16px;
        grid-column-gap: 20px;
    }

    .c3gp_gifts_cart_main_item_gallery_big {
        margin-bottom: 10px;
    }

    .c3gp_gifts_cart_main_item_gallery_list img {
        width: 32px;
        height: 32px;
    }

    .c3gp_gifts_cart_main_item_title {
        margin-top: 0;
        font-size: 16px;
        padding-left: 0;
    }

    .c3gp_gifts_cart_main_item_desc_wrap {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .c3gp_gifts_cart_main_item_desc_text {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 19px;
    }

    .c3gp_gifts_cart_main_item_remove span {
        display: none;
    }

    .c3gp_gifts_cart_main {
        padding: 15px;
    }

    .c3gp_gifts_cart_main_item_desc_count_wrap {
        margin-top: 0;
    }

    .c3gp_gifts_cart_main_item_desc_price {
        margin-top: 0;
    }

    .popup_gifts_cart_item_title {
        font-size: 14px;
        margin-bottom: 7px;
    }

    .popup_gifts_cart_item_remove {
        margin-left: 0;
    }

    .popup_gifts_cart_item_info {
        width: auto;
    }

    .c3gp_gifts_cart_main_order_wrap {
        padding-right: 0;
        padding-left: 0;
    }

    .c3gp_gifts_cart_main_list {
        margin-right: 0;
        padding-right: 0;
        padding-left: 0;
    }

    .c3gp_gifts_page_head_cart_btn {
        width: 114px;
    }

    .c3gp_gifts_page_head_cart_btn_count {
        font-size: 14px;
        margin-left: 10px;
    }

    .c3gp_gifts_page_head_category_item {
        margin-right: 8px;
    }

    .c3gp_gifts_page_main_list {
        grid-gap: 10px;
    }

    .c3gp_gifts_page_main_item_add_btn {
        font-size: 12px;
    }

    .c3gp_gifts_page_main_item_price {
        line-height: 1.2;
    }

    .c3gp_gifts_page_main_item_price span {
        font-size: 12px;
    }

    .c3gp_gifts_page_main_item_price_with_discount span {
        font-size: 14px;
    }

    .c3gp_gifts_page_main_item_bottom {
        padding: 0 9px;
    }

    .c3gp_gifts_page_main_list {
        margin-bottom: 40px;
    }

    .c3gp_gifts_page_main_title {
        font-size: 18px;
    }

    .c3gp_gifts_page_head {
        padding-right: 26px;
    }

    .c3gp_gifts_cart_main_item_gallery {
        margin-left: 0;
    }
}

@media screen and (max-height: 800px) {
    .c3gp_gifts_cart_main {
        overflow: auto;
    }

    .c3gp_gifts_cart_main::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 3px;
    }

    .c3gp_gifts_cart_main::-webkit-scrollbar-thumb {
        background: $scrollbarThumbColor;
        border-radius: 3px;
    }

    .c3gp_gifts_cart_main_list {
        overflow-y: visible;
    }
}

@media screen and (max-width: 767px) {
    .gifts_page_wrap {
        overflow-y: auto;
    }

    .gifts_page_wrap::-webkit-scrollbar {
        width: 3px;
        background: $whiteColor;
        border-radius: 3px;
    }

    .gifts_page_wrap::-webkit-scrollbar-thumb {
        background: $scrollbarThumbColor;
        border-radius: 3px;
    }

    .gifts_page_wrap {
        //padding-bottom: 60px;
    }

    .c3gp_gifts_cart_main {
        //display: block;
        //min-height: unset;
    }

    .c3gp_gifts_cart_main_wrap {
        overflow: visible;
        flex: none;
        padding-bottom: 70px;
    }

    .c3gp_gifts_cart_main_list {
        overflow: visible;
    }

    .c3gp_gifts_cart_main_item {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .c3gp_gifts_cart_main_order_messages_wrap {
        flex-wrap: wrap;
    }

    .c3gp_gifts_cart_main_item_gallery {
        width: 158px;
    }

    .c3gp_gifts_cart_main_order_message_item {
        width: 100%;
        margin-bottom: 28px;
    }

    .c3gp_gifts_cart_main_order_message_item:last-child {
        margin-bottom: 0;
    }

    .c3gp_gifts_cart_main_order_message_item_textarea textarea {
        height: 112px;
    }

    .c3gp_gifts_cart_main_order_messages_wrap {
        flex-wrap: wrap;
    }

    .c3gp_gifts_cart_main_item_remove {
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .c3gp_gifts_cart_main_item_remove img {
        margin-right: 0;
        width: 20px;
    }

    .c3gp_gifts_cart_main_item_remove svg {
        margin-right: 0;
        width: 20px;
        height: 20px;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn {
        margin-left: 0;
        width: 300px;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn_wrap {
        padding-bottom: 13px;
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .c3gp_gifts_cart_main_order_results_buy_btn_wrap:before {
        content: '';
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 200vw;
        height: 126px;
        z-index: -1;
        pointer-events: none;
        transform: translateX(-50%);
        background: linear-gradient(0deg, #ebedfa 0%, rgba(255, 255, 255, 0) 100%);
    }
}

@media screen and (max-width: 760px) {
    .c3gp_gifts_page_main_list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 699px) {
    .popup_gifts_cart_item_img {
        width: 100px;
    }

    .popup_gifts_cart_item_img_wrap_discount_label {
        width: 100px;
    }

    .popup_gift_details_title {
        font-size: 18px;
    }

    .popup_gift_details_close img {
        width: 14px;
    }

    .popup_gift_details_close svg {
        width: 22px;
        height: 22px;
    }

    .popup_gift_details_close {
        right: 3px;
        top: 3px;
    }

    .popup_gift_details_wrap {
        padding: 20px;
    }

    .popup_gift_details_desc {
        font-size: 12px;
    }

    .popup_gifts_cart_wrap {
        height: 410px;
    }
}

@media screen and (max-width: 599px) {
    .gifts_page_wrap {
        padding: 0 7px;
    }

    .c3gp_gifts_page_main_list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }

    .c3gp_gifts_page_main_item_top {
        position: relative;
        padding-top: 100%;
    }

    .c3gp_gifts_page_main_list {
        justify-content: center;
        margin-bottom: 24px;
    }

    .c3gp_gifts_page_head {
        padding: 13px 8px 0;
        position: relative;
    }

    .c3gp_gifts_page_head:not(.active) .c3gp_gifts_page_head_category_item:nth-child(6) {
        display: none;
    }

    .c3gp_gifts_page_main_title {
        font-size: 20px;
        padding-left: 8px;
    }

    .c3gp_gifts_page_head_title {
        /*width: 138px;
		margin-right: calc(100% - 140px);
*/
        display: none;
    }

    .c3gp_gifts_page_head_cart_btn {
        /*position: absolute;
		top: 13px;
		right: 15px;
*/
        width: 79px;
    }

    .c3gp_gifts_page_head_cart_btn span {
        display: none;
    }

    .c3gp_gifts_page_head_cart_btn .c3gp_gifts_page_head_cart_btn_count {
        display: inline-block;
        margin-left: 0;
    }

    .c3gp_gifts_page_main {
        margin-right: -7px;
        padding-right: 7px;
    }

    .c3gp_gifts_page_head_category_item {
        /*display: none;
*/
    }

    .c3gp_gifts_page_head_category_item:nth-child(1),
    .c3gp_gifts_page_head_category_item:nth-child(2),
    .c3gp_gifts_page_head_category_item:nth-child(3),
    .c3gp_gifts_page_head_category_item:nth-child(4),
    .c3gp_gifts_page_head_category_item:nth-child(5) {
        display: block;
    }

    .c3gp_gifts_page_main::-webkit-scrollbar {
        background: transparent;
    }

    .c3gp_gifts_cart_main_wrap {
        margin-right: 0;
        padding-right: 0;
    }

    .c3gp_gifts_cart_main_item_gallery {
        width: 120px;
    }

    .c3gp_gifts_cart_main_item_gallery_list img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    .c3gp_gifts_cart_main_item_title {
        font-size: 13px;
        -webkit-flex: 1px;
        -moz-flex: 1px;
        -ms-flex: 1px;
        -o-flex: 1px;
        flex: 1px;
    }

    .c3gp_gifts_page_main_item_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .c3gp_gifts_page_main_item_bottom {
        display: block;
    }

    .c3gp_gifts_page_main_item_discount_label {
        padding: 6px 4px 16px;
    }

    .c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_bottom {
        display: flex;
        gap: 6px;
    }

    .c3gp_gifts_page_main_item_added,
    .c3gp_gifts_page_main_item_add_btn {
        margin: 0 auto;
        width: 100%;
        max-width: 154px;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
    }

    .c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_add_btn {
        margin-bottom: 8px;
    }

    .c3gp_gifts_page_main_item_add_btn img {
        width: 15px;
        margin-right: 10px;
    }

    .c3gp_gifts_page_main_item_add_btn svg {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    .c3gp_gifts_page_main_item {
        padding-bottom: 7px;
    }

    .c3gp_gifts_page_main_item_title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .c3gp_gifts_page_main_item_discount_label .c3gp_gifts_page_main_item_title {
        margin-bottom: 8px;
    }

    .c3gp_gifts_page_main_item_price {
        text-align: center;
        margin-bottom: 8px;
    }

    .c3gp_gifts_page_main_item_price_with_discount {
        text-align: center;
        margin-bottom: 0;
    }

    .c3gp_gifts_cart_main_item_desc_price {
        font-size: 10px;
    }

    .c3gp_gifts_cart_main_item_desc_price span {
        margin-left: 11px;
    }

	.c3gp_gifts_cart_main_item_desc_price_with_discount .c3gp_gifts_cart_main_item_desc_old_price {
		font-size: 10px;
	}

	.c3gp_gifts_cart_main_item_desc_count_input {
        width: 48px;
        height: 27px;
        font-size: 14px;
        margin: 0 13px;
    }

    .c3gp_gifts_cart_main_item_desc_count_plus img,
    .c3gp_gifts_cart_main_item_desc_count_minus img {
        width: 11px;
    }

    .c3gp_gifts_cart_main_order_results_error {
        width: 100%;
    }

    .c3gp_gifts_cart_head_return_btn span {
        display: none;
    }

    .c3gp_gifts_cart_head_return_btn {
        width: 86px;
        display: none;
    }

    .c3gp_gifts_cart_head_empty {
        width: 86px;
        display: none;
    }

    .c3gp_gifts_cart_head_title {
        font-size: 22px;
    }

    .c3gp_gifts_cart_head {
        height: 59px;
        justify-content: center;
    }

    .c3gp_gifts_cart_main_order_addressee {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .c3gp_gifts_cart_main_order_addressee_item_info {
        display: block;
    }

    .c3gp_gifts_cart_main_order_addressee_item_photo {
        margin-right: 10px;
    }

    .c3gp_gifts_cart_main_order_addressee_item_place {
        margin-left: 0;
    }

    .c3gp_gifts_cart_main_order_addressee_item_id {
        padding: 0;
    }

    .c3gp_gifts_cart_main_order_addressee_item_name {
        padding: 0;
    }

    .c3gp_gifts_cart_main_order_addressee_item_remove {
        margin-left: 23px;
    }

    .c3gp_gifts_cart_main_order_results {
        display: block;
    }

    .c3gp_gifts_cart_main_order_results_price {
        text-align: center;
    }

    .c3gp_gifts_cart_main_order_messages_wrap {
        margin-bottom: 18px;
    }

    .c3gp_gifts_cart_main_order_addressee_item {
        margin-top: 16px;
    }

    .popup_gifts_cart_item {
        display: block;
    }

    .popup_gifts_cart_item_bottom {
        justify-content: space-between;
        margin-top: 7px;
    }

    .popup_gifts_cart_item_price span {
        display: inline;
        margin-left: 11px;
    }

    .popup_gifts_cart_item_price_with_discount {
        display: block;
        width: auto;
    }

    .popup_gifts_cart_continue_btn span {
        display: inline-block;
        font-size: 14px;
    }

    .popup_gifts_cart_continue_btn {
        width: 89px;
        font-size: 0;
    }

    .popup_gifts_cart_continue_btn .popup_gifts_cart_continue_btn_back {
        display: inline;
        font-size: 14px;
    }

    .popup_gifts_cart_go_to_cart_btn {
        width: 154px;
    }

    .popup_gifts_cart_go_to_cart_btn img {
        margin-right: 9px;
        width: 15px;
    }

    .popup_gifts_cart_title {
        font-size: 18px;
    }

    .popup_gifts_cart_close {
        top: 3px;
        right: 3px;
    }

    .popup_gifts_cart_close img {
        width: 14px;
    }

    .popup_gifts_cart_close svg {
        width: 20px;
        height: 20px;
    }

    .popup_gifts_cart_wrap {
        padding: 15px;
        height: 450px;
    }

    .popup_gift_details {
        display: block;
    }

    .popup_gift_details_gallery {
        width: 255px;
        margin: 0 auto 20px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
    }

    .popup_gift_details_gallery_list img {
        display: block;
        width: 43px;
        margin-right: 0;
    }

    .popup_gift_details_gallery_big {
        margin-bottom: 0;
        width: 200px;
        height: 200px;
        object-fit: contain;
        margin-right: 12px;
    }

    .popup_gift_details_gallery_list {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        justify-content: space-between;
    }

    .popup_gift_details_title {
        text-align: center;
        margin-bottom: 5px;
        width: 100%;
    }

    .popup_gift_details_desc {
        margin-bottom: 14px;
        width: 100%;
    }

    .popup_gift_details_price {
        font-size: 0;
    }

    .popup_gift_details_price span {
        margin-left: 5px;
        font-size: 12px;
    }

    .popup_gift_details_price_with_discount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 120px;
    }

    .popup_gift_details_price_with_discount span {
        margin-left: 0;
    }

    .popup_gift_details_price_with_discount .popup_gift_details_old_price {
        margin-top: 4px;
        margin-left: 0;
    }

    .popup_gift_details_price_with_discount .popup_gift_details_add_btn {
        margin-bottom: 0;
    }

    .popup_gift_details_info {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-align-items: center;
        align-items: center;
    }

    .popup_gift_details_add_btn {
        margin-top: 0;
        margin-bottom: 0;
    }

    .popup_gift_details_wrap {
        padding: 15px;
    }
}

@media screen and (max-width: 899px) and (max-height: 450px) {
    .c3gp_gifts_page_head {
        padding-left: 0;
        padding-top: 0;
    }

    .c3gp_gifts_page_head_category_item {
        margin-right: 7px;
    }

    .c3gp_gifts_page_head_title {
        display: none;
    }
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
    .c3gp_gifts_page_main_list {
        grid-template-columns: repeat(3, 1fr);
    }
}

.popup_not_enough_credits {
    position: relative;
    padding: 44px 0;
    background-color: $whiteColor;
    text-align: center;
    width: 417px;
    max-width: 100%;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.popup_not_enough_credits_title {
    font-weight: 600;
    font-size: 20px;
    color: $accentLeftColor;
    margin-bottom: 8px;
}

.popup_not_enough_credits_text {
    color: $defaultTextColorAlpha8;
    line-height: 21px;
    margin: 0 90px 19px;
}

.popup_not_enough_credits_btn {
    display: block;
    width: 219px;
    height: 42px;
    line-height: 42px;
    margin: 0 auto;
    background: linear-gradient(90.66deg, $accentLeftColor 0%, $accentRightColor 100%);
    border-radius: 4px;
    color: $whiteColor;
    font-weight: 600;
    cursor: pointer;
}

.popup_not_enough_credits_btn img {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 20px;
}

.popup_gift_bought {
    position: relative;
    padding: 56px 0;
    background-color: $whiteColor;
    text-align: center;
    width: 436px;
    max-width: 100%;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.popup_gift_bought_title {
    font-weight: 600;
    font-size: 30px;
    color: $accentLeftColor;
    margin-bottom: 21px;
}

.popup_gift_bought_title img {
    vertical-align: middle;
    margin-bottom: 6px;
    margin-right: 8px;
}

.popup_gift_bought_title svg{
	vertical-align: middle;
	width: 27px;
	height: 27px;
	fill: #01327a;
	margin-bottom: 4px;
	margin-right: 8px;
}

.popup_gift_bought_text {
    font-size: 18px;
    color: $defaultTextColorAlpha8;
}

.popup_gift_bought_text:after {
    content: '';
    display: block;
    width: 200px;
    height: 1px;
    background-color: $defaultTextColorAlpha1;
    margin: 20px auto 7px;
}

.popup_gift_bought_sub_text {
    color: $defaultTextColorAlpha6;
    line-height: 21px;
    margin: 0 65px;
}

.popup_gift_close {
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 5px;
    cursor: pointer;
}

.popup_gift_close svg {
	width: 24px;
	height: 24px;
	fill: #ccd2d9;
	stroke: #ccd2d9;
	stroke-width: 0.8;
}
