@import "@sitestyles";

/**************

other

**************/

.c4_minichat .c3_head_id {
  margin-right: 8px;
}

.c3lh_search_filters_list_mob_top {
  display: none;
}

.c3lh_search_filters_list_wrap2_title {
  display: none;
}

.c3lh_search_filters_list_wrap2_close {
  display: none;
}

/**************

other end

**************/

.column-2.hidden {
  display: none;
}

.column-3.likeher {
  background-color: $bleachColor;
  overflow-x: hidden;
}

.c3lh_your_likes_wrap {
  padding: 0 20px 0 41px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* column 3 top*/

.c3lh_top {
  height: 60px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.c3lh_top_return_btn {
  font-weight: 500;
  font-size: 14px;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: 5px;
  width: 170px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  background-color: $whiteColor;
}

.c3lh_top_title {
  font-weight: 600;
  font-size: 28px;
  color: $accentLeftColor;
}

.c3lh_top_empty {
  width: 170px;
}

/* column 3 your likes list*/

.c3lh_your_likes_list_wrap2 {
  padding-right: 23px;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  padding-left: 10px;
  padding-bottom: 40px;
}

/*.need_confirm_email .c3lh_your_likes_list_wrap2{
	height: calc(100vh - 160px);
	height: calc(calc(var(--vh, 1vh) * 100) - 160px);
}*/

.c3lh_your_likes_list_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3lh_your_likes_list_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.c3lh_your_likes_list_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /*grid-template-rows: repeat(auto-fill, 429px);*/
  grid-gap: 16px;
  justify-content: center;
  margin-bottom: 14px;
}

.c3lh_your_likes_list_wrap.filters {
  justify-content: center;
}

/* column 3 your likes item*/

.c3lh_your_likes_item {
  position: relative;
  height: 429px;
}

/* column 3 your likes item main*/

.c3lh_your_likes_item_main {
  width: 244px;
  height: 393px;
  padding: 3px 2px;
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  position: relative;
}

.c3lh_your_likes_item_big_photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  display: block;
  margin-bottom: 8px;
}

.c3lh_your_likes_item_profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 75px;
  background: linear-gradient(0deg, $whiteColor 0%, $transparentColor 100%);
  padding: 0 12px;
  padding-bottom: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.c3lh_your_likes_item_profile_img {
  margin-right: 7px;
}

.c3lh_your_likes_item_profile_img img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 100%;
  display: block;
}

.c3lh_your_likes_item_profile_right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c3lh_your_likes_item_profile_name {
  width: 100%;
  color: $defaultTextColor;
  font-size: 14px;
  font-weight: 600;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 2px;
}

.c3lh_your_likes_item_profile_online {
  color: $onlineColor;
  width: 100%;
  font-size: 12px;
}

.c3lh_your_likes_item_profile_offline {
  color: $arsenicColor;
  width: 100%;
  font-size: 12px;
}

.c3lh_your_likes_item_profile_online:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $onlineColor;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.c3lh_your_likes_item_profile_offline:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $arsenicColor;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.c3lh_your_likes_item_profile_id {
  font-size: 10px;
  color: $defaultTextColor;
  opacity: 0.5;
}

.c3lh_your_likes_item_profile_place {
  font-weight: 500;
  font-size: 10px;
  color: $oldGrayColor;
  margin-left: 13px;
}

.c3lh_your_likes_item_profile_place img {
  vertical-align: middle;
  margin-bottom: 1px;
  margin-right: 3px;
  width: 19px;
}

/* column 3 your likes item back*/

.c3lh_your_likes_item_back {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 272px;
  height: 417px;
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.c3lh_your_likes_item_back_top_item_wrap {
  width: 44px;
  height: 44px;
  position: relative;
}

.c3lh_your_likes_item_back_top_item {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  white-space: nowrap;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
}

.c3lh_your_likes_item_back_top_item img {
  display: block;
  margin: auto;
  width: 24px;
  vertical-align: middle;
}

.active_likehistory_hint span {
  z-index: 999;
  font-size: 13px;
  background: $whiteColor;
  line-height: 24px;
  margin: 0px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  opacity: 1;
}

.active_likehistory_hint:hover span {
  display: flex;
}

.c3lh_your_likes_item_back_top_item span {
  display: none;
  /*font-size: 18px;
*/
  /*color: $defaultTextColorAlpha8;
*/
  /*line-height: 1;
*/
}

.c3lh_your_likes_item_back_top_item:hover {
  background-color: $whiteColor;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

/* column 3 your likes item back bottom*/

.c3lh_your_likes_item_back_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding: 0 8px;
}

.c3lh_your_likes_item_back_b_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.c3lh_your_likes_item_back_b_item {
  width: 43px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 20px;
  background-size: 20px;
  cursor: pointer;
  position: relative;
}

.c3lh_your_likes_item_back_b_item.active {
  background-color: $whiteColor;
  box-shadow: 0px 5px 6px rgba(47, 43, 99, 0.16);
  border-radius: 0px 0px 8px 8px;
  position: relative;
  z-index: 1;
}

.c3lh_your_likes_item_back_b_item.item_profile {
  background-image: url(../../img/c3lh-item-profile.svg);
}

.c3lh_your_likes_item_back_b_item.item_profile:hover {
  background-image: url(../../img/c3lh-item-profile-h.svg);
}

.c3lh_your_likes_item_back_b_item.item_picture {
  background-image: url(../../img/c3lh-item-pic-a.svg);
}

.c3lh_your_likes_item_back_b_item.item_picture:hover {
  background-image: url(../../img/c3lh-item-pic-h.svg);
}

.c3lh_your_likes_item_back_b_item.item_video {
  background-image: url(../../img/c3lh-item-video.svg);
}

.c3lh_your_likes_item_back_b_item.item_video:hover {
  background-image: url(../../img/c3lh-item-video-h.svg);
}

.c3lh_your_likes_item_back_b_item.item_video.disabled_video {
  background-image: url(../../img/disabled-video.svg);
  /*pointer-events: none;
*/
  cursor: default;
}

.c3lh_your_likes_item_back_b_item.item_video.disabled_video:hover {
  background-image: url(../../img/disabled-video-h.svg);
}

.c3lh_your_likes_item_back_chat_btn {
  width: 86px;
  height: 26px;
  line-height: 26px;
  background: linear-gradient(90.36deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  text-align: center;
  -ms-align-self: center;
  align-self: center;
  cursor: pointer;
}

.c3lh_your_likes_item_back_chat_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.c3lh_your_likes_item_back_chat_btn img {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 9px;
}

/* column 3 your likes item big*/

.c3lh_your_likes_item_big {
  /*display: none;
 */
  grid-column-start: span 2;
  grid-row-start: span 2;
  position: relative;
  max-height: 972px;
}

/* column 3 your likes item main*/

.c3lh_your_likes_item_big_main {
  width: calc(100% - 95px);
  height: calc(100% - 62px);
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  position: relative;
}

.c3lh_your_likes_item_big_main.pictures {
  display: block;
}

.c3lh_your_likes_item_big_big_photo {
  width: 100%;
  height: calc(100% - 72px);
  position: relative;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  padding: 38px 46px 0;
}

.c3lh_your_likes_item_big_big_photo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.c3lh_your_likes_item_big_big_photo_close {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 10px;
  cursor: pointer;
}

.c3lh_your_likes_item_big_big_photo_close img {
  width: 20px;
  height: 20px;
}

.c3lh_your_likes_item_big_big_photo_next {
  position: absolute;
  right: 0;
  top: 50%;
  width: 70px;
  height: 70px;
  transform: translateY(-50%);
  background: $background5Color;
  border-radius: 40px 0px 0px 40px;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
}

.c3lh_your_likes_item_big_big_photo_next img {
  vertical-align: middle;
  width: 19px;
}

.c3lh_your_likes_item_big_profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  padding-left: 46px;
  padding-top: 10px;
  padding-bottom: 12px;
}

.c3lh_your_likes_item_big_profile.active {
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

.c3lh_your_likes_item_big_profile_img {
  margin-right: 7px;
}

.c3lh_your_likes_item_big_profile_img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
  display: block;
}

.c3lh_your_likes_item_big_profile_right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c3lh_your_likes_item_big_profile_name {
  width: 100%;
  color: $defaultTextColor;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.c3lh_your_likes_item_big_profile_id {
  font-size: 10px;
  color: $defaultTextColor;
  opacity: 0.5;
}

.c3lh_your_likes_item_big_profile_place {
  font-weight: 500;
  font-size: 10px;
  color: $oldGrayColor;
  margin-left: 13px;
}

.c3lh_your_likes_item_big_profile_place img {
  vertical-align: middle;
  margin-bottom: 1px;
  margin-right: 3px;
}

/* column 3 your likes item back*/

.c3lh_your_likes_item_big_back {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 18px);
  height: calc(100% - 28px);
  background-color: $whiteColor;
  box-shadow: 3px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.c3lh_your_likes_item_big_back_top {
  width: 95px;
}

.c3lh_your_likes_item_big_back_top_item_wrap {
  width: 64px;
  height: 60px;
  position: relative;
  margin: auto;
}

.c3lh_your_likes_item_big_back_top_item {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  white-space: nowrap;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 12px 10px 12px;
  border-radius: 5px;
  z-index: 2;
}

.c3lh_your_likes_like_ico {
  width: 40px;
  height: 40px;
  background-image: url(../../img/c3lh-item-like.svg);
}

.c3lh_your_likes_item_big_back_top_item:hover .c3lh_your_likes_like_ico {
  margin-right: 12px;
}

.c3lh_your_likes_item_big_back_top_item.active .c3lh_your_likes_like_ico {
  background-image: url(../../img/c3lh-item-like-active.svg);
}

.c3lh_your_likes_item_big_back_top_item img {
  display: block;
  margin: auto;
  width: 40px;
  vertical-align: middle;
}

#c3lh_like_ico_heart:hover {
  fill: red;
  stroke: red;
  stroke-width: 5px;
}

.c3lh_your_likes_item_big_back_top_item span {
  display: none;
  font-size: 18px;
  color: $defaultTextColorAlpha8;
  line-height: 1;
}

.c3lh_your_likes_item_big_back_top_item:hover {
  background-color: $whiteColor;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3lh_your_likes_item_big_back_top_item:hover img {
  margin-right: 12px;
}

.c3lh_your_likes_item_big_back_top_item:hover span {
  display: block;
}

/* column 3 your likes item back bottom*/

.c3lh_your_likes_item_big_back_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  padding: 0 8px;
}

.c3lh_your_likes_item_big_back_b_left {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.c3lh_your_likes_item_big_back_b_item {
  width: 58px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 28px;
  background-size: 28px;
  cursor: pointer;
}

.c3lh_your_likes_item_big_back_b_item.active {
  background-color: $whiteColor;
  box-shadow: 0px 5px 6px rgba(47, 43, 99, 0.16);
  border-radius: 0px 0px 8px 8px;
  position: relative;
  z-index: 1;
}

.c3lh_your_likes_item_big_back_b_item.item_profile {
  background-image: url(../../img/c3lh-item-profile.svg);
}

.c3lh_your_likes_item_big_back_b_item.item_picture {
  background-image: url(../../img/c3lh-item-pic.svg);
}

.c3lh_your_likes_item_big_back_b_item.item_video {
  background-image: url(../../img/c3lh-item-video.svg);
}

.c3lh_your_likes_item_big_back_b_item.active.item_profile {
  background-image: url(../../img/c3lh-item-profile-a.svg);
}

.c3lh_your_likes_item_big_back_b_item.active.item_picture {
  background-image: url(../../img/c3lh-item-pic-a.svg);
}

.c3lh_your_likes_item_big_back_b_item.active.item_video {
  background-image: url(../../img/c3lh-item-video-a.svg);
}

.c3lh_your_likes_item_big_back_chat_btn {
  width: 178px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(90.36deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: $whiteColor;
  text-align: center;
  -ms-align-self: center;
  align-self: center;
  cursor: pointer;
}

.c3lh_your_likes_item_big_back_chat_btn img {
  width: 19px;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 9px;
}

/* column 3 your likes item big profile*/

.c3lh_your_likes_item_big_main.profile {
  padding-right: 11px;
  padding-bottom: 22px;
}

.c3lh_your_likes_item_big_main_profile_wrap {
  padding-left: 22px;
  padding-top: 24px;
  height: 100%;
}

.c3lh_your_likes_profile_head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 29px;
}

.c3lh_your_likes_profile_photo {
  margin-right: 14px;
}

.c3lh_your_likes_profile_photo img {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  object-fit: cover;
}

.c3lh_your_likes_profile_head_id {
  font-size: 12px;
  color: $arsenicColor;
}

.c3lh_your_likes_profile_head_name {
  font-weight: bold;
  font-size: 18px;
  color: $primaryColor;
  margin: 4px 0 6px;
}

.c3lh_your_likes_profile_head_place {
  font-weight: 500;
  font-size: 12px;
  color: $arsenicColor;
}

.c3lh_your_likes_profile_head_place img {
  margin-right: 7px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.c3lh_your_likes_profile_content {
  max-height: 665px;
  overflow-y: auto;
  padding-right: 20px;
}

.c3lh_your_likes_profile_content::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3lh_your_likes_profile_content::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 7px;
}

.c3lh_your_likes_profile_content_title {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-bottom: 16px;
}

.c3lh_your_likes_profile_content_block {
  margin-bottom: 30px;
}

.c3lh_your_likes_profile_content_block ul li {
  list-style: none;
  display: block;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.06em;
  color: $stormGreyColor;
  margin-bottom: 12px;
}

.c3lh_your_likes_profile_content_block ul li span {
  font-weight: bold;
}

.c3lh_your_likes_profile_content_block ul li span.ns {
  color: $counterAndErrorColor;
}

.c3lh_your_likes_profile_content_hobbies:after {
  display: block;
  content: '';
  height: 0;
  clear: both;
}

.c3lh_your_likes_profile_content_hobby_item {
  height: 29px;
  line-height: 29px;
  background-color: $background5Color;
  border-radius: 5px;
  padding: 0 15px;
  margin-bottom: 13px;
  float: left;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha9;
}

.c3lh_your_likes_profile_content_block p {
  font-size: 16px;
  line-height: 150%;
  color: $stormGreyColor;
  margin-bottom: 10px;
}

/* column 3 your likes item big video*/

.c3lh_your_likes_item_big_main.video {
  padding: 28px 6px 5px 16px;
}

.c3lh_your_likes_video_title {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primaryColor;
}

.c3lh_your_likes_video_list_wrap {
  margin-top: 20px;
  padding-right: 7px;
  height: calc(100% - 119px);
  max-height: 720px;
  overflow-y: auto;
}

.c3lh_your_likes_item_big_main.video .c3lh_your_likes_item_big_profile {
  padding-left: 0;
}

.c3lh_your_likes_video_list_wrap::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3lh_your_likes_video_list_wrap::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 7px;
}

.c3lh_your_likes_video_item {
  width: 100%;
  height: 272px;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
}

.c3lh_your_likes_video_item:after {
  content: '';
  display: none;
  width: 98px;
  height: 98px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url(../../img/c3lh-item-big-video-play.svg);
  -webkit-background-size: contain;
  background-size: contain;
}

.c3lh_your_likes_video_item_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.c3lh_your_likes_item_big_close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  padding: 5px;
}

.c3lh_your_likes_item_big_close img {
  display: block;
}

/* column 3 your likes item end*/
/* popup send gift*/

.popup_likeher_sendgift {
  /*display: none;
*/
  width: 1036px;
  height: 914px;
  background-color: $whiteColor;
  border-radius: 10px;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 32px 16px 16px 35px;
}

.popup_likeher_sendgift_close {
  position: absolute;
  right: 25px;
  top: 25px;
  padding: 5px;
  cursor: pointer;
}

.popup_likeher_sendgift_close img {
  display: block;
}

.popup_likeher_sendgift_head {
  font-weight: 600;
  font-size: 30px;
  color: $primaryColor;
  margin-bottom: 18px;
}

.popup_likeher_sendgift_list {
  height: calc(100% - 120px);
  padding-right: 10px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 306px);
  grid-gap: 24px;
}

.popup_likeher_sendgift_list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.popup_likeher_sendgift_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.popup_likeher_sendgift_item {
  background-color: $oldGrayColor;
  width: 306px;
  height: 388px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: $background1Color;
  padding-bottom: 15px;
  border: 6px solid $background1Color;
}

.popup_likeher_sendgift_item_photo {
  width: 100%;
  height: 306px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.popup_likeher_sendgift_item_name {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: $accentLeftColor;
  margin: 13px 0 8px 19px;
  cursor: pointer;
}

.popup_likeher_sendgift_item_creds {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $accentRightColor;
  margin-left: 19px;
}

.popup_likeher_sendgift_shopping_card_btn {
  width: 191px;
  height: 52px;
  border: 1px solid $primaryColor;
  background-color: $whiteColor;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $primaryColor;
  margin-top: 12px;
  margin-left: auto;
  cursor: pointer;
}

.popup_likeher_sendgift_shopping_card_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
}

/* popup send gift full item*/

.popup_likeher_sendgift_item_full {
  /*display: none;
 */
  justify-content: space-between;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: $background1Color;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  text-align: left;
}

.popup_likeher_sendgift_item_full.active {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.popup_likeher_sendgift_item_full_text_row {
  width: calc(100% - 48px);
  margin: 30px auto 0;
}

.popup_likeher_sendgift_item_full_text_row_title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.popup_likeher_sendgift_item_full_text_row_title {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: $primaryColor;
}

.popup_likeher_sendgift_item_full_text_row_title_symbols {
  font-size: 16px;
  color: $defaultTextColorAlpha6;
}

.popup_likeher_sendgift_item_full_text_row_textarea {
  width: 100%;
  resize: none;
  height: 120px;
  margin-top: 10px;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
}

.popup_likeher_sendgift_item_full_text_row_textarea + .popup_likeher_sendgift_item_full_text_row_title_symbols {
  margin-top: 8px;
}

.popup_likeher_sendgift_item_full_close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  cursor: pointer;
}

.popup_likeher_sendgift_item_full_close img {
  width: 15px;
}

.popup_likeher_sendgift_item_full_left {
  margin-right: 25px;
}

.popup_likeher_sendgift_item_full_big_photo {
  width: 306px;
  height: 306px;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}

.popup_likeher_sendgift_item_full_photos_list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
}

.popup_likeher_sendgift_item_full_photos_list img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
}

.popup_likeher_sendgift_item_full_right {
  width: calc(100% - 331px);
  padding-top: 17px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
}

.popup_likeher_sendgift_item_full_name {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.01em;
  color: $accentLeftColor;
  margin-bottom: 18px;
}

.popup_likeher_sendgift_item_full_specs {
  margin-bottom: 18px;
}

.popup_likeher_sendgift_item_full_specs li {
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  color: $stormGreyColor;
  line-height: 24px;
}

.popup_likeher_sendgift_item_full_specs li span {
  font-weight: bold;
  color: $accentLeftColor;
}

.popup_likeher_sendgift_item_full_desc li {
  list-style: none;
  font-size: 16px;
  color: $stormGreyColor;
  line-height: 24px;
  padding-right: 10px;
}

.popup_likeher_sendgift_item_full_desc li:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: $stormGreyColor;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 6px;
}

.popup_likeher_sendgift_item_full_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
  height: 60px;
  padding-right: 25px;
  padding-bottom: 12px;
}

.popup_likeher_sendgift_item_full_creds {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: $accentRightColor;
  margin-top: 25px;
}

.popup_likeher_sendgift_item_full_creds span {
  color: $defaultTextColor;
  font-weight: 500;
  margin-right: 30px;
}

.popup_likeher_sendgift_item_full_add_btn {
  display: block;
  width: 163px;
  height: 40px;
  background: linear-gradient(90.51deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 5px;
  font-weight: 600;
  color: $whiteColor;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin: 19px auto 0;
}

.popup_likeher_sendgift_item_full_add_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px;
}

.popup_likeher_sendgift_item_full_added_btn {
  display: none;
  width: 163px;
  height: 40px;
  background-color: $whiteColor;
  border-radius: 5px;
  font-weight: 600;
  color: $accentLeftColor;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin-left: 18px;
}

.popup_likeher_sendgift_item_full_added_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px;
}

/* column 3 chat window */

.c3lh_your_likes_item_big_chat_window {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 440px;
  background-color: $whiteColor;
  z-index: 999;
  border-radius: 10px;
}

.c3lh_your_likes_item_profile_chat_window {
  display: none;
  position: fixed;
  background-color: $whiteColor;
  z-index: 999;
  border-radius: 5px 0 0 5px;
}

.c3lh_your_likes_item_profile_chat_window:after {
  content: '';
  width: 10px;
  height: calc(100% + 20px);
  position: absolute;
  right: 0;
  top: -10px;
  background-image: url(../../img/c3lh-window-profile-bg.svg);
}

.c3lh_your_likes_item_profile_chat_window .c3lh_your_likes_item_big_profile {
  padding-left: 11px;
}

.c3lh_ylibcw .c3_head {
  padding: 12px 32px;
  border-radius: 10px 10px 0 0;
}

.c3lh_ylibcw.c4_minichat .c3_write_msg {
  border-radius: 0 0 10px 10px;
}

.start_chat_text {
  text-align: center;
  font-size: 18px;
  color: $primaryColor;
  position: absolute;
  left: 0;
  bottom: 257px;
  width: 100%;
}

.c3lh_your_likes_item_big_mail_window {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 440px;
  background-color: $whiteColor;
  z-index: 999;
  border-radius: 10px;
}

.c3lh_ylibmw .c3_textaera_wrap {
  height: 390px;
}

.c3lh_ylibmw .c3_head {
  padding: 12px 32px;
  border-radius: 10px 10px 0 0;
}

.c3lh_ylibmw.c4_minichat .c3_write_msg {
  border-radius: 0 0 10px 10px;
}

.c3lh_ylibmw .start_chat_text {
  bottom: 535px;
}

/* likeher search popup photo */

.popup_likeher_search_photos {
  /*width: 100%;*/
  /*height: 100%;*/
  /*!*height: calc(var(--vh, 1vh) * 100);*!*/
  /*position: fixed;*/
  /*left: 0;*/
  /*top: 0;*/
  /*background: rgba(55, 78, 108, 0.32);*/
  height: 100%;
  z-index: 999;
  @media screen and (max-width: 599px) {
    display: flex;
    align-items: center;
  }
}

.popup_likeher_search_photos_content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 599px) {
    height: 70vh;
    margin: auto;
    max-height: 70vh;
    border-radius: 10px;
    overflow: hidden;
    //width: calc(100% - 16px);
  }
}

.content_new_gallery {
  @media screen and (max-width: 599px) {
    height: 85vh;
    max-height: 85vh;
  }
}

.popup_likeher_search_photos_big_photo {
  position: relative;
}

.popup_likeher_search_photos_big_photo > img {
  max-width: calc(100vw - 220px);
  max-height: calc(100vh - 30px);
  max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
  border-radius: 10px;
  display: block;
}

.popup_likeher_search_photos_big_photo.unpaid {
  background-color: $oldGrayColor;
  border-radius: 10px;
}

.popup_likeher_photo_unpaid_warning {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 60px 30px;
  background: linear-gradient(180deg, $whiteColor 0%, $transparentColor 100%);
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColor;
  text-align: center;
  z-index: 1;
  line-height: 1.5;
}

.popup_likeher_photo_unpaid_warning.like_her {
  display: block;
  z-index: 5;
}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning {
  display: flex;
  align-items: center;
}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_photo_unpaid_warning_text {
  text-align: start;
}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn {
  margin: 0 0 0 30px;
  position: relative;
  /*right: -48px;
*/
  width: 114px;
  height: 38px;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn {
    padding: 10px;
  }
  .popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn > img {
    width: 20px;
  }
}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn > span {
  color: white;
  margin-top: 7px;
  font-size: 14px;
  line-height: 140%;
  font-weight: 500;

}

.popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn > img {
  filter: none;
}

.popup_likeher_photo_unpaid_warning span {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: $accentRightColor;
  margin-bottom: 6px;
  line-height: 1.3;
}

.popup_likeher_search_photos_list_wrap {
  height: 100%;
  padding: 20px 10px 20px 0;
  padding-right: 10px;
  background: $fadeColor;
  margin-left: 22px;
}

.popup_likeher_search_photos_list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 14px 0 24px;
}

.popup_likeher_search_photos_list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColorAlpha5;
  border-radius: 5px;
}

.popup_likeher_search_photos_list::-webkit-scrollbar-thumb {
  background: $accentLeftColor;
  border-radius: 7px;
}

.popup_likeher_search_photos_list_item {
  margin-bottom: 16px;
}

.popup_likeher_search_photos_list_item img {
  width: 110px;
  height: auto;
  object-fit: cover;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.popup_likeher_search_photos_list_item.unpaid {
  background-color: $oldGrayColor;
  border-radius: 10px;
}

.popup_likeher_search_photos_list_item.unpaid img {
  filter: blur(25px);
}

.popup_likeher_search_photos_list_item.w_text {
  width: 110px;
  background-image: url(../../img/vert-slider-bg.svg);
  background-size: 100% 100%;
  padding: 13px 4px 10px;
}

.popup_likeher_search_photos_list_item_title {
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: $defaultTextColor;
}

.popup_likeher_search_photos_unpaid_warning {
  font-weight: 500;
  text-align: center;
  color: $defaultTextColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 60px 30px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  z-index: 1;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;

  @media screen and (max-width: 599px) {
    padding: 20px 10px 20px;
    font-size: 12px;
  }

  span {
    display: block;
    font-weight: 600;
    font-size: 20px;
    color: $accentRightColor;
    margin-bottom: 6px;
    line-height: 1.3;

    @media screen and (max-width: 599px) {
      font-size: 14px;
    }
  }
}

.popup_likeher_search_photos_list_item_text {
  font-weight: 600;
  text-align: center;
  color: $accentLeftColor;
}

.popup_likeher_search_photos_list_item_chat_btn {
  width: 90px;
  height: 38px;
  line-height: 38px;
  background: $backgroundButtonColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 6px;
  font-weight: 600;
  color: $whiteColor;
  /*display: flex;*/
  display: none;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
  cursor: pointer;
}

.popup_likeher_search_photos_list_item.w_text .popup_likeher_search_photos_list_item_chat_btn {
  display: flex;
}

.popup_likeher_search_photos_list_item_chat_btn img {
  width: 19px;
  margin-right: 6px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.popup_likeher_search_photos_list_item_chat_btn svg {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
  margin-right: 6px;
}

.popup_likeher_search_photos_list_item:last-child {
  margin-bottom: 0;
}

.popup_likeher_search_photos_prev {
  position: absolute;
  left: 0;
  top: 50%;
  background: $background5Color;
  border-radius: 0 40px 40px 0;
  width: 77px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  cursor: pointer;
}

.popup_likeher_search_photos_next {
  position: absolute;
  right: 0;
  top: 50%;
  background: $background5Color;
  border-radius: 40px 0px 0px 40px;
  width: 77px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  cursor: pointer;
}

.popup_likeher_search_photos_next img {
  line-height: 1;
  vertical-align: middle;
  width: 22px;
}

.popup_likeher_search_photos_prev img {
  line-height: 1;
  vertical-align: middle;
  /*transform: rotate(180deg);
*/
  width: 22px;
}

.popup_likeher_search_photos_close {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px;
  cursor: pointer;
  z-index: 2;
}

.popup_likeher_search_photos_close img {
  display: block;
}

.popup_likeher_search_photos_close svg {
  width: 55px;
  height: 55px;
  fill: #FFFFFF;
}

/* likeher search popup video */

.popup_likeher_search_video {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: $fadeColor;
  z-index: 999;
}

.popup_likeher_search_video video {
  width: calc(100vw - 200px);
  height: calc(100vh - 100px);
  height: calc(calc(var(--vh, 1vh) * 100) - 100px);
}

.popup_likeher_search_video_item {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.popup_likeher_search_video_item.playing:after {
  display: none;
}

.popup_likeher_search_video_item:after {
  content: '';
  display: block;
  width: 149px;
  height: 149px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url(../../img/play-video.svg);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

/* search head */

.c3lh_search_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  position: relative;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 14px;
  z-index: 15;
}

.c3lh_search_range_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.c3lh_search_range_title {
  font-weight: 600;
  font-size: 14px;
  color: $searchFilterTitle;
}

.c3lh_search_range_btn_confirm {
  cursor: pointer;
}

.c3lh_search_range_btn_confirm img {
  display: block;
}

/* main custom select styles*/


/* search head selects */

.c3lh_search_range_selects_wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin: 0 6px 0 21px;
}

.c3lh_search_range_selects_wrap .choices:first-child {
  margin-right: 10px;
}

.c3lh_search_range_selects_wrap .choices__placeholder {
  width: 82px;
  height: 30px;
  background-color: $whiteColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: $accentLeftColor;
  padding: 8px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3lh_search_range_selects_wrap .choices__list--single .choices__item {
  width: 82px;
  height: 30px;
  background-color: $whiteColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: $accentLeftColor;
  padding: 8px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3lh_search_range_selects_wrap .choices__list--dropdown .choices__item {
  width: 82px;
  height: 30px;
  background-color: $whiteColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: $accentLeftColor;
  padding: 8px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3lh_search_range_selects_wrap .choices__item.choices__placeholder {
  color: $defaultTextColorAlpha5;
}

.c3lh_search_range_selects_wrap .choices__list--dropdown {
  border-radius: 0 0 5px 5px;
}

.c3lh_search_range_selects_wrap .c3lh_search_range_selects_wrap .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
}

.c3lh_search_range_selects_wrap .choices__item--disabled {
  display: none;
}

/* custom select end */
/* head filters button wrap*/

.c3lh_search_filters_btn_wrap {
  width: 230px;
  height: 37px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  line-height: 37px;
  font-weight: 500;
  font-size: 14px;
  color: $primaryColor;
  padding: 0 12px;
  position: relative;
  cursor: pointer;
}

.c3lh_search_filters_btn_wrap b {
  display: inline-block;
  height: 19px;
  line-height: 19px;
  padding: 0 7px;
  background-color: $primaryColor;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  vertical-align: middle;
  font-size: 12px;
  color: $whiteColor;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.c3lh_search_filters_btn_wrap.active {
  -ms-align-self: flex-end;
  align-self: flex-end;
  border-color: $whiteColor;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px 5px 0 0;
  height: 50px;
  line-height: 50px;
}

.c3lh_search_filters_btn_wrap.active:before {
  //content: '';
  //height: 10px;
  //width: calc(100% + 20px);
  //position: absolute;
  //left: -10px;
  //bottom: -1px;
  /* background-image: url(../../img/c3s-filter-btn-bg.svg);
 */
}

.c3lh_search_filters_btn_wrap:after {
  content: '';
  display: block;
  width: 9px;
  height: 5px;
  position: absolute;
  right: 14px;
  top: calc(50% - 2.5px);
  background-image: url(../../img/c3s-filters-btn-arr.svg);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.c3lh_search_filters_btn_wrap.active:after {
  transform: rotate(180deg);
}

.c3lh_search_filters_btn_wrap svg {
  vertical-align: middle;
  margin-right: 7px;
  width: 22px;
  height: 21px;
  fill: $iconFilterFillColor;
}

/* head search id */

.c3lh_search_id_wrap {
  position: relative;
}

.c3lh_search_id_wrap input {
  display: block;
  width: 174px;
  height: 30px;
  /*box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);*/
  border-radius: 5px;
  border: 1px solid $selectBorderColor !important;
  background-color: $whiteColor !important;
  outline: none;
  padding: 0 30px 0 12px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: $primaryColor;
  font-weight: 600;
}

.c3lh_search_id_wrap input::placeholder {
  font-weight: 500;
  color: $searchPlaceholder;
}

.c3lh_search_id_wrap input:hover {
  border: 1px solid $selectBorderHoverColor;
  box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.c3lh_search_id_wrap input:hover::placeholder {
  color: $searchPlaceholder;
}

.c3lh_search_id_wrap input:focus {
  border: 1px solid #00317B;
  box-shadow: 0px 3px 6px rgba(28, 38, 119, 0.08);
}

.c3lh_search_id_wrap button {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;
}

.c3lh_search_close {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #ccd1d9;
    width: 14px;
    height: 14px;
  }
}

.c3lh_search_search {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: $iconCrossSearchColor;
    width: 16px;
    height: 16px;
  }
}

.c3lh_search_id_wrap button img {
  display: block;
  margin: auto;
}

/* search filter list */

.c3lh_search_filters_list_wrap {
  /*display: none;*/
  background-color: $whiteColor;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 24px;
  //padding-right: 12px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 2;
}

.c3lh_search_filters_list_wrap2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  //max-height: calc(100vh - 200px);
  //max-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
  //overflow-y: scroll;
  //padding-right: 12px;
  margin-bottom: 16px;
}

.need_confirm_email .c3lh_search_filters_list_wrap2 {
  //max-height: calc(100vh - 280px);
  //max-height: calc(calc(var(--vh, 1vh) * 100) - 280px);
}


.c3lh_search_filters_list_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 3px;
}

.c3lh_search_filters_list_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 3px;
}

.c3lh_search_filter_item {
  /*width: 390px;
*/
  //margin-bottom: 24px;
  position: relative;

  &.city_item.disabled .like_select_head {
    border: 1px solid #CDD3DA;
    background-color: #eee;
    pointer-events: none;

    &::placeholder {
      color: #b9bbbf;
    }
  }
}

.c3lh_search_filter_item .fill_data_form_select_item_wrap {
  width: auto;
  margin-bottom: 0;
}

.c3lh_search_filter_item_two {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.c3lh_search_filter_item > label {
  display: block;
  width: 100%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 5px;
}

.c3lh_search_filter_item_online_status_head {
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
}

.c3lh_search_filter_item_online_status_inp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.c3lh_search_filter_item_online_status_inp_text {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: $primaryColor;
  margin-right: 11px;
}

.c3lh_search_filter_item_online_status_inp input {
  display: none;
}

.c3lh_search_filter_item_online_status_inp label {
  display: block;
  width: 36px;
  height: 18px;
  background-color: $liteGreyColor;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  position: relative;
  cursor: pointer;
  transition: .3s;
}

.c3lh_search_filter_item_online_status_inp label:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: $whiteColor;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: .3s;
}

.c3lh_search_filter_item_online_status_inp input:checked + label {
  background-color: $whiteColor;
  border: 1px solid $searchBoxShadowBorder;
  box-shadow: $searchBoxShadow;
}

.c3lh_search_filter_item_online_status_inp input:checked + label:before {
  background-color: $onlineColor;
  top: 1px;
  left: 19px;
}

.c3lh_search_filters_list_btn_find {
  width: 201px;
  height: 40px;
  background: $backgroundButtonColor;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.06em;
  color: $whiteColor;
  font-weight: 600;
  line-height: 40px;
  margin: auto;
  cursor: pointer;
  grid-column: span 2;
}

.c3lh_search_filters_list_btn_find:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.c3lh_search_filters_list_btn_find img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 12px;
}

.c3lh_search_filters_list_btn_find svg {
  width: 22px;
  height: 22px;
  fill: #FFFFFF;
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 12px;
}

.c3lh_search_filters_list_result_val {
  font-size: 12px;
  color: $oldGrayColor;
  text-align: center;
  margin-top: 14px;
}

.c3lh_search_filter_item_hobbies_title {
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 44px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.c3lh_search_filter_item_hobbies_title.active {
  color: $accentLeftColor;
  font-weight: 500;
  background-color: $background5Color;
}

.c3lh_search_filter_item_hobbies_title:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  position: absolute;
  right: 14px;
  top: calc(50%);
  background-image: url(/img/create-profile-page/selecet-arrow.svg);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  transition: .3s;
}

.c3lh_search_filter_item_hobbies_title.opened:after {
  transform: rotate(180deg);
}

.c3lh_search_filter_item_hobbies_list_wrap {
  /* display: none;
 */
  position: absolute;
  left: 0;
  top: 72px;
  border-radius: 5px;
  background-color: $bleachColor;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  z-index: 5;
  padding-bottom: 17px;
}

.c3lh_search_filter_item_hobbies_list_head {
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 44px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $accentLeftColor;
  padding: 0px 12px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.c3lh_search_filter_item_hobbies_list_head:after {
  content: '';
  display: block;
  width: 9px;
  height: 5px;
  position: absolute;
  right: 16px;
  top: calc(50%);
  /* background-image: url(../img/c3s-filters-btn-arr.svg);
 */
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: .56;
  z-index: 1;
  transform: rotate(180deg);
}

.c3lh_search_filter_item_hobbies_list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0 0 12px;
}

.c3lh_search_filter_item_hobbies_list_item {
  font-weight: 500;
  padding: 7px 15px 5px;
  color: $defaultTextColorAlpha9;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.c3lh_search_filter_item_hobbies_list_item.selected {
  background-color: $background5Color;
}

.c3lh_search_filter_item_hobbies_confirm_btn {
  width: 132px;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 12px;
  color: $primaryColor;
  text-align: center;
  border: 1px solid $primaryColor;
  border-radius: 5px;
  margin: 16px auto 0;
  background-color: $whiteColor;
  cursor: pointer;
}

.c3lh_search_filter_item_hobbies_confirm_btn img {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 6px;
}

.c3lh_search_filter_item_hobbies_choosen {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}

.c3lh_search_filter_item_hobbies_choosen_item {
  position: relative;
  font-weight: 500;
  padding: 7px 36px 5px 15px;
  color: $defaultTextColorAlpha9;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: $background5Color;
}

.c3lh_search_filter_item_hobbies_choosen_item:after {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../../img/c3lh-item-big-close-blue.svg);
  -webkit-background-size: contain;
  background-size: contain;
}

/* search filter select custom */

.c3lh_search_filter_item_two .choices {
  width: 48%;
}

.c3lh_search_filter_item .choices__list--single .choices__item {
  width: 100%;
  height: 42px;
  line-height: 44px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.c3lh_search_filter_item .choices__list--dropdown .choices__item {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: $bleachColor;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha8;
  padding: 0 12px;
  outline: none;
  border-radius: 4px;
  margin-bottom: 2px;
}

.c3lh_search_filter_item .choices__list--dropdown {
  background-color: $bleachColor;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 10px 16px;
  max-height: 360px;
}

.c3lh_search_filter_item .choices__list--dropdown .is-highlighted {
  background-color: $background5Color;
}

.c3lh_search_filter_item .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
  padding-left: 22px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 3;
}

.c3lh_search_filter_item .is-open .choices__list--dropdown {
  z-index: 2;
}

.c3lh_search_filter_item .choices__list--single.has-selected .choices__item {
  color: $accentLeftColor;
}

.c3lh_search_filter_item .choices__item--disabled {
  display: none;
}

.c3lh_search_filter_item .choices__list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c3lh_search_filter_item .choices__list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

/* girls list bottom buttons */

.c3lh_girls_list_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 0 22px;
}

.c3lh_girls_list_bottom_btn {
  width: 190px;
  height: 40px;
  line-height: 40px;
  background: $whiteColor;
  border: 1px solid $primaryColor;
  text-align: center;
  border-radius: 5px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 7px;
}

.c3lh_girls_list_bottom_btn:hover {
  background: linear-gradient(90.6deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-color: transparent;
  color: $whiteColor;
}

.c3lh_girls_list_bottom_btn svg {
  fill: $primaryColor;
  margin-right: 10px;
}

.c3lh_girls_list_bottom_btn.c3lh_glbb_chat svg {
  width: 21px;
  height: 21px;
  stroke-width: 0;
}

.c3lh_girls_list_bottom_btn.c3lh_glbb_videos svg {
  width: 21px;
  height: 14px;
  stroke: $primaryColor;
  stroke-width: 0.8px;
}

.c3lh_girls_list_bottom_btn.c3lh_glbb_gifts svg {
  width: 16px;
  height: 20px;
  stroke-width: 0;
}

.c3lh_girls_list_bottom_btn.c3lh_glbb_letter svg {
  width: 20px;
  height: 16px;
  stroke: $whiteColor;
  stroke-width: 0.2px;
}

.c3lh_girls_list_bottom_btn:hover svg {
  fill: $whiteColor;
  stroke: $whiteColor;
}

/* girls list main */

.c3lh_girls_list_main {
  height: calc(100vh - 86px);
  height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  padding: 0 69px;
}

/* girls list control */

.c3lh_girls_list_controls {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: flex-end;
  align-items: flex-end;
  margin-top: -42px;
  position: relative;
  z-index: 3;
}

.c3lh_girls_list_controls_next_gril {
  width: 121px;
  height: 121px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background-color: $whiteColor;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $primaryColor;
  cursor: pointer;
}

.c3lh_girls_list_controls_next_gril img {
  margin-bottom: 20px;
  margin-top: 20px;
}

.c3lh_girls_list_controls_like_her {
  width: 121px;
  height: 121px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background-color: $whiteColor;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $accentRightColor;
  cursor: pointer;
}

.c3lh_girls_list_controls_girl_info {
  display: grid;
  grid-template-areas: "c3lh_id c3lh_name c3lh_place"
	"c3lh_id c3lh_status c3lh_place";
  grid-template-columns: repeat(3, 1fr);
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  width: 596px;
  background-color: $whiteColor;
  padding: 0 22px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  height: 79px;
}

.c3lh_girls_list_controls_like_her img {
  margin-bottom: 12px;
  margin-top: 12px;
}

.c3lh_girls_list_controls_girl_info_id {
  font-weight: 500;
  font-size: 1.2vh;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $primaryColor;
  grid-area: c3lh_id;
}

.c3lh_girls_list_controls_girl_info_place {
  font-weight: 500;
  font-size: 1.2vh;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  color: $primaryColor;
  grid-area: c3lh_place;
  text-align: right;
}

.c3lh_girls_list_controls_girl_info_place img {
  width: 32px;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 5px;
  line-height: 1;
}

.c3lh_girls_list_controls_girl_info_middle {
  text-align: center;
}

.c3lh_girls_list_controls_girl_info_name {
  font-weight: 600;
  font-size: 2vh;
  color: $stormGreyColor;
  grid-area: c3lh_name;
  text-align: center;
  margin-bottom: -9px;
}

.c3lh_girls_list_controls_girl_info_online_status {
  font-weight: 600;
  font-size: 1.7vh;
  grid-area: c3lh_status;
  text-align: center;
  margin-top: -9px;
}

.c3lh_girls_list_controls_girl_info_online_status:before {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: $oldGrayColor;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.c3lh_girls_list_controls_girl_info_online_status.online {
  color: $onlineColor;
}

.c3lh_girls_list_controls_girl_info_online_status.online:before {
  background-color: $onlineColor;
}

/* girls photos list */

.c3lh_girls_list_photos_list {
  height: calc(100% - 79px);
  padding-top: 21px;
  position: relative;
}

.c3lh_girls_list_photo_item_big {
  height: 100%;
  width: 596px;
  background-color: transparent;
  margin: auto;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  z-index: 2;
}

.c3lh_girls_list_photo_item_big img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px 10px 0px 0px;
}

.c3lh_girls_list_photo_item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 486px;
  height: 80%;
  background-color: $bleachColor;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.c3lh_girls_list_photo_item.item-0 {
  left: 0;
}

.c3lh_girls_list_photo_item.item-2 {
  right: 0;
}

.c3lh_girls_list_photo_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
  border-radius: 10px;
}

.c3lh_girls_list_photos_list_btn_prev {
  position: absolute;
  left: 0;
  top: 50%;
  background: $background5Color;
  border-radius: 0 40px 40px 0;
  width: 77px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.c3lh_girls_list_photos_list_btn_next {
  position: absolute;
  right: 0;
  top: 50%;
  background: $background5Color;
  border-radius: 40px 0px 0px 40px;
  width: 77px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.c3lh_girls_list_photos_list_btn_prev img,
.c3lh_girls_list_photos_list_btn_next img {
  vertical-align: middle;
}

.column_2_likeher_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* column 2 tabs*/

.cl2h_tabs_wrap {
  padding: 16px;
}

.cl2h_tabs_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex: none;
}

.cl2h_tab_btn {
  width: 154px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $accentLeftColor;
  cursor: pointer;
  position: relative;
}

.cl2h_tab_btn.wide_btn.active:after {
  content: '';
  display: block;
  width: 100%;
  height: 10px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.cl2h_tab_btn.active {
  background-color: $whiteColor;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
}

.cl2h_tab_fast_filter {
  background-color: $whiteColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 0px 5px 5px 5px;
  padding: 20px;
  position: relative;
}

.cl2h_tab_fast_filter:before {
  content: '';
  display: block;
  width: 50%;
  height: 10px;
  background-color: $whiteColor;
  position: absolute;
  top: -10px;
  left: 0;
}

.cl2h_tab_fast_filter_item {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: $bleachColor;
  border-radius: 5px;
  padding-left: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  color: $greyColorBorderAlpha4;
}

.cl2h_tab_fast_filter_item.active {
  letter-spacing: 0.01em;
  color: $accentLeftColor;
  font-weight: 600;
  color: $accentLeftColor;
  background-color: $background5Color;
}

.cl2h_tab_fast_filter_reault_val {
  text-align: center;
  font-size: 12px;
  color: $accentRightColor;
  margin-top: 16px;
}

.cl2h_tab_wide_filter {
  background-color: $whiteColor;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  border-radius: 5px 0px 5px 5px;
  padding: 20px;
  position: relative;
}

.cl2h_tab_wide_filter:before {
  content: '';
  display: block;
  width: 50%;
  height: 10px;
  background-color: $whiteColor;
  position: absolute;
  top: -10px;
  right: 0;
}

.cl2h_tab_wide_filter_row {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cl2h_tab_wide_filter_row > label {
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $stormGreyColor;
  margin-bottom: 5px;
}

.cl2h_tab_wide_filter_search_btn {
  width: 159px;
  height: 42px;
  line-height: 42px;
  border: 1px solid $primaryColor;
  border-radius: 4px;
  text-align: center;
  margin: auto;
  font-weight: 600;
  color: $primaryColor;
  cursor: pointer;
  margin-top: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.cl2h_tab_wide_filter_search_btn img {
  margin-bottom: -4px;
  margin-right: 10px;
}

.cl2h_tab_wide_filter_search_btn svg {
  width: 18px;
  height: 18px;
}


.cl2h_tab_wide_filter_result_val {
  color: $accentRightColor;
  text-align: center;
  margin-top: 11px;
}

/* like filter wide select custom */

.cl2h_tab_wide_filter_row .choices {
  width: 100%;
}

.cl2h_tab_wide_filter_row_two .choices {
  width: 48%;
}

.cl2h_tab_wide_filter_row .choices__list--single .choices__item {
  width: 100%;
  height: 40px;
  line-height: 42px;
  background-color: $bleachColor;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha5;
  padding: 0px 12px;
  position: relative;
  outline: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.cl2h_tab_wide_filter_row .choices__list--dropdown .choices__item {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: $bleachColor;
  font-weight: 500;
  font-size: 14px;
  color: $defaultTextColorAlpha8;
  padding: 0 12px;
  outline: none;
  border-radius: 4px;
  margin-bottom: 2px;
}

.cl2h_tab_wide_filter_row .choices__list--dropdown {
  background-color: $bleachColor;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 6px 12px rgba(47, 43, 99, 0.16);
  padding: 0 10px 16px;
  max-height: 360px;
}

.cl2h_tab_wide_filter_row .choices__list--dropdown .is-highlighted {
  background-color: $background5Color;
}

.cl2h_tab_wide_filter_row .is-open .choices__list--single .choices__item {
  border-radius: 5px 5px 0 0;
  padding-left: 22px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 3;
}

.cl2h_tab_wide_filter_row .is-open .choices__list--dropdown {
  z-index: 2;
}

.cl2h_tab_wide_filter_row .choices__list--single.has-selected .choices__item {
  color: $accentLeftColor;
}

.cl2h_tab_wide_filter_row .choices__item--disabled {
  display: none;
}

.cl2h_tab_wide_filter_row .choices__list::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.cl2h_tab_wide_filter_row .choices__list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

/* c2 girls you liked wrap */

.c2lh_girls_you_liked_wrap {
  padding-top: 55px;
  padding-right: 5px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.c2lh_girls_you_liked_wrap2 {
  padding: 0 7px 100px 16px;
  overflow-y: auto;
  flex: 1;
}

/*.fast .c2lh_girls_you_liked_wrap2{*/
/*	height: calc(100vh - 359px);*/
/*	height: calc(calc(var(--vh, 1vh) * 100) - 359px);*/
/*	overflow-y: auto;*/
/*}*/

/*.wide .c2lh_girls_you_liked_wrap2{*/
/*	height: calc(100vh - 800px);*/
/*	height: calc(calc(var(--vh, 1vh) * 100) - 800px);*/
/*	overflow-y: auto;*/
/*}*/

.c2lh_girls_you_liked_wrap2::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 5px;
}

.c2lh_girls_you_liked_wrap2::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.c2lh_girls_you_liked_title {
  font-weight: 600;
  font-size: 18px;
  color: $accentLeftColor;
  padding-left: 16px;
  margin-bottom: 10px;
}

.c2lh_girls_you_liked_day_title {
  letter-spacing: 0.06em;
  color: $stormGreyColor;
  margin-bottom: 9px;
}

.c2lh_girls_you_liked_item + .c2lh_girls_you_liked_day_title {
  margin-top: 24px;
}

.c2lh_girls_you_liked_item {
  width: 100%;
  background-color: $whiteColor;
  border-radius: 8px;
  box-shadow: $searchItemLikeBtnShadowHover;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  cursor: pointer;
}

.c2lh_girls_you_liked_item_img {
  margin-right: 11px;
  position: relative;
}

.c2lh_girls_you_liked_item_img:after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background-color: $familySizeCondensedSoupColor;
  border-radius: 100%;
  border: 2px solid $whiteColor;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.c2lh_girls_you_liked_item_img.online:after {
  background-color: $onlineColor;
}

.c2lh_girls_you_liked_item_img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
}

.c2lh_girls_you_liked_item_id {
  display: none;
  font-size: 10px;
  color: $defaultTextColorAlpha6;
}

.c2lh_girls_you_liked_item_name {
  font-size: 16px;
  font-weight: 600;
  color: $stormGreyColor;
  margin-bottom: 5px;
}

.c2lh_girls_you_liked_item_place {
  font-size: 10px;
  letter-spacing: -0.03em;
  color: $defaultTextColorAlpha6;
}

.c2lh_girls_you_liked_item_place img {
  height: 8px;
  vertical-align: middle;
  margin-right: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.c2lh_girls_you_liked_item_profile_btn {
  width: 45px;
  height: 45px;
  line-height: 45px;
  margin-left: auto;
  display: none;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
}

.c2lh_girls_you_liked_item_profile_btn svg {
  display: inline;
  vertical-align: middle;
  fill: $accentRightColor;
  width: 24px;
  height: 24px;
}

.c2lh_girls_you_liked_item:hover .c2lh_girls_you_liked_item_profile_btn {
  display: block;
}

/*.c2lh_girls_you_liked_item_profile_btn:hover{
	background: linear-gradient(90.13deg, $accentLeftColor 0%, $accentRightColor 100%);

}

.c2lh_girls_you_liked_item_profile_btn:hover svg{
	fill: $whiteColor;
}*/

.c2lh_all_likes_btn_wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 20px;
}

.c2lh_all_likes_btn_wrap:before {
  content: '';
  display: block;
  width: 100%;
  height: 181px;
  background: linear-gradient(180deg, $transparentColor 0%, $background1Color 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.c2lh_all_likes_btn {
  width: 147px;
  height: 42px;
  line-height: 42px;
  background: $backgroundButtonColor;
  border-radius: 4px;
  text-align: center;
  color: $whiteColor;
  font-weight: 600;
  margin: auto;
  cursor: pointer;
  position: relative;
}

.c2lh_all_likes_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.c2lh_all_likes_btn img {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 13px;
}

.c2lh_all_likes_btn svg {
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 13px;
  width: 19px;
  height: 18px;
  fill: #FFFFFF;
}

.small .c3lh_girls_list_photos_list {
  height: calc(100% - 160px);
}

.small .c3lh_girls_list_controls_next_gril,
.small .c3lh_girls_list_controls_like_her {
  width: 80px;
  height: 94px;
}

.small .c3lh_girls_list_controls_next_gril img {
  width: 26px;
  margin-bottom: 12px;
  margin-top: 0;
}

.small .c3lh_girls_list_controls_next_gril span {
  display: block;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.small .c3lh_girls_list_controls_like_her img {
  width: 28px;
  margin-bottom: 10px;
  margin-top: 0;
}

.small .c3lh_girls_list_controls_like_her span {
  display: block;
  width: 70%;
  text-align: center;
  margin: 0 auto;
}

.small .c3lh_girls_list_controls {
  margin-top: -15px;
}

.small .c3lh_girls_list_bottom_btn {
  width: 166px;
  line-height: 1.3;
}

.small.c3lh_girls_list_main {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}


.small .c3lh_girls_list_controls_girl_info {
  grid-template-areas: "c3lh_name c3lh_name c3lh_status"
	"c3lh_id c3lh_place c3lh_status ";
  height: 70px;
}

.small .c3lh_girls_list_controls_girl_info_name {
  text-align: left;
}


.small .c3lh_girls_list_controls_girl_info_place {
  text-align: left;
  margin-left: -30px;
}

.small .c3lh_girls_list_controls_girl_info_place img {
  width: 23px;
  margin-top: -2px;
}

.small .c3lh_girls_list_controls_girl_info_online_status {
  margin-top: 0;
}

.small .c3lh_girls_list_controls_girl_info_id {
  margin-top: -2px;
}

.small .c3lh_girls_list_controls {
  margin-top: -24px;
}


.small .c3lh_girls_list_bottom_btn {
  width: 126px;
  line-height: 1.3;
  font-size: 12px;
  font-weight: 500;
  margin: 0 5px;
}

.small .c3lh_girls_list_bottom_btn.c3lh_glbb_videos span {
  display: inline-block;
  width: 60px;
}

iframe {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
  height: 30px;
  width: 82px;
  margin-right: 10px;
}

.c3lh_search_range_wrap .like_select_head {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 10px;
}

.cl2h_tab_wide_filter .like_select_head {
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
  @media screen and (max-width: 796px) {
    height: 30px;
    line-height: 30px;
  }
}

.c3lh_search_range_wrap .like_select_list {
  /*background-color: $whiteColor;*/
  /*margin-top: -2px;*/
  /*padding-top: 5px;*/
}

.c3lh_search_range_wrap .like_select_label {
  padding: 7px 12px 5px 5px;
}

.c3lh_search_range_wrap .like_select_list_wrap {
  padding-left: 5px;
}

.c3lh_search_range_wrap .like_select_head,
.c3lh_search_range_wrap .like_select_head.selected {
  //background-color: $whiteColor;
}


.c3lh_search_filters_list_wrap2 .c3lh_search_range_wrap {
  display: none;
}

.popup_likeher_search_photos_big_photo_chat_wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 206px;
  background: linear-gradient(0deg, #EBEDFA 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 0 0 10px 10px;
  padding: 0 15px 30px;
}

.popup_likeher_search_photos_big_photo_chat_btn {
  width: 220px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $buttonBackgroundColor;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  cursor: pointer;
  transition: box-shadow .3s;
}

.popup_likeher_search_photos_big_photo_chat_btn:hover {
  box-shadow: $searchItemBtnShadowHover;
}

.popup_likeher_search_photos_big_photo_chat_btn img {
  margin-right: 12px;
}

.popup_likeher_search_photos_big_photo_chat_btn svg {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  fill: #FFFFFF;
}

.popup_likeher_search_photos_big_photo_profile_btn {
  width: 220px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid $searchSayHelloBtnBorder;
  border-radius: 5px;
  margin-left: 14px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: $searchSayHelloBtnColor;
  cursor: pointer;
  transition: box-shadow .3s;
}

.popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn.active {
  height: 50px;
  border-radius: 0 0 5px 5px;
  border-color: #DFE3FF;
  border-bottom-color: transparent;
  z-index: 5;
  position: relative;
  background-color: #EBEDFA;
}

.popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn.active:hover {
  box-shadow: none;
}

.popup_likeher_search_photos_big_photo_hello_btn_wrap .c3_wp_hello_list_wrap {
  width: calc(100% - 61px);
  right: 50%;
  transform: translateX(50%);
  bottom: 80px;
  max-width: 454px;

  @media screen and (max-width: 599px) {
    width: 304px;
    bottom: 58px;
  }
}

.popup_likeher_search_photos_big_photo_profile_btn img {
  margin-right: 12px;
}

.popup_likeher_search_photos_big_photo_profile_btn svg {
  margin-right: 12px;
  width: 22px;
  height: 22px;
  fill: $searchSayHelloBtnBorder;
}

.popup_likeher_search_photos_big_photo_profile_btn.hello_btn svg {
  fill: #ffba30;
}

.popup_likeher_search_photos_big_photo_profile_btn:hover {
  box-shadow: $searchItemLikeBtnShadowHover;
}

.c3lh_search_filters_list_mobile_info {
  display: none;
}

@media screen and (min-width: 1921px) {
  .c3lh_your_likes_item {
    height: 713px;
  }

  .c3lh_your_likes_item_main {
    width: 390px;
    height: 650px;
  }

  .c3lh_your_likes_item_back {
    width: 410px;
    height: 672px;
  }

  .c3lh_your_likes_item_back_top_item_wrap {
    width: 62px;
    height: 48px;
  }

  .c3lh_your_likes_item_back_top_item {
    left: 6px;
  }

  .c3lh_your_likes_item_back_top_item img {
    width: 28px;
  }

  .c3lh_your_likes_item_back_bottom {
    height: 63px;
  }

  .c3lh_your_likes_item_back_b_item {
    width: 67px;
    height: 53px;
  }

  .c3lh_your_likes_item_back_b_item.item_profile {
    background-size: 27px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture {
    background-size: 30px;
  }

  .c3lh_your_likes_item_back_b_item.item_video {
    background-size: 29px;
  }

  .c3lh_your_likes_item_back_chat_btn {
    width: 125px;
    height: 37px;
    line-height: 37px;
    font-size: 18px;
  }

  .c3lh_your_likes_item_back_chat_btn img {
    width: 22px;
  }

  .c3lh_your_likes_item_profile_name {
    font-size: 24px;
  }

  .c3lh_your_likes_item_profile_online {
    font-size: 14px;
  }

  .c3lh_your_likes_item_profile_online:before {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 3200px) {
  .c3lh_your_likes_item {
    height: 907px;
  }

  .c3lh_your_likes_item_main {
    width: 497px;
    height: 827px;
  }

  .c3lh_your_likes_item_back {
    width: 522px;
    height: 857px;
  }

  .c3lh_your_likes_item_back_top_item_wrap {
    width: 80px;
    height: 55px;
  }

  .c3lh_your_likes_item_back_top_item {
    left: 12px;
  }

  .c3lh_your_likes_item_back_top_item img {
    width: 35px;
  }

  .c3lh_your_likes_item_back_bottom {
    height: 80px;
  }

  .c3lh_your_likes_item_back_b_item {
    width: 86px;
    height: 68px;
  }

  .c3lh_your_likes_item_back_b_item.item_profile {
    background-size: 34px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture {
    background-size: 38px;
  }

  .c3lh_your_likes_item_back_b_item.item_video {
    background-size: 36px;
  }

  .c3lh_your_likes_item_back_chat_btn {
    width: 160px;
    height: 47px;
    line-height: 47px;
  }

  .c3lh_your_likes_item_profile_name {
    font-size: 28px;
  }

  .c3lh_your_likes_item_profile_online {
    font-size: 18px;
  }

  .c3lh_your_likes_item_profile_online:before {
    width: 13px;
    height: 13px;
  }
}

@media screen and (max-width: 1899px) {
  .c3lh_search_filters_list_wrap2 {
    justify-content: center;
  }
}

@media screen and (max-width: 1699px) {
  .c2lh_girls_you_liked_item {
    position: relative;
  }

  .c2lh_girls_you_liked_item_profile_btn {
    display: block;
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

@media screen and (max-width: 1599px) {
  .popup_likeher_photo_unpaid_warning {
    font-size: 12px;
  }

  .popup_likeher_photo_unpaid_warning span {
    font-size: 18px;
  }
}

@media screen and (max-width: 1499px) {
  .c3lh_search_range_title span {
    display: none;
  }
}

@media screen and (max-width: 1499px) {
  .c3lh_search_filter_item_online_status_inp {
    //flex-wrap: wrap;
    //width: 90px;
  }

  .c3lh_search_filter_item_online_status_inp_text {
    //font-size: 12px;
    margin-right: 5px;
    margin-bottom: 0;
  }

  .c3lh_search_filter_item_online_status_inp label {
    height: 16px;
  }

  .c3lh_search_filter_item_online_status_inp label:before {
    width: 12px;
    height: 12px;
  }

  .c3lh_search_filter_item_online_status_inp input:checked + label:before {
    left: 21px;
  }

  .c3lh_search_range_title {
    /*width: 60px;*/
    font-size: 12px;
    text-transform: capitalize;
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
    width: 64px;
    z-index: 5;
  }

  .c3lh_search_range_wrap .like_select_head {
    padding-left: 5px;
  }

  .c3lh_search_range_wrap .like_select_head:after {
    right: 5px;
  }

  .c3lh_search_range_wrap .like_select_label {
    padding: 7px 5px 5px;
    margin: 0 5px;
  }

  .c3lh_search_filters_btn_wrap {
    width: 178px;
  }

  .c3lh_search_id_wrap input {
    width: 113px;
  }

  .popup_likeher_photo_unpaid_warning {
    font-size: 12px;
  }

  .popup_likeher_photo_unpaid_warning span {
    font-size: 14px;
  }
}

@media screen and (max-width: 1399px) {
  .c3lh_search_id_wrap {
    margin-left: 20px;
  }

  .c3lh_your_likes_item_main {
    width: 215px;
    height: 360px;
  }

  .c3lh_your_likes_item_back {
    width: 240px;
    height: 386px;
  }

  .c3lh_your_likes_item {
    height: 396px;
  }
}

@media screen and (max-width: 1399px) and (min-width: 600px) {
  .c3lh_your_likes_item_big {
    width: 474px;
    height: 565px;
    grid-column-start: span auto;
    margin: 0 auto;
  }

  .c3lh_your_likes_item_big_main_profile_wrap {
    height: calc(100% - 62px);
  }

  .c3lh_your_likes_profile_content {
    height: calc(100% - 35px);
  }

  .c3lh_your_likes_item_big_main {
    width: 299px;
  }

  .c3lh_your_likes_item_big_big_photo {
    padding: 22px 26px 0;
  }

  .c3lh_your_likes_item_big_close img {
    width: 15px;
  }

  .c3lh_your_likes_item_big_big_photo_next {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .c3lh_your_likes_item_big_big_photo_next img {
    width: 14px;
  }

  .c3lh_your_likes_item_big_profile {
    padding-left: 26px;
  }

  .c3lh_your_likes_item_big_back_top {
    width: 175px;
    padding: 18px;
  }

  .c3lh_your_likes_item_big_back_top_item span {
    display: block;
  }

  .c3lh_your_likes_item_big_back_top_item {
    position: static;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    padding: 0;
    padding-left: 9px;
    height: 42px;
  }

  .c3lh_your_likes_item_big_back_top_item_wrap {
    width: 100%;
  }

  .c3lh_your_likes_item_big_back_top_item img {
    margin: unset;
    margin-right: 9px;
    width: 29px;
  }

  .c3lh_your_likes_item_big_back_top_item:hover img {
    margin-right: 9px;
  }

  .c3lh_your_likes_like_ico {
    width: 29px;
    height: 29px;
    -webkit-background-size: 23px;
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 9px;
  }

  .c3lh_your_likes_item_big_back_top_item:hover .c3lh_your_likes_like_ico {
    margin-right: 9px;
  }

  .c3lh_your_likes_item_big_back_top_item span {
    font-size: 14px;
  }

  .c3lh_your_likes_profile_photo img {
    width: 50px;
    height: 50px;
  }

  .c3lh_your_likes_profile_head_name {
    font-size: 14px;
    font-weight: 500;
    margin: 1px 0 2px;
  }

  .c3lh_your_likes_profile_head_id {
    font-size: 10px;
  }

  .c3lh_your_likes_profile_head_place {
    font-size: 10px;
  }

  .c3lh_your_likes_profile_head_place img {
    height: 10px;
  }

  .c3lh_your_likes_profile_content_title {
    font-size: 14px;
  }

  .c3lh_your_likes_profile_content_block ul li {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .c3lh_your_likes_profile_content_block p {
    font-size: 14px;
  }

  .c3lh_your_likes_item_big_back {
    height: calc(100% - 39px);
  }

  .c3lh_your_likes_video_title {
    font-size: 14px;
  }

  .c3lh_your_likes_item_big_main.video {
    padding-top: 13px;
  }

  .c3lh_your_likes_video_list_wrap {
    margin-top: 10px;
    height: calc(100% - 90px);
  }

  .c3lh_your_likes_video_item {
    height: 150px;
  }

  .c3lh_your_likes_video_item:after {
    width: 56px;
    height: 56px;
  }
}

.c3lh_search_filters_list_wrap .fill_data_form_select_item_name {
  margin-bottom: 4px;
}

@media screen and (max-width: 1299px) {
  .c3lh_search_range_title {
    /*text-align: center;
		width: 70px;
*/
  }

  .c3lh_search_range_wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4px;
  }

  /*.c3lh_your_likes_list_wrap2{
		height: calc(100vh - 70px);
		height: calc(calc(var(--vh, 1vh) * 100) - 70px);
	}

	.need_confirm_email .c3lh_your_likes_list_wrap2{
		height: calc(100vh - 160px);
		height: calc(calc(var(--vh, 1vh) * 100) - 160px);
	}*/

  .c3lh_search_filters_btn_wrap.active {

  }

  .c3lh_girls_list_controls {
    justify-content: center;
  }

  .c3lh_girls_list_main {
    height: calc(100vh - 156px);
    height: calc(calc(var(--vh, 1vh) * 100) - 156px);
    padding: 0 16px;
  }

  .c3lh_girls_list_controls_next_gril,
  .c3lh_girls_list_controls_like_her {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    -o-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: calc(50% - 8px);
    height: 50px;
  }

  .c3lh_girls_list_controls_next_gril img {
    width: 26px;
    margin: 0 0 0 16px;
  }

  .c3lh_girls_list_bottom_controls {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    padding: 0 8px 20px;
  }

  .c3lh_girls_list_controls_like_her img {
    width: 28px;
    margin: 0 0 0 16px;
  }

  .c3lh_girls_list_bottom_btn {
    width: 126px;
    line-height: 1.3;
    font-size: 12px;
    font-weight: 500;
    margin: 0 5px;
  }

  .c3lh_girls_list_bottom_btn.c3lh_glbb_videos span {
    display: inline-block;
    width: 60px;
  }

  .c3lh_girls_list_controls_girl_info {
    grid-template-areas: "c3lh_name c3lh_name c3lh_status"
		"c3lh_id c3lh_place c3lh_status ";
    height: 70px;
  }

  .c3lh_girls_list_controls_girl_info_name {
    text-align: left;
  }


  .c3lh_girls_list_controls_girl_info_place {
    text-align: left;
    margin-left: -30px;
  }

  .c3lh_girls_list_controls_girl_info_place img {
    width: 23px;
    margin-top: -2px;
  }

  .c3lh_girls_list_controls_girl_info_online_status {
    margin-top: 0;
  }

  .c3lh_girls_list_controls_girl_info_id {
    margin-top: -2px;
  }

  .c3lh_girls_list_photos_list {
    height: calc(100% - 20px);
  }

  .c3lh_search_filters_list_wrap2 {
    //grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
}

@media screen and (max-width: 1199px) {
  .popup_likeher_sendgift_list {
    justify-content: space-evenly;
  }

  .c3lh_search_id_wrap {
    margin-left: 0;
    margin-right: 50px;
  }

  .c3mp_videos_page_wrap .c3lh_search_top {
    padding-right: 15px;
  }
}

@media screen and (max-width: 1099px) {
  .popup_likeher_sendgift_item_full {
    grid-column-end: 3;
  }

  .popup_likeher_sendgift_list {
    grid-template-rows: unset;
  }

  .popup_likeher_sendgift_item_full_name {
    font-size: 16px;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 1140px) {
    .c3lh_your_likes_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    .c3lh_your_likes_list_wrap2 {
      height: auto;
      flex: 1;
    }

    .need_confirm_email .c3lh_your_likes_list_wrap2 {
      height: auto;
    }

    .c3lh_search_filter_item_online_status_inp {
      display: none;
      width: 100%;
    }

    .c3lh_search_id_wrap {
      display: none;
      width: 255px;
      margin: 0 auto 16px;
    }

    .c3lh_search_filters_list_wrap2_close {
      display: block;
      position: absolute;
      right: 7px;
      top: 4px;
      cursor: pointer;
      padding: 5px;
    }

    .c3lh_search_filters_list_wrap2_close img {
      width: 16px;
      display: block;
    }

    .c3lh_search_filters_list_wrap2_close svg {
      width: 22px;
      height: 22px;
      fill: #d3d7de;
      stroke: #d3d7de;
      stroke-width: 0.8;
      display: block;
    }

    .c3lh_search_filters_list_mob_top {
      margin-bottom: 35px;
      display: flex;
      flex-direction: column-reverse;
    }

    .c3lh_search_filters_list_mob_top .c3lh_search_id_wrap {
      display: block;
      width: 100%;
      margin-bottom: 0;
      margin-top: 16px;
    }

    .c3lh_search_id_wrap input {
      width: 100%;
      background: $whiteColor;
      border: 1px solid $familySizeCondensedSoupColor;
      border-radius: 4px;
      box-shadow: unset;
      height: 42px;
    }

    .c3lh_search_id_wrap button {
      width: 40px;
      height: 40px;
      top: 1px;
    }

    .c3lh_search_filters_list_mob_top .c3lh_search_filter_item_online_status_inp {
      display: flex;
      align-items: center;
    }

    .c3lh_search_filter_item_online_status_inp_text {
      font-size: 14px;
      font-weight: 500;
      margin-right: 20px;
      margin-bottom: 0;
    }

    .c3lh_search_filter_item_online_status_inp label {
      width: 43px;
      height: 20px;
    }

    .c3lh_search_filter_item_online_status_inp label:before {
      width: 16px;
      height: 16px;
    }

    .c3lh_search_filter_item_online_status_inp input:checked + label:before {
      left: 24px;
    }

    .c3lh_search_filters_list_wrap .fill_data_form_select_item_name {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .c3lh_search_filters_btn_wrap b {
      display: none;
    }

    .c3lh_search_filters_btn_wrap {
      width: auto;
      border: none;
      background-color: transparent;
      box-shadow: unset;
    }

    .c3lh_search_filters_btn_wrap.active {
      margin-bottom: 0;
      line-height: 1.3;
      height: auto;
      align-self: center;
    }

    .c3lh_search_filters_btn_wrap:after {
      content: "Filters";
      display: inline-block;
      width: auto;
      height: auto;
      background-image: unset;
      position: static;
      color: $accentRightColor;
      font-weight: 600;
      letter-spacing: -0.02em;
      font-size: 12px;
    }

    .c3lh_search_filters_btn_wrap.active:after {
      transform: rotate(0);
    }

    .c3lh_search_filters_btn_wrap img {
      width: 20px;
      display: none;
    }

    .c3lh_search_filters_btn_wrap {
      background-image: url(../../img/c3s-filters-btn-violet.svg);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 17px;
      padding-left: 23px;
      padding-right: 0;
    }

    .c3lh_search_range_wrap {
      width: 100%;
      justify-content: center;
      margin-bottom: 18px;
    }

    .c3lh_search_top > .c3lh_search_range_wrap {
      display: none;
    }

    .c3lh_search_top > .c3lh_search_filters_btn_wrap {
      display: none;
    }

    .c3lh_search_top {
      height: 0px;
      padding: 0;
      justify-content: space-between;
      padding-right: 10px;
    }

    .c3lh_search_range_title {
      margin-bottom: 0px;
      margin-right: 6px;
      text-transform: capitalize;
      width: auto;
    }

    .c3lh_search_range_selects_wrap {
      margin: 0;
    }

    .c3lh_search_range_title span {
      display: none;
    }

    .c3lh_search_top .like_select_head {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding-left: 10px;
    }

    .c3lh_search_range_wrap .like_select_head:after, .c3lh_search_range_wrap .like_select_head.selected:after {
      display: none;
    }

    .c3lh_search_range_wrap .like_select_head, .c3lh_search_range_wrap .like_select_head.selected {
      font-size: 12px;
      padding: 0 5px;
      text-align: center;
      height: 24px;
      line-height: 24px;
    }

    .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
      width: 49px;
      height: 24px;
    }

    .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap:last-child {
      margin-right: 0;
    }

    .c3lh_search_range_btn_confirm {
      margin-left: auto;
    }

    .c3lh_search_range_wrap .like_select_label {
      margin: 0;
      text-align: center;
    }

    .c3lh_search_filters_list_wrap {
      width: 237px;
      height: calc(100% - 127px);
      position: fixed;
      top: 64px;
      left: 0;
      max-height: unset;
      padding-bottom: 12px;
      padding-left: 10px;
      padding-right: 5px;
      background-color: $background1Color;
      display: flex;
      flex-direction: column;
      border-radius: 0 8px 0 0;
      box-shadow: none;
      margin: 0;
      z-index: 6;
    }

    .c3lh_search_filters_list_wrap:before {
      content: "";
      display: none;
      width: 100vw;
      height: calc(100vh - 95px);
      background-color: $fadeColor;
      backdrop-filter: blur(4px);
      position: fixed;
      left: 0;
      top: 50px;
      z-index: -1;
    }

    .c3lh_search_filters_list_wrap:after {
      content: "";
      display: none;
      width: calc(100% - 12px);
      height: calc(100% - 72px);
      background-color: $whiteColor;
      position: fixed;
      left: 6px;
      top: 60px;
      z-index: -1;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    .c3lh_search_filters_list_wrap2_close {
      width: 22px;
      height: 22px;
      background-image: url(../../img/cross_white.svg);
      background-size: contain;
      left: 256px;
      top: 64px;
      position: fixed;
    }

    .c3lh_search_filters_list_wrap2_close img {
      display: none;
    }

    .c3lh_search_filters_list_wrap2_close svg {
      display: none;
    }

    .c3lh_search_filters_list_btn_find {
      margin: 0 auto;
    }

    .c3lh_search_filters_list_wrap2 {
      max-height: unset;
      padding-right: 5px;
      padding-left: 0px;
      flex: 1;
      z-index: 8;
      display: block;
    }

    .need_confirm_email .c3lh_search_filters_list_wrap {
      max-height: unset;
      padding-right: 5px;
      flex: 1;
      z-index: 20;
      padding-top: 16px;
    }


    .need_confirm_email .c3lh_search_filters_list_wrap2 {
      max-height: unset;
      padding-right: 5px;
      flex: 1;
      z-index: 8;
    }

    .c3lh_search_filters_list_wrap2 {
      /*height: calc(100vh - 200px);
            height: calc(calc(var(--vh, 1vh) * 100) - 200px);
*/
      margin-bottom: 0;
    }

    .c3lh_search_filters_list_wrap2_title {
      display: none;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: -0.02em;
      color: $accentLeftColor;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }

    .c3lh_search_filters_list_wrap2_title img {
      width: 20px;
      margin-right: 9px;
    }

    .c3lh_search_filters_list_wrap2_title svg {
      width: 20px;
      height: 20px;
      margin-right: 9px;
      fill: #446178;
    }

    .c3lh_search_top .fill_data_form_select_item {
      height: 30px;
    }

    .popup_likeher_search_photos_close {
      width: 66px;
      height: 66px;
      right: 50%;
      top: unset;
      bottom: 12px;
      transform: translateX(50%);
      background-image: url(../../img/icon_exit.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .popup_likeher_search_photos_close img {
      width: 20px;
      display: none;
    }

    .popup_likeher_search_photos_close svg {
      display: none;
    }
  }
}

@media screen and (max-width: 1139px) {
  .c3lh_your_likes_wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: unset;
  }

  .c3lh_your_likes_list_wrap2 {
    height: auto;
    flex: 1;
  }

  .need_confirm_email .c3lh_your_likes_list_wrap2 {
    height: auto;
  }

  .c3lh_search_filter_item_online_status_inp {
    display: none;
    width: 100%;
  }

  .c3lh_search_id_wrap {
    display: none;
    width: 255px;
    margin: 0 auto 16px;
  }

  .c3lh_search_filters_list_wrap2_close {
    display: block;
    position: fixed;
    right: 7px;
    //top: 4px;
    cursor: pointer;
    padding: 5px;
    left: 256px;
    top: 64px;
  }

  .c3lh_search_filters_list_wrap2_close img {
    width: 16px;
    display: block;
  }

  .c3lh_search_filters_list_wrap2_close svg {
    width: 22px;
    height: 22px;
    fill: #d3d7de;
    stroke: #d3d7de;
    stroke-width: 0.8;
    display: block;
  }

  .c3lh_search_filters_list_mob_top {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column-reverse;
  }

  .c3lh_search_filters_list_mob_top .c3lh_search_id_wrap {
    display: block;
    width: 100%;
    margin-bottom: 0;
    margin-top: 16px;
  }

  .c3lh_search_filters_list_wrap_mobile .c3lh_search_id_wrap {
    display: block;
    width: 213px;
  }

  .c3lh_search_filters_list_wrap_mobile .c3lh_search_filter_item_online_status_inp {
    display: flex;
  }

  .c3lh_search_id_wrap input {
    width: 100%;
    background: $whiteColor;
    border: 1px solid $familySizeCondensedSoupColor;
    border-radius: 4px;
    box-shadow: unset;
    height: 42px;
  }

  .c3lh_search_id_wrap button {
    width: 40px;
    height: 40px;
    top: 1px;
  }

  .c3lh_search_filters_list_mob_top .c3lh_search_filter_item_online_status_inp {
    display: flex;
    align-items: center;
  }

  .c3lh_search_filter_item_online_status_inp_text {
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .c3lh_search_filter_item_online_status_inp label {
    width: 43px;
    height: 20px;
  }

  .c3lh_search_filter_item_online_status_inp label:before {
    width: 16px;
    height: 16px;
  }

  .c3lh_search_filter_item_online_status_inp input:checked + label:before {
    left: 24px;
  }

  .c3lh_search_filters_list_wrap .fill_data_form_select_item_name {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .c3lh_search_filters_btn_wrap b {
    display: none;
  }

  .c3lh_search_filters_btn_wrap {
    width: auto;
    border: none;
    background-color: transparent;
    box-shadow: unset;
  }

  .c3lh_search_filters_btn_wrap.active {
    margin-bottom: 0;
    line-height: 1.3;
    height: auto;
    align-self: center;
  }

  .c3lh_search_filters_btn_wrap:after {
    content: "Filters";
    display: inline-block;
    width: auto;
    height: auto;
    background-image: unset;
    position: static;
    color: $accentRightColor;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 12px;
  }

  .c3lh_search_filters_btn_wrap.active:after {
    transform: rotate(0);
  }

  .c3lh_search_filters_btn_wrap img {
    width: 20px;
    display: none;
  }

  .c3lh_search_filters_btn_wrap {
    background-image: url(../../img/c3s-filters-btn-violet.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 17px;
    padding-left: 23px;
    padding-right: 0;
  }

  .c3lh_search_range_wrap {
    width: 100%;
    justify-content: center;
    margin-bottom: 18px;
  }

  .c3lh_search_top > .c3lh_search_range_wrap {
    display: none;
  }

  .c3lh_search_top > .c3lh_search_filters_btn_wrap {
    display: none;
  }

  .c3lh_search_top {
    height: 0px;
    padding: 0;
    justify-content: space-between;
    padding-right: 10px;
  }

  .c3lh_search_range_title {
    margin-bottom: 0px;
    margin-right: 6px;
    text-transform: capitalize;
    width: auto;
  }

  .c3lh_search_range_selects_wrap {
    margin: 0;
  }

  .c3lh_search_range_title span {
    display: none;
  }

  .c3lh_search_top .like_select_head {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 10px;
  }

  .c3lh_search_range_wrap .like_select_head:after, .c3lh_search_range_wrap .like_select_head.selected:after {
    display: none;
  }

  .c3lh_search_range_wrap .like_select_head, .c3lh_search_range_wrap .like_select_head.selected {
    font-size: 12px;
    padding: 0 5px;
    text-align: center;
    //height: 24px;
    //line-height: 24px;
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
    width: 49px;
    height: 24px;
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap:last-child {
    margin-right: 0;
  }

  .c3lh_search_range_btn_confirm {
    margin-left: auto;
  }

  .c3lh_search_range_wrap .like_select_label {
    margin: 0;
    text-align: center;
  }

  .c3lh_search_filters_list_wrap {
    width: 237px;
    height: calc(100% - 127px);
    position: fixed;
    top: 64px;
    left: 0;
    max-height: unset;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 5px;
    background-color: $background1Color;
    display: flex;
    flex-direction: column;
    border-radius: 0 8px 0 0;
    box-shadow: none;
    margin: 0;
    z-index: 6;
    overflow-y: scroll;
  }

  .c3lh_search_filters_list_wrap:before {
    content: "";
    display: none;
    width: 100vw;
    height: calc(100vh - 95px);
    background-color: $fadeColor;
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 50px;
    z-index: -1;
  }

  .c3lh_search_filters_list_wrap:after {
    content: "";
    display: none;
    width: calc(100% - 12px);
    height: calc(100% - 72px);
    background-color: $whiteColor;
    position: fixed;
    left: 6px;
    top: 60px;
    z-index: -1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .c3lh_search_filters_list_wrap2_close {
    width: 22px;
    height: 22px;
    background-image: url(../../img/cross_white.svg);
    background-size: contain;
    left: 256px;
    top: 64px;
  }

  .c3lh_search_filters_list_wrap2_close img {
    display: none;
  }

  .c3lh_search_filters_list_wrap2_close svg {
    display: none;
  }

  .c3lh_search_filters_list_btn_find {
    margin: 0 auto;
  }

  .c3lh_search_filters_list_wrap2 {
    max-height: unset;
    padding-right: 5px;
    padding-left: 0px;
    flex: 1;
    z-index: 8;
    display: block;
  }

  .need_confirm_email .c3lh_search_filters_list_wrap {
    max-height: unset;
    padding-right: 5px;
    flex: 1;
    z-index: 20;
    padding-top: 16px;
  }


  .need_confirm_email .c3lh_search_filters_list_wrap2 {
    max-height: unset;
    padding-right: 5px;
    flex: 1;
    z-index: 8;
  }

  .c3lh_search_filters_list_wrap2 {
    /*height: calc(100vh - 200px);
		height: calc(calc(var(--vh, 1vh) * 100) - 200px);
*/
    margin-bottom: 0;
  }

  .c3lh_search_filters_list_mobile_info {
    display: block;
    padding: 0 0 12px;
    text-align: center;

    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    color: #446077;
    position: relative;
  }

  .c3lh_search_filters_list_mobile_info:after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% + 12px + 12px);
    bottom: 0;
    left: -12px;
    background-color: #fff;
  }

  .c3lh_search_filters_list_wrap2_title {
    display: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: $accentLeftColor;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .c3lh_search_filters_list_wrap2_title img {
    width: 20px;
    margin-right: 9px;
  }

  .c3lh_search_filters_list_wrap2_title svg {
    width: 20px;
    height: 20px;
    margin-right: 9px;
    fill: #446178;
  }

  .c3lh_search_top .fill_data_form_select_item {
    height: 30px;
  }

  .popup_likeher_search_photos_close {
    width: 66px;
    height: 66px;
    right: 35px;
    top: 15px;
    bottom: 15px;
    transform: translateX(50%);
    background-image: url(../../img/icon_exit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .popup_likeher_search_photos_close img {
    width: 20px;
    display: none;
  }

  .popup_likeher_search_photos_close svg {
    display: none;
  }

  .popup_likeher_search_photos_big_photo > img {
    max-height: calc(calc(var(--vh, 1vh) * 100) - 160px);
  }

  .c3lh_search_filter_item:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 999px) {
  .c3lh_search_filters_list_wrap2 {
    /*max-height: calc(100vh - 170px);
		max-height: calc(calc(var(--vh, 1vh) * 100) - 300px);
*/
  }

  .need_confirm_email .c3lh_search_filters_list_wrap2 {
    /*max-height: calc(100vh - 250px);
		max-height: calc(calc(var(--vh, 1vh) * 100) - 380px);
*/
  }

  .c3lh_search_id_wrap {
    margin-right: 0;
  }

  .c3lh_girls_list_main {
    height: calc(100vh - 236px);
    height: calc(calc(var(--vh, 1vh) * 100) - 236px);
  }


  .c3mp_videos_page_wrap .c3lh_search_top {
    padding-right: 0px;
    margin-left: 10px;
  }

  /*.fast .c2lh_girls_you_liked_wrap2{*/
  /*	height: calc(100vh - 457px);*/
  /*	height: calc(calc(var(--vh, 1vh) * 100) - 457px);*/
  /*}*/

  /*.wide .c2lh_girls_you_liked_wrap2{*/
  /*	height: calc(100vh - 891px);*/
  /*	height: calc(calc(var(--vh, 1vh) * 100) - 891px);*/
  /*}*/
}

@media (hover: none) and (pointer: coarse) {
  .c3lh_search_filters_list_wrap2 .c3lh_search_range_wrap {
    display: flex;
  }

  .c3lh_your_likes_item_back_top_item:hover {
    background-color: transparent;
    box-shadow: none;
    z-index: auto;
  }

  .active_likehistory_hint:hover span {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .c3lh_your_likes_item {
      height: 355px;
    }

    .c3lh_your_likes_item_back {
      width: 220px;
      height: 343px;
    }

    .c3lh_your_likes_item_main {
      width: 191px;
      height: 319px;
    }

    .c3lh_your_likes_wrap {
      padding-left: 20px;
      padding-right: 10px;
      padding-top: 20px;
    }

    .c3lh_your_likes_list_wrap2 {
      padding-right: 10px;
    }

    .c3lh_search_filters_list_wrap {
      width: 340px;
    }
    .c3lh_search_filters_list_wrap2_close {
      left: 360px;
      top: 72px;
    }

    .c3lh_search_filters_list_btn_find {
      width: calc(100% - 5px);
      margin: 0;
    }
  }

  @media screen and (min-width: 1000px) {
    .popup_likeher_search_photos_close {
      right: 10px;
      top: 10px;
      bottom: unset;
      left: unset;
      transform: unset;
    }

    .popup_likeher_search_photos_big_photo > img {
      max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);
    }

    .c3lh_search_filters_list_wrap {
    }
  }

  @media screen and (min-width: 1000px) and (min-height: 1299px) {
    .popup_likeher_search_photos_close {
      width: 80px;
      height: 80px;
      left: 50%;
      bottom: 12px;
      transform: translateX(-50%);
      top: unset;
      right: unset;
    }
  }
}

@media screen and (max-height: 900px) {

  .cl2h_tab_wide_filter::-webkit-scrollbar {
    width: 3px;
    background: #fff;
    border-radius: 3px;
  }

  .cl2h_tab_wide_filter::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
  }

  .cl2h_tab_wide_filter .fill_data_form_select_list {
    margin-bottom: 0;
  }

  .cl2h_tab_wide_filter .fill_data_form_select_list .fill_data_form_select_item_wrap:last-child {
    margin-bottom: 0;
  }

  .c2lh_all_likes_btn_wrap {
    /*position: static;*/
  }
}

@media screen and (max-height: 800px) {
  .popup_likeher_search_photos_big_photo_chat_wrap {
    height: 146px;
    padding: 0 10px 18px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn {
    width: 145px;
    height: 33px;
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn img {
    width: 17px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn svg {
    width: 20px;
    height: 20px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn {
    width: 145px;
    height: 33px;
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn img {
    width: 17px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn svg {
    width: 18px;
    height: 18px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn.active {
    height: 40px;
  }
}

@media screen and (max-width: 999px) {
  .c3lh_search_filters_list_wrap2 .c3lh_search_range_wrap {
    display: flex;
  }
}

@media screen and (max-width: 899px) {
  .c3lh_girls_list_controls {
    margin-top: -15px;
  }

  .c3lh_girls_list_controls_girl_info {
    grid-template-areas: "c3lh_name c3lh_name c3lh_status"
		"c3lh_id c3lh_place c3lh_status ";
    height: 70px;
  }

  .c3lh_search_filters_btn_wrap {
    width: 82px;
  }

  .c3lh_search_filters_btn_wrap.active:before {
    display: none;
  }

  .c3lh_search_filters_btn_wrap span {
    display: none;
  }

  .popup_likeher_sendgift_item_full.active {
    display: block;
  }

  .popup_likeher_sendgift_item_full_photos_list {
    width: 306px;
  }

  .popup_likeher_sendgift_item_full_right {
    padding: 17px 15px 0;
  }

  .c3lh_girls_list_controls_girl_info_name {
    text-align: left;
  }


  .c3lh_girls_list_controls_girl_info_place {
    text-align: left;
    margin-left: -30px;
  }

  .c3lh_girls_list_controls_girl_info_place img {
    width: 23px;
    margin-top: -2px;
  }

  .c3lh_girls_list_controls_girl_info_online_status {
    margin-top: 0;
  }

  .c3lh_girls_list_controls_girl_info_id {
    margin-top: -2px;
  }

  .c3lh_girls_list_controls {
    margin-top: -24px;
  }
}

@media screen and (max-width: 789px) {
  .popup_likeher_search_photos_big_photo_chat_wrap {
    justify-content: center;
    margin: 0;
  }

  .popup_likeher_search_photos_big_photo.unpaid .popup_likeher_photo_unpaid_warning .popup_likeher_search_photos_list_item_chat_btn {
    display: none;
  }

  .popup_likeher_search_photos_list_wrap {
    display: none;
  }

  .popup_likeher_search_photos_big_photo {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .popup_likeher_search_photos_big_photo > img {
    max-width: unset;
    max-height: unset;
    width: calc(100% - 16px);
    //height: 100%;
    //object-fit: cover;
    object-fit: contain;
    object-position: top center;
    margin: auto;
  }

  .popup_likeher_search_photos_close {
    right: 15px;
    top: 15px;
    bottom: unset;
    transform: unset;
  }

  .popup_likeher_search_photos_next {
    width: 50px;
    height: 70px;
    line-height: 40px;
    border-radius: 0;
    background-image: url(../../img/lh-arr-right.svg);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }

  .popup_likeher_search_photos_prev {
    width: 50px;
    height: 70px;
    line-height: 40px;
    border-radius: 0;
    background-image: url(../../img/lh-arr-left.svg);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }

  .popup_likeher_search_photos_prev img,
  .popup_likeher_search_photos_next img {
    display: none;
  }
}

@media screen and (max-width: 770px) {
  .c3lh_search_top {
    padding-bottom: 0;
    margin: 0;
    border: none;
  }

  .c3lh_search_filter_item_hobbies_title {
    height: 30px;
    line-height: 32px;
  }

  .c3lh_search_filter_item_hobbies_list_wrap {
    top: 55px;
  }
}

@media screen and (max-width: 700px) {
  .need_confirm_email .c3lh_search_filters_list_wrap {
    /*max-height: calc(100vh - 280px);
		max-height: calc(calc(var(--vh, 1vh) * 100) - 280px);
*/
  }

  .c3lh_search_range_title {
    width: auto;
  }

  .popup_compose_head {
    justify-content: center;
  }

  .popup_compose_head_choosen_filters {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .popup_compose_head_right {
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
  }

  .c3lh_search_top {
    /*-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 10px;
		height: 100px;
*/
  }

  .c3lh_search_range_wrap {
    width: 100%;
    justify-content: center;
  }

  .c3lh_search_filters_btn_wrap {
    /*margin-right: auto;
*/
  }

  .c3lh_search_filter_item_online_status_inp {
    margin-left: auto;
    margin-right: auto;
  }

  .c3lh_search_id_wrap input {
    /*width: 84px;
*/
  }

  .c3lh_your_likes_wrap {
    padding: 0 5px 0 10px;
  }

  .c3lh_your_likes_list_wrap2 {
    padding-right: 7px;
    padding-left: 0;
  }

  .popup_likeher_sendgift_item_full.active {
    grid-column-end: 2;
  }

  .popup_likeher_sendgift_item_full_creds {
    font-size: 18px;
  }

  .popup_likeher_sendgift_item_full_added_btn {
    margin-left: 0;
  }
}

@media screen and (max-width: 599px) {
  .c3lh_search_filters_list_wrap .fill_data_form_select_item_name {
    font-size: 12px;
  }

  .c3lh_search_filters_list_wrap_mobile .fill_data_form_select_item_name {
    font-size: 14px;
  }

  .c3lh_search_top .like_select_head {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }

  .c3lh_search_filters_list_wrap_mobile .like_select_head {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .c3lh_your_likes_wrap {
    padding-left: 0;
  }

  .c3lh_your_likes_list_wrap2 {
    padding-top: 12px;
    padding-bottom: 16px;
  }

  .c3lh_search_filters_list_wrap {
    /*max-height: calc(100vh - 200px);
		max-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
		-webkit-border-radius: 0 10px 10px 10px;
		-moz-border-radius: 0 10px 10px 10px;
		border-radius: 0 10px 10px 10px;
*/
    top: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 100px);
    z-index: 20;
  }

  .c3lh_search_filters_list_wrap2 {
    display: block;
  }

  .c3lh_your_likes_item_profile_online:before {
    width: 7px;
    height: 7px;
  }

  .c3lh_search_range_title {
    font-size: 12px;
    margin-right: 10px;
  }

  .c3lh_search_filter_item {
    margin: 0 auto 24px;
    max-width: 100%;
  }

  .c3lh_search_filters_list_wrap_mobile .c3lh_search_filter_item {
    margin: 0;
    width: 213px;
  }

  .c3lh_search_range_selects_wrap .choices__list--single .choices__item {
    width: 59px;
  }

  .c3lh_search_range_selects_wrap .choices__list--dropdown .choices__item {
    width: 59px;
  }

  .c3lh_search_range_selects_wrap {
    margin: 0;
  }

  .c3lh_search_range_btn_confirm img {
    width: 18px;
  }

  .c3lh_your_likes_item_big {
    display: block;
    grid-column-start: span 1;
    grid-row-start: span 1;
    height: 100%;
    width: 100%;
  }

  .c3lh_your_likes_item_big_big_photo {
    padding: 23px 23px 0;
    height: calc(100% - 56px);
  }

  .c3lh_your_likes_item_big_main {
    width: calc(100% - 54px);
    height: calc(100% - 36px);
  }

  .c3lh_your_likes_item_big_back_top {
    width: 54px;
  }

  .c3lh_your_likes_item_big_back_top_item img {
    width: 24px;
  }

  .c3lh_your_likes_item_big_back_top_item_wrap {
    width: 100%;
    height: auto;
  }

  .c3lh_your_likes_like_ico {
    width: 24px;
    height: 24px;
    -webkit-background-size: 20px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
  }

  .c3lh_your_likes_item_big_back_top_item {
    padding: 10px 0;
    margin: auto;
    position: static;
  }

  .c3lh_your_likes_item_big_back_top_item:hover img {
    margin-right: auto;
  }

  .c3lh_your_likes_item_big_back_top_item:hover span {
    display: none;
  }

  .c3lh_your_likes_item_big_back_top_item:hover .c3lh_your_likes_like_ico {
    margin-right: auto;
  }

  .c3lh_your_likes_item_big_back_top_item:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .c3lh_your_likes_item_big_close,
  .c3lh_your_likes_item_big_big_photo_next {
    display: none;
  }

  .c3lh_your_likes_item_big_profile_img img {
    width: 34px;
    height: 34px;
    display: block;
  }

  .c3lh_your_likes_item_big_profile_name {
    font-size: 12px;
    margin-bottom: 2px;
  }

  .c3lh_your_likes_item_big_profile {
    padding-left: 23px;
  }

  .c3lh_your_likes_item_big_back_bottom {
    height: 36px;
  }

  .c3lh_your_likes_item_big_back_b_item {
    width: 43px;
    height: 32px;
    -webkit-background-size: 20px;
    background-size: 20px;
  }

  .c3lh_your_likes_item_big_back_chat_btn {
    width: 86px;
    height: 26px;
    line-height: 26px;
  }

  .c3lh_your_likes_profile_photo img {
    width: 34px;
    height: 34px;
  }

  .c3lh_your_likes_profile_head_name {
    font-size: 12px;
    font-weight: 500;
    color: $defaultTextColor;
    margin: 1px 0 2px;
  }

  .c3lh_your_likes_profile_head_id {
    font-size: 10px;
  }

  .c3lh_your_likes_profile_head_place {
    font-size: 10px;

  }

  .c3lh_your_likes_profile_head_place img {
    height: 10px;
  }

  .c3lh_your_likes_profile_head {
    margin-bottom: 10px;
  }

  .c3lh_your_likes_profile_content_title {
    font-size: 14px;
  }

  .c3lh_your_likes_profile_content_block ul li {
    font-size: 12px;
    margin-bottom: 6px;
    padding-left: 10px;
  }

  .c3lh_your_likes_profile_content {
    height: calc(100% - 44px);
  }

  .c3lh_your_likes_profile_content_hobby_item {
    word-break: break-all;
    font-size: 12px;
    height: auto;
    padding: 6px 10px 4px;
    line-height: 1.3;
  }

  .c3lh_your_likes_profile_content_block p {
    font-size: 14px;
  }

  .c3lh_your_likes_video_item {
    height: 119px;
  }

  .c3lh_your_likes_video_item:after {
    width: 45px;
    height: 45px;
  }

  .c3lh_your_likes_video_title {
    font-size: 14px;
  }

  .c3lh_your_likes_video_list_wrap {
    height: calc(100% - 75px);
    margin-top: 10px;
  }

  .c3lh_ylibmw .c3_textaera_wrap {
    height: 100px;
  }

  .c3lh_your_likes_item_big_back_chat_btn img {
    margin-right: 4px;
    width: 15px;
  }

  .c3lh_your_likes_item_big_main.video {
    padding-top: 10px;
  }

  .popup_likeher_sendgift_head {
    font-size: 16px;
  }

  .popup_likeher_sendgift_close img {
    width: 15px;
  }

  .popup_likeher_sendgift_item_name {
    font-size: 16px;
  }

  .popup_likeher_search_photos_big_photo_chat_wrap {
    height: 146px;
    padding: 0 10px 18px;
    width: 100%;
    margin: 0;
  }

  .popup_likeher_search_photos_big_photo_chat_btn {
    width: 145px;
    height: 33px;
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn img {
    width: 17px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn svg {
    width: 20px;
    height: 20px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn {
    width: 120px;
    height: 33px;
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn img {
    width: 17px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn svg {
    width: 18px;
    height: 18px;
  }

  .popup_likeher_search_photos_next,
  .popup_likeher_search_photos_prev {
    width: 40px;
    height: 60px;
    background-size: 25px;
  }

  .popup_likeher_search_photos_close {
    right: 5px;
    top: 5px;
    width: 50px;
    height: 50px;
  }

  .popup_likeher_photo_unpaid_warning {
    font-size: 12px;
  }

  .popup_likeher_photo_unpaid_warning span {
    font-size: 14px;
  }

  /*.ReactModal__Content .popup_likeher_search_photos_big_photo{*/
  /*	height: 100%;*/
  /*	width: 100%;*/
  /*	max-width: calc(100vw - 30px);*/
  /*	max-height: calc(100vh - 30px);*/
  /*	max-height: calc(calc(var(--vh, 1vh) * 100) - 30px);*/
  /*	display: flex;*/
  /*	justify-content: center;*/
  /*	align-items: center;*/
  /*}*/

  /*.ReactModal__Content .popup_likeher_search_photos_big_photo > img{*/
  /*	width: 100%;*/
  /*	height: 100%;*/
  /*	max-width: unset;*/
  /*	object-fit: contain;*/
  /*}*/

  .c3lh_top_return_btn span {
    display: none;
  }

  .c3lh_top_return_btn {
    width: 86px;
    font-size: 12px;
  }

  .c3lh_top_empty {
    width: 74px;
    display: none;
  }

  .c3lh_top_title {
    font-size: 22px;
    width: 100%;
    text-align: center;
  }

  .c3lh_top {
    height: auto;
    padding-top: 16px;
    padding-bottom: 0px;
    padding-right: 7px;
  }

  .c3lh_your_likes_item_back_top_item:hover {
    background-color: transparent;
    box-shadow: unset;
    z-index: 999;
  }

  .active_likehistory_hint:hover span {
    display: none;
  }

  .cl2h_tab_wide_filter::-webkit-scrollbar {
    width: 3px;
    background: $whiteColor;
    border-radius: 3px;
  }

  .cl2h_tab_wide_filter::-webkit-scrollbar-thumb {
    background: $scrollbarThumbColor;
    border-radius: 3px;
  }

  .c2lh_all_likes_btn_wrap {
    height: auto;
    padding: 10px 0;
  }

  .c2lh_all_likes_btn {
    margin-top: 0;
  }


  .c3lh_your_likes_item {
    height: auto;
    padding-bottom: 65px;
  }

  .c3lh_your_likes_item_back {
    width: 100%;
    height: 100%;
    bottom: unset;
    top: 0;
    justify-content: flex-end;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  .c3lh_your_likes_item_main {
    width: 100%;
    padding-top: 168%;
    height: auto;
  }

  .c3lh_your_likes_item_big_photo {
    height: calc(100% - 3px);
    width: calc(100% - 2px);
    position: absolute;
    left: 1px;
    top: 1px;
  }

  .c3lh_your_likes_item_back_bottom {
    flex-wrap: wrap;
    height: 65px;
    padding: 4px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture {
    display: none;
  }

  .c3lh_your_likes_item_back_b_item {
    width: 26px;
    height: 26px;
    -webkit-background-size: 16px auto;
    background-size: 16px auto;
  }

  .c3lh_your_likes_item_back_b_left {
    width: 50%;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top {
    width: calc(50% - 4px);
    position: absolute;
    bottom: 35px;
    right: 4px;
    display: flex;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top_item_wrap:nth-child(2) {
    display: none;
  }

  .c3lh_your_likes_item_back_top_item {
    padding: 0;
    position: static;
  }

  .c3lh_your_likes_item_back_top_item_wrap {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c3lh_your_likes_item_back_top_item_wrap:first-child {
    background-image: url(../../img/c3lh-item-mail-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .c3lh_your_likes_item_back_top_item_wrap:last-child {
    background-image: url(../../img/c3lh-item-like-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .c3lh_your_likes_item_back_top_item_wrap.liked:last-child {
    background-image: url(../../img/c3lh-item-like-mob-liked.svg);
  }

  .c3lh_your_likes_item_back_top_item img {
    width: 16px;
    display: none;
  }

  .c3lh_your_likes_item_back_chat_btn {
    width: 100%;
  }

  .c3lh_your_likes_item_profile {
    background: linear-gradient(to top, $whiteColor 0%, $transparentColor 100%);
    padding-bottom: 6px;
  }

  .c3lh_your_likes_item_profile_name {
    font-size: 12px;
  }

  .c3lh_your_likes_item_profile_online {
    font-size: 10px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn.active {
    height: 40px;
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
  .c3lh_your_likes_wrap {
    display: flex;
    padding: 13px 8px 0 13px;
  }

  .need_confirm_email .c3lh_your_likes_wrap {
    padding-top: 0;
  }

  .cl2h_tabs_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .c3lh_search_top {
    width: 30%;
    height: auto;
    padding-right: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 0;
    padding: 0;
    overflow: hidden;
  }

  .c3lh_search_range_title {
    width: auto;
    text-align: center;
    margin-right: 6px;
    text-transform: capitalize;
  }

  .c3lh_search_range_wrap {
    justify-content: center;
    margin-bottom: 16px;
  }

  .c3lh_search_range_selects_wrap {
    margin: 0;
  }

  .c3lh_search_filters_btn_wrap {
    width: 190px;
    margin: 0 auto;
  }

  .c3lh_search_filters_btn_wrap span {
    display: inline;
  }

  .c3lh_search_filter_item_online_status_inp {
    width: 100%;
    margin: 16px 0;
  }

  .c3lh_search_filter_item_online_status_inp_text {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .c3lh_search_id_wrap input {
    width: 100%;
  }

  .need_confirm_email .c3lh_your_likes_list_wrap2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .c3lh_your_likes_list_wrap2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-right: 5px;
    padding-bottom: 16px;
    width: 100%;
  }

  .c3lh_your_likes_item {
    height: auto;
    padding-bottom: 65px;
  }

  .c3lh_your_likes_item_back {
    width: 100%;
    height: 100%;
    bottom: unset;
    top: 0;
    justify-content: flex-end;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  .c3lh_your_likes_item_main {
    width: 100%;
    padding-top: 168%;
    height: auto;
  }

  .c3lh_your_likes_item_big_photo {
    height: calc(100% - 3px);
    width: calc(100% - 2px);
    position: absolute;
    left: 1px;
    top: 1px;
  }

  .c3lh_your_likes_item_back_bottom {
    flex-wrap: wrap;
    height: 65px;
    padding: 4px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture {
    display: none;
  }

  .c3lh_your_likes_item_back_b_item {
    width: 26px;
    height: 26px;
    -webkit-background-size: 16px auto;
    background-size: 16px auto;
  }

  .c3lh_your_likes_item_back_b_left {
    width: 50%;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top {
    width: calc(50% - 4px);
    position: absolute;
    bottom: 35px;
    right: 4px;
    display: flex;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top_item_wrap:nth-child(2) {
    display: none;
  }

  .c3lh_your_likes_item_back_top_item {
    padding: 0;
    position: static;
  }

  .c3lh_your_likes_item_back_top_item_wrap {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c3lh_your_likes_item_back_top_item_wrap:first-child {
    background-image: url(../../img/c3lh-item-mail-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .c3lh_your_likes_item_back_top_item_wrap:last-child {
    background-image: url(../../img/c3lh-item-like-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .c3lh_your_likes_item_back_top_item_wrap.liked:last-child {
    background-image: url(../../img/c3lh-item-like-mob-liked.svg);
  }

  .c3lh_your_likes_item_back_top_item img {
    width: 16px;
    display: none;
  }

  .c3lh_your_likes_item_back_chat_btn {
    width: 100%;
  }

  .c3lh_your_likes_item_profile {
    background: linear-gradient(to top, $whiteColor 0%, $transparentColor 100%);
    padding-bottom: 6px;
  }

  .c3lh_your_likes_item_profile_name {
    font-size: 12px;
  }

  .c3lh_your_likes_item_profile_online {
    font-size: 10px;
  }

  .column-2.likeher.active {
    z-index: 999;
    left: 50px;
  }
}

@media screen and (max-width: 789px) and (max-height: 450px) {
  .c3lh_your_likes_item {
    height: auto;
    padding-bottom: 65px;
  }

  .c3lh_your_likes_item_back {
    width: 100%;
    height: 100%;
    bottom: unset;
    top: 0;
    justify-content: flex-end;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  .c3lh_your_likes_item_main {
    width: 100%;
    padding-top: 168%;
    height: auto;
  }

  .c3lh_your_likes_item_big_photo {
    height: calc(100% - 3px);
    width: calc(100% - 2px);
    position: absolute;
    left: 1px;
    top: 1px;
  }

  .c3lh_your_likes_item_back_bottom {
    flex-wrap: wrap;
    height: 65px;
    padding: 4px;
  }

  .c3lh_your_likes_item_back_b_item.item_picture {
    display: none;
  }

  .c3lh_your_likes_item_back_b_item {
    width: 26px;
    height: 26px;
    -webkit-background-size: 16px auto;
    background-size: 16px auto;
  }

  .c3lh_your_likes_item_back_b_left {
    width: 50%;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top {
    width: calc(50% - 4px);
    position: absolute;
    bottom: 35px;
    right: 4px;
    display: flex;
    justify-content: space-around;
  }

  .c3lh_your_likes_item_back_top_item_wrap:nth-child(2) {
    display: none;
  }

  .c3lh_your_likes_item_back_top_item {
    padding: 0;
    position: static;
  }

  .c3lh_your_likes_item_back_top_item_wrap {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c3lh_your_likes_item_back_top_item_wrap:first-child {
    background-image: url(../../img/c3lh-item-mail-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .c3lh_your_likes_item_back_top_item_wrap:last-child {
    background-image: url(../../img/c3lh-item-like-mob.svg);
    background-size: 16px auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .c3lh_your_likes_item_back_top_item_wrap.liked:last-child {
    background-image: url(../../img/c3lh-item-like-mob-liked.svg);
  }

  .c3lh_your_likes_item_back_top_item img {
    width: 16px;
    display: none;
  }

  .c3lh_your_likes_item_back_chat_btn {
    width: 100%;
  }

  .c3lh_your_likes_item_profile {
    background: linear-gradient(to top, $whiteColor 0%, $transparentColor 100%);
    padding-bottom: 6px;
  }

  .c3lh_your_likes_item_profile_name {
    font-size: 12px;
  }

  .c3lh_your_likes_item_profile_online {
    font-size: 10px;
  }

  .popup_likeher_search_photos_big_photo_chat_wrap {
    height: 146px;
    padding-bottom: 18px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn {
    width: 137px;
    height: 33px;
    font-size: 14px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn img {
    width: 19px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn svg {
    width: 22px;
    height: 22px;
  }

  .popup_likeher_search_photos_big_photo > img {
    width: unset;
    height: unset;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 20px);
  }

  .popup_likeher_search_photos_next,
  .popup_likeher_search_photos_prev {
    width: 40px;
    height: 60px;
    background-size: 25px;
    transform: translateY(-50%);
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn {
    font-size: 12px;
    width: 110px;
    margin-left: 5px;
  }

  .popup_likeher_search_photos_big_photo_hello_btn_wrap .popup_likeher_search_photos_big_photo_profile_btn.active {
    height: 40px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 500px) and (max-height: 450px) {
  .popup_likeher_search_photos_list_wrap {
    display: none;
  }

  .popup_likeher_search_photos_close {
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    bottom: unset;
    transform: unset;
  }

  .popup_likeher_search_photos_big_photo_chat_wrap {
    padding-left: 10px;
  }

  .popup_likeher_search_photos_big_photo > img {
    max-height: calc(100vh - 20px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 20px);
  }

  .popup_likeher_search_photos_big_photo_profile_btn {
    width: 120px;
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn img {
    width: 15px;
    margin-right: 6px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn {
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_chat_btn img {
    width: 15px;
    margin-right: 6px;
  }

  .popup_likeher_search_photos_big_photo.unpaid {
    width: 500px;
  }
}

@media screen and (max-width: 699px) and (max-height: 450px) and (min-width: 420px) {
  .c3lh_search_filters_btn_wrap {
    width: 100%;
  }

  .c3lh_search_filters_btn_wrap img {
    width: 16px;
  }

  .c3lh_search_filters_btn_wrap {
    font-size: 12px;
  }

  .c3lh_search_filters_btn_wrap b {
    right: 5px;
  }
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
  .c3lh_your_likes_list_wrap2 {
    padding: 0;
  }

  .c3lh_your_likes_wrap {
    padding-left: 0;
    padding-right: 5px;
  }

  .popup_likeher_search_photos_big_photo_profile_btn {
    margin-left: 0;
  }

  .popup_likeher_search_photos_big_photo_chat_btn img,
  .popup_likeher_search_photos_big_photo_chat_btn svg,
  .popup_likeher_search_photos_big_photo_profile_btn img,
  .popup_likeher_search_photos_big_photo_profile_btn svg {
    display: none;
  }

  .popup_likeher_search_photos_big_photo_chat_btn,
  .popup_likeher_search_photos_big_photo_profile_btn {
    width: calc(50% - 3px);
    font-size: 12px;
  }

  .popup_likeher_search_photos_big_photo_chat_wrap {
    padding: 0 5px 5px;
    justify-content: space-between;
  }
}

.popup_likeher_search_photos_big_photo_profile_btn.like.active {
  background-color: #446077;
  border-color: #446077;
  color: #fff;
  box-shadow: none;
  cursor: default;
}

.cl2h_tabs_wrap .fill_data_form_select_list .fill_data_form_select_item_wrap:not(:first-child) .fill_data_form_select_item_name {
  padding-top: 4px;
}

.cl2h_tabs_wrap .fill_data_form_select_list .fill_data_form_select_item_name {
  padding-bottom: 2px;
  padding-top: 4px;
  @media screen and (max-width: 796px) {
    font-size: 12px;
    line-height: 18px;
  }
}