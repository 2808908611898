@import "@sitestyles";

.c3mp_videos_page_wrap {
  padding-left: 30px;
  padding-right: 12px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.c3mp_videos_list_wrap {
  padding-right: 15px;
  //height: calc(100vh - 70px);
  //height: calc(calc(var(--vh, 1vh) * 100) - 70px);
  overflow-y: auto;
  padding-bottom: 40px;
  flex: 1;
}

.need_confirm_email .c3mp_videos_list_wrap {
  //height: calc(100vh - 150px);
  //height: calc(calc(var(--vh, 1vh) * 100) - 150px);
}

.c3mp_videos_list_wrap::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 5px;
}

.c3mp_videos_list_wrap::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.c3_video_empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c3_video_empty_img {
  max-width: 98%;
  max-height: 40%;
}

.c3_video_empty_img.sweetheart {
  margin-bottom: 20px;
}

.popup_compose_letter .c3_video_empty_img {
  width: 98%;
  max-width: 400px;
  max-height: 250px;
}

.c3_video_empty_title {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #426076;
  margin-bottom: 12px;
}

.c3_video_empty_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  color: #8A9BA9;
}

.c3_video_empty_text span {
  cursor: pointer;
  color: #2a4053;
}

.c3_video_empty_clear_btn {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00317B;
  border-radius: 4px;
  background-color: #fff;

  font-weight: 600;
  font-size: 14px;
  color: #00317B;
  margin: 26px auto 0;
  cursor: pointer;
}

.c3mp_video_list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, 424px);
  grid-gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 11px;
}

.c3mp_video_list_item {
  background-color: #fff;
  box-shadow: 0px 3px 5px rgba(197, 196, 208, 0.64);
  border-radius: 10px;
  position: relative;
}

.c3mp_video_list_item_img_wrap {
  position: relative;
  cursor: pointer;
  /*height: 248px;*/
}

.c3mp_videos_page_wrap .video_aspect_ratio_container {
  padding-top: 56%;
}

@media screen and (min-width: 1500px) {
  .video_aspect_ratio_container.auto-open-woman-profile .c3mp_video_list_item_img {
    width: 1480px;
    height: 833px;
  }
}

@media screen and (max-width: 1500px) {
  .video_aspect_ratio_container.auto-open-woman-profile .c3mp_video_list_item_img {
    width: 990px;
    height: 550px;
  }
}

@media screen and (max-width: 1000px) {
  .video_aspect_ratio_container.auto-open-woman-profile .c3mp_video_list_item_img {
    width: 573px;
    height: 322px;
  }
}

@media screen and (max-width: 600px) {
  .video_aspect_ratio_container.auto-open-woman-profile .c3mp_video_list_item_img {
    width: 395px;
    height: 221px;
  }
}

@media screen and (max-width: 400px) {
  .video_aspect_ratio_container.auto-open-woman-profile .c3mp_video_list_item_img {
    width: 320px;
    height: 177px;
  }
}


.c3mp_video_list_item_img {
  width: 100%;
  height: 239px;
  display: block;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
  object-position: bottom;
}

.c3mp_videos_page_wrap .c3mp_video_list_item_img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
  object-position: bottom;
  position: absolute;
  left: 0;
  top: 0;
}

.c3mp_video_list_item_play_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.c3mp_video_list_item_play_btn svg {
  display: block;
  width: 80px;
  height: 80px;
  fill: #FFFFFF;
  filter: drop-shadow(0px 0 3px rgba(0, 0, 0, 0.4))
}

.c3mp_video_list_item_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  height: 66px;
  padding: 0 16px;
}

.c3mp_video_list_item_profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.c3mp_video_list_item_profile_img {
  width: 34px;
  height: 34px;
  position: relative;
  z-index: 1;
  margin-right: 7px;
}

.c3mp_video_list_item_profile_img img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
}

.c3mp_video_list_item_profile_img svg {
  width: 16px;
  height: 16px;
  fill: #c3ccd6;
  position: absolute;
  top: 0;
  left: 3%;
  transform: translate(50%, 50%);
  margin: 0;
}

.c3mp_video_list_item_profile_img:after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 1px;
  right: 1px;
  background-color: #ccc;
}

.c3mp_video_list_item_profile_img:has(svg):before {
  content: '';
  width: 34px;
  height: 34px;
  background-color: #fafaff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.c3mp_video_list_item_profile_img.online:after {
  background-color: #27AE60;
}

.c3mp_video_list_item_profile_info {

}

.c3mp_video_list_item_profile_id {
  font-size: 10px;
  color: #333333;
  opacity: 0.5;
}

.c3mp_video_list_item_profile_name {
  font-size: 12px;
  font-weight: 500;
  color: #333;
}

.c3mp_video_list_item_profile_place {
  font-size: 12px;
  font-weight: 500;
  color: #999;
}

.c3mp_video_list_item_profile_place img {
  height: 8px;
  vertical-align: middle;
  margin-right: 5px;
}

.c3mp_video_list_item_buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.c3mp_video_list_item_gifts span {
  display: none;
}

.c3mp_video_list_item_bottom_chat {
  //width: 86px;
  padding: 0 10px;
  height: 30px;
  background: linear-gradient(90.42deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c3mp_video_list_item_bottom_chat:hover {
  box-shadow: $smallBtnShadowHover;
}

.c3mp_video_list_item_bottom_chat img {
  width: 15px;
  margin-right: 8px;
}

.c3mp_video_list_item_bottom_chat svg {
  width: 18px;
  height: 18px;
  fill: #FFFFFF;
  margin-right: 6px;
}

.c3mp_video_list_item_bottom_all_btn {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color .3s;
  border-radius: 4px;
}

.c3mp_video_list_item_bottom_all_btn:last-child {
  margin-left: 0;
}

.c3mp_video_list_item_bottom_all_btn:hover {
  background-color: $buttonHoverBg2;
}

.c3lh_your_likes_item_back_b_item_info {
  font-size: 10px;
  color: $videoButtonsColor;
}

.c3mp_video_list_item_bottom_all_btn svg {
  width: 18px;
  height: 18px;
  fill: $iconLetterVideoComponentFillColor;
  display: block;
}

.c3mp_video_list_item_bottom_all_btn.liked svg,
.c3mp_video_list_item_bottom_all_btn.like svg {
  width: 18px;
  height: 18px;
  fill: $iconLikeVideoComponentFillColor;
  display: block;
}

.c3mp_video_list_item_free {
  width: 100%;
  height: 30px;
  padding-top: 6px;
  padding-left: 9px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px 10px 0px 0px;
  font-size: 10px;
  color: rgba(51, 51, 51, 1);
  display: none;
}

.popup_mp_videos {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_mp_videos:before {
  content: '';
  display: block;
  width: 100vw;
  height: 100%;
  background: rgba(55, 78, 108, 0.32);
  position: fixed;
  left: 0;
  top: 0;
}

.popup_mp_videos_wrap {
  position: relative;
  z-index: 9999;
  border-radius: 10px;
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.popup_mp_videos_wrap iframe {
  /*background-color: #000;*/
}

.popup_mp_video_temp {
  width: 1710px;
  max-width: calc(100vw - 30px);
  height: 963px;
  max-height: calc(100vh - 90px);
  max-height: calc(calc(var(--vh, 1vh) * 100) - 90px);
  border-radius: 10px 10px 0 0;
}

.popup_mp_video_bottom {
  background-color: #fff;
  height: 70px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  padding: 0 24px;
  border-radius: 0 0 10px 10px;
}

.popup_mp_video_profile {
  display: flex;
  align-items: center;
  //margin-right: auto;
}

.popup_mp_video_profile_img {
  position: relative;
  margin-right: 7px;
}

.popup_mp_video_profile_img img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
  display: block;
}

.popup_mp_video_profile_img:after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 1px;
  right: 1px;
  background-color: #ccc;
}

.popup_mp_video_profile_img.online:after {
  background-color: #27AE60;
}

.popup_mp_video_profile_name {
  font-weight: 500;
  color: #333;
  margin-bottom: 2px;
  text-align: left;
}

.popup_mp_video_profile_info_bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.popup_mp_video_profile_info_id {
  font-size: 10px;
  opacity: .5;
  color: #333;
  margin-right: 10px;
}


.popup_mp_video_profile_info_place {
  color: #999;
  font-size: 10px;
  font-weight: 500;
}

.popup_mp_video_profile_info_place img {
  height: 8px;
  margin-right: 5px;
  vertical-align: middle;
}

.popup_mp_video_chat {
  width: 342px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(90.42deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.popup_mp_video_chat img {
  width: 19px;
  margin-right: 10px;
}

.popup_mp_video_chat svg {
  width: 24px;
  height: 24px;
  fill: #FFFFFF;
  margin-right: 10px;
}

.popup_mp_video_buttons {
  display: flex;
  align-items: center;
}

.popup_mp_video_buttons_all {
  height: 42px;
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color .3s;
  font-size: 14px;
  color: $videoButtonsColor;
  border-radius: 4px;
}

.popup_mp_video_buttons_all:hover {
  background-color: $buttonHoverBg2;
}

.popup_mp_video_buttons_all img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 9px;
}

.popup_mp_video_buttons_all svg {
  width: 28px;
  height: 28px;
  margin-right: 9px;
  fill: $videoButtonsColor;
  vertical-align: middle;
}

.popup_mp_video_buttons_mobile_open {
  display: none;
}

.popup_mp_videos_close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99999;
  cursor: pointer;
  background: url('../img/pap-btn-close.svg') center no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.c3lh_your_likes_video_item iframe {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
}

.popup_mp_video_chat_mobile_text {
  display: none;
}

.popup_mp_video_temp {
  height: unset;
  max-height: unset;
  flex: 1;
  margin-bottom: 16px;
  width: 100%;
  max-width: unset;
}

.c3lh_your_likes_video_item iframe {
  border-radius: 10px;
}

.popup_mp_video_bottom {
  border-radius: 10px;
  flex: none;
}

.c3lh_your_likes_video_item {
  height: 100%;
}

.video_show_list_cost {
  width: 100%;
  //height: 30px;
  //position: absolute;
  //left: 0;
  //bottom: 0;
  //padding-top: 56px;
  font-weight: 600;
  font-size: 12px;
  color: #00317B;
  text-align: center;
  pointer-events: none;
  margin-bottom: 12px;
}

.video_show_list_cost span {
  position: relative;
}

.video_show_list_cost svg {
  vertical-align: bottom;
  margin-right: 8px;
  fill: #01327a;
  stroke: #01327a;
  stroke-width: 0.4;
  width: 16px;
  height: 16px;
}

.video_show_list_cost_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #F4F4F4 100%);
}

.c3_woman_profile_page_title {
}

.c3_woman_profile_page_title_cost {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.6);
  display: flex;
  align-items: center;
  text-transform: none;
}

.c3_woman_profile_page_title_cost img {
  margin-right: 8px;
}

.c3_woman_profile_page_title_cost svg {
  margin-right: 8px;
}

@media screen and (min-width: 1921px) {
  .c3mp_video_list {
    grid-template-columns: repeat(auto-fill, 630px);
    grid-gap: 30px;
  }

  .c3mp_video_list_item_bottom {
    height: 99px;
  }

  .c3mp_video_list_item_profile_img {
    margin-right: 14px;
    width: 50px;
    height: 50px;
  }

  .c3mp_video_list_item_profile_img:has(svg):before {
    width: 50px;
    height: 50px;
  }

  .c3mp_video_list_item_profile_img svg {
    width: 24px;
    height: 24px;
  }

  .c3mp_video_list_item_profile_img img {
    width: 50px;
    height: 50px;
  }

  .c3mp_video_list_item_profile_img:after {
    width: 8px;
    height: 8px;
    border-width: 2px;
    right: 3px;
    bottom: 3px;
  }

  .c3mp_video_list_item_profile_name {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .c3mp_video_list_item_profile_place img {
    height: 14px;
    margin-right: 8px;
  }

  .c3mp_video_list_item_profile_place {
    font-size: 14px;
  }

  .c3mp_video_list_item_free {
    height: 75px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    padding-top: 10px;
    font-size: 18px;
  }

  .c3mp_video_list_item_play_btn img {
    width: 110px;
  }

  .c3mp_video_list_item_play_btn svg {
    width: 110px;
    height: 110px;
  }

  .c3mp_video_list_item_bottom_all_btn svg {
    margin-right: 6px;
  }
}

@media screen and (min-width: 3200px) {
  .c3mp_video_list {
    grid-template-columns: repeat(auto-fill, 1020px);
  }

  .c3mp_video_list_item_bottom {
    height: 159px;
  }

  .c3mp_video_list_item_profile_img {
    margin-right: 22px;
  }

  .c3mp_video_list_item_profile_img img {
    width: 104px;
    height: 104px;
  }

  .c3mp_video_list_item_profile_img:after {
    width: 20px;
    height: 20px;
    border-width: 3px;
    right: 4px;
    bottom: 4px;
  }

  .c3mp_video_list_item_profile_name {
    font-size: 30px;
    margin-bottom: 8px;
  }

  .c3mp_video_list_item_profile_place img {
    height: 22px;
    margin-right: 12px;
  }

  .c3mp_video_list_item_profile_place {
    font-size: 21px;
  }

  .c3mp_video_list_item_free {
    height: 120px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    padding-top: 16px;
    font-size: 24px;
  }

  .c3mp_video_list_item_play_btn img {
    width: 180px;
  }

  .c3mp_video_list_item_play_btn svg {
    width: 180px;
    height: 180px;
  }

  .c3mp_video_list_item_bottom_all_btn svg {
    margin-right: 6px;
  }
}

@media screen and (max-width: 1899px) {
  .c3mp_video_list {
    grid-template-columns: repeat(2, 1fr);
  }

  .c3mp_video_list_item_bottom_all_btn svg {
    margin-right: 6px;
  }
}

@media screen and (max-width: 1549px) {
  .c2_list .c3_video_empty_text {
    font-size: 14px;
  }

  .c3_woman_profile_page_title_cost {
    font-size: 10px;
  }

  .c3_woman_profile_page_title_cost img {
    width: 12px;
  }

  .c3_woman_profile_page_title_cost svg {
    width: 16px;
    height: 16px;
  }

  .c3mp_video_list_item_bottom_chat {
    //width: 67px;
    height: 34px;
  }

  .c3mp_video_list_item_bottom_all_btn {
    width: 48px;
    height: 40px;
    padding: 0;
    flex-direction: column;
  }

  .c3mp_video_list_item_bottom_all_btn img {
    width: 16px;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .c3lh_your_likes_item_back_b_item_info {
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .c3mp_video_list_item_bottom_all_btn svg {
    margin-right: 0;
  }
}

@media screen and (max-width: 1349px) {
  .c3mp_video_list {
    grid-template-columns: repeat(auto-fill, 384px);
  }

  .c3mp_video_list_item_img {
    height: 216px;
  }
}

@media screen and (max-width: 1299px) {
  .c3mp_videos_list_wrap {
    //height: calc(100vh - 70px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 70px);
  }

  .need_confirm_email .c3mp_videos_list_wrap {
    //height: calc(100vh - 150px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  }
}

@media screen and (max-width: 1249px) {
  .c3mp_video_list {
    grid-template-columns: repeat(2, 1fr);
  }

  .c3mp_video_list_item_img {
    height: 163px;
  }

  .c3mp_video_list_item_play_btn img {
    width: 55px;
  }

  .c3mp_video_list_item_play_btn svg {
    width: 55px;
    height: 55px;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 768px) {
    .c3mp_videos_page_wrap {
      padding-left: 20px;
      padding-right: 10px;
    }

    .c3mp_videos_list_wrap {
      padding-right: 10px;
    }

    .c3mp_video_list {
      grid-gap: 12px;
      padding-top: 0;
    }
  }

  @media screen and (min-width: 1140px) {
    .c3mp_videos_page_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }
    .c3mp_videos_list_wrap {
      height: auto;
      flex: 1;
      padding-bottom: 16px;
      padding-top: 0;
      margin-top: 20px;
    }

    .need_confirm_email .c3mp_videos_list_wrap {
      height: auto;
    }

    .c3mp_video_list {
      padding-top: 16px;
    }

    //.popup_mp_videos_close {
    //  width: 60px;
    //  height: 60px;
    //  right: 50%;
    //  top: unset;
    //  bottom: 12px;
    //  transform: translateX(50%);
    //  background-image: url(../img/icon_exit.svg);
    //  background-repeat: no-repeat;
    //  background-position: center;
    //}

    .c3mp_video_list + .c3_video_empty {
      height: calc(100% - 16px);
    }
  }
}

@media screen and (max-width: 1400px) {
  .video_show_list_cost {
    margin-bottom: 0;
  }
  .c3mp_videos_page_wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  .c3mp_videos_list_wrap {
    height: auto;
    flex: 1;
    padding-bottom: 16px;
    padding-top: 0;
    margin-top: 10px;
  }

  .need_confirm_email .c3mp_videos_list_wrap {
    height: auto;
  }

  .c3mp_video_list {
    padding-top: 0;
  }

  .popup_mp_videos {
    flex-direction: column;
  }

  .popup_mp_videos_wrap {
    //height: 100vh;
    //max-height: calc(100vh - 130px);
    //max-width: calc(100vw - 130px);
  }

  .popup_mp_videos_close {
    width: 60px;
    height: 60px;
    top: 16px;
    right: 0;
    background-image: url(../img/icon_exit.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .popup_mp_video_buttons_all {
    height: 36px;
    font-size: 12px;
    margin-left: 5px;
  }

  .popup_mp_video_buttons_all img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .popup_mp_video_buttons_all svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .popup_mp_video_chat {
    width: 300px;
  }

  .c3mp_video_list_item_bottom_chat img,
  .c3mp_video_list_item_bottom_chat svg {
    display: none;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media (orientation: portrait) {

    .popup_mp_videos {
      //width: 100vw;
    }

    .popup_mp_videos_wrap {
      //width: 100%;
      justify-content: center;
      //max-width: unset;
    }

    .popup_mp_video_temp {
      max-height: unset;
      height: unset;
      width: 100%;
      flex: unset;
    }

    .c3lh_your_likes_video_item {
      height: unset;
      padding-top: 56%;
      position: relative;
      margin-bottom: 0;
    }

    .c3lh_your_likes_video_item iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    .popup_mp_video_bottom {
      border-radius: 6px;
    }
  }

  @media (orientation: landscape) {
    .popup_mp_videos_wrap {
      //height: 100%;
      //max-width: calc(100% - 70px);
      //max-height: unset;
      //display: block;
    }

    .popup_mp_video_temp {
      height: calc(100% - 82px);
      margin-bottom: 22px;
    }

    .popup_mp_video_temp {
      width: 100%;
    }

    .popup_mp_videos {
      justify-content: space-between;
    }

    .popup_mp_videos_close {
      //position: static;
      //transform: unset;
    }

    .popup_mp_video_bottom {
      border-radius: 6px;
    }

    .c3lh_your_likes_video_item iframe {
      border-radius: 6px;
    }

    @media screen and (max-height: 600px) {
      .popup_mp_video_temp {
        margin-bottom: 12px;
      }
    }

    @media screen and (max-width: 599px) {
      .popup_mp_video_temp {
        height: calc(100% - 65px);
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .popup_mp_video_buttons_mobile_open {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 4px;
    background-image: url(../img/dots-triple.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .popup_mp_video_buttons_mobile_open.active {
    background-color: #E9EBEE;
  }

  .popup_mp_video_bottom {
    position: relative;
    padding-right: 17px;
  }

  .popup_mp_video_buttons {
    position: absolute;
    right: 19px;
    bottom: calc(100% - 4px);
    background-color: #fff;
    display: none;
    border: 1px solid #E9EBEE;
    box-shadow: $searchItemLikeBtnShadowHover;
    border-radius: 5px;
  }
  .popup_mp_video_buttons.active {
    display: block
  }

  .popup_mp_video_buttons:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    right: 9px;
    bottom: -8px;
    background: linear-gradient(135deg, transparent, transparent 50%, #E9EBEE 50%, #E9EBEE);
    transform: rotate(45deg);
  }

  .popup_mp_video_buttons:after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    position: absolute;
    right: 10px;
    bottom: -7px;
    background-color: #fff;
    transform: rotate(45deg);
  }

  .popup_mp_video_buttons_all {
    width: 150px;
    margin: 0 18px;
    justify-content: flex-start;
    height: 45px;
    border-bottom: 1px solid #E9EBEE;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.06em;
    color: #1C2677;
  }

  .popup_mp_video_buttons_all:last-child {
    border-bottom: none;
  }

  .popup_mp_video_buttons_all img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
  }

  .popup_mp_video_buttons_all svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
  }

  .popup_mp_video_chat {
    width: 270px;
    height: 38px;
    margin-left: auto;
    margin-right: 10px;
    font-weight: 500;
  }

  .popup_mp_video_chat img {
    width: 16px;
    margin-right: 8px;
  }

  .popup_mp_video_chat svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .c3_woman_profile_page_title_cost {
    justify-content: center;
  }

  .c3_woman_profile_gallery_video_list_wrap {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .c3mp_video_list_item_buttons {
    align-items: stretch;
  }

  .c3mp_video_list_item_bottom_chat {
    //width: 60px;
    height: 34px;
  }

  .c3mp_video_list_item_bottom_all_btn img {
    margin-top: 0;
    margin-bottom: auto;
  }

  .c3lh_your_likes_item_back_b_item_info {
    margin-top: 2px;
    margin-bottom: 0;
  }

  .c3mp_video_list_item_bottom_all_btn {
    height: auto;
    width: 40px;
    margin-left: 5px;
  }

  .c3mp_video_list_item_bottom {
    padding: 0 7px;
  }
}

@media screen and (max-width: 699px) {
  .popup_mp_video_temp {
    border-radius: 10px;
    margin-bottom: 13px;
  }

  .c3mp_video_list {
    grid-gap: 16px;
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
    /*width: 59px;*/
  }

  .c3lh_search_filters_btn_wrap.active {
    margin-bottom: -8px;
  }

  .popup_mp_videos {
    flex-direction: column;
  }

  .popup_mp_videos_close {
    //position: unset;
    //margin-top: 16px;
    //transform: unset;
    z-index: 999;
    //background-size: contain
  }

  .popup_mp_videos_wrap {
    max-width: calc(100% - 30px);
    box-shadow: unset;
    height: auto;
  }

  .c3mp_videos_list_wrap {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 649px) {
  .c3mp_video_list {
    grid-template-columns: minmax(280px, 1fr);
    grid-gap: 8px;
    padding-top: 0;
  }
}

@media screen and (max-width: 599px) {
  .c3mp_videos_page_wrap {
    padding-left: 0;
    padding-right: 3px;
  }

  .c3mp_videos_list_wrap {
    padding-right: 7px;
    padding-left: 10px;
    padding-top: 0;
    margin-top: 8px;
  }

  .c3_video_empty_title {
    font-size: 18px;
  }

  .c3_video_empty_text {
    font-size: 14px;
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
    width: 49px;
    height: 30px;
  }

  .c3lh_search_filters_btn_wrap.active {
    margin-bottom: 0;
  }

  .c3mp_video_list_item_bottom {
    padding: 0 7px;
  }

  .c3_profile_gifts_wrap .c3_video_empty_img {
    max-width: 60%;
    margin-top: 50px;
  }

  .popup_mp_video_buttons_mobile_open {
    width: 34px;
    height: 34px;
  }

  .popup_mp_video_chat {
    width: 142px;
    height: 34px;
    margin-right: 3px;
  }

  .popup_mp_video_chat span span {
    display: none;
  }

  .popup_mp_video_profile_img {
    display: none;
  }

  .popup_mp_video_profile_info_id {
    display: none;
  }

  .popup_mp_video_bottom {
    height: 52px;
    padding: 0 7px 0 22px;
  }

  .popup_mp_video_buttons {
    right: 6px;
    bottom: unset;
    top: calc(100% + 0px);
  }

  .popup_mp_video_profile_name {
    font-size: 12px;
  }

  .popup_mp_video_buttons_all {
    margin: 0px 13px;
    width: 142px;
    height: 35px;
    font-size: 11px;
  }

  .popup_mp_video_buttons_all img {
    width: 18px;
    height: 18px;
  }

  .popup_mp_video_buttons_all svg {
    width: 18px;
    height: 18px;
  }

  .popup_mp_video_buttons:after {
    bottom: unset;
    top: -7px;
  }

  .popup_mp_video_buttons:before {
    bottom: unset;
    top: -8px;
    transform: rotate(-135deg);
  }

  //.popup_mp_videos_close {
  //  //bottom: 0px;
  //  //z-index: 999;
  //}

  .video_show_list_cost {
    font-size: 10px;
    padding-right: 30px;
    padding-left: 30px;
    line-height: 1.4;
    //padding-top: 32px;
    //height: 67px;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .video_show_list_cost svg {
    display: none;
  }

  .c3mp_video_list_item_bottom_all_btn {
    margin-left: 12px;
  }

  .c3mp_video_list_item_bottom_all_btn:last-child {
    margin-left: 8px;
  }

  .c3lh_your_likes_list_wrap2 .c3_video_empty {
    padding-left: 10px;
  }

  .c3lh_your_likes_list_wrap2 .c3_video_empty_text {
    padding: 0 10px;
  }

  .popup_compose_letter .c3_video_empty_img {
    max-width: 320px;
    max-height: 200px;
  }
}

@media screen and (max-width: 370px) {
  .c3mp_video_list_item_profile_place {
    display: flex;
    align-items: center;
  }
  .c3mp_video_list_item_profile_place span {
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 60px;
  }

  .c3mp_video_list_item_profile_name {
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 74px;
  }
}


@media screen and (max-width: 340px) {
  .popup_mp_video_chat {
    width: 90px;
  }

  .popup_mp_video_chat span {
    display: none;
  }

  .popup_mp_video_chat_mobile_text {
    display: block;
  }

  .c3mp_video_list_item_bottom_all_btn {
    margin-left: 5px;
  }

  .c3mp_video_list_item_bottom_all_btn:last-child {
    margin-left: 0px;
  }

  .c3mp_video_list_item_bottom_chat {
    padding: 0 8px;
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
  .c3mp_video_list_item_bottom {
    padding: 0 8px;
  }
  .c3mp_videos_page_wrap {
    display: flex;
    padding: 13px 8px 0 13px;
  }

  .need_confirm_email .c3mp_videos_page_wrap {
    padding-top: 0;
  }

  .c3mp_videos_list_wrap {
    //height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    padding: 0 12px 16px;
    width: 100%;
    margin-top: 10px;
  }

  .need_confirm_email .c3mp_videos_list_wrap {
    //height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
  }

  .c3mp_videos_page_wrap .c3lh_search_top {
    padding-right: 0px;
    margin-left: 0;
  }

  .c3lh_search_filters_list_wrap {
    width: 237px;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 50px;
    z-index: 5;
    max-height: unset;
    padding: 14px 5px 12px 10px;
    background-color: #EBEDFA;
    display: flex;
    flex-direction: column;
    -webkit-border-radius: 0 8px 0 0;
    -moz-border-radius: 0 8px 0 0;
    border-radius: 0 8px 0 0;
    margin: 0;
  }

  .need_confirm_email .c3lh_search_filters_list_wrap {
    max-height: unset;
    z-index: 999;
  }

  .c3lh_search_filters_list_wrap {
    z-index: 999;
  }

  .c3lh_search_filters_list_wrap2 {
    max-height: unset;
    grid-template-columns: repeat(1, 1fr);
    padding-right: 5px;
  }

  .need_confirm_email .c3lh_search_filters_list_wrap2 {
    max-height: unset;
  }

  .c3lh_search_filters_btn_wrap.active {
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    border-color: transparent;
    box-shadow: none;
    height: 37px;
    line-height: 37px;
    margin-left: auto;
    margin-right: -13px;
  }

  .c3mp_video_list {
    grid-template-columns: repeat(2, 1fr);
  }

  .c3lh_search_range_wrap .cl2h_tab_wide_filter_item_wrap {
    width: 49px;
    height: 24px;
  }

  .c3lh_search_range_wrap .like_select_head, .c3lh_search_range_wrap .like_select_head.selected {
    font-size: 12px;
    padding: 0 5px;
    text-align: center;
    height: 24px;
    line-height: 24px;
  }

  .c3lh_search_range_title span {
    display: none;
  }

  .c3lh_search_range_btn_confirm img {
    width: 18px;
  }

  .c3lh_search_filters_list_wrap .like_select_head {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 10px;
  }

  .c3lh_search_filters_list_wrap .like_select_list {
    background-color: #fff;
  }

  .c3lh_search_filter_item {
    margin-bottom: 16px;
  }

  .c3lh_search_filters_list_wrap2_close {
    display: block;
    position: fixed;
    cursor: pointer;
    padding: 5px;
    width: 22px;
    height: 22px;
    background-image: url(../img/cross_white.svg);
    background-size: contain;
    left: 256px;
    top: 64px;
  }

  .c3lh_search_filters_list_wrap2_close img {
    display: none;
  }

  .c3lh_search_filters_list_btn_find {
    margin: 0;
    width: 217px;
  }

  .c3lh_search_filters_list_mob_top {
    display: block;
  }
}

@media screen and (max-width: 799px) and (max-height: 450px) and (min-width: 420px) {
  .c3mp_video_list {
    grid-template-columns: 280px;
  }

  .c3mp_video_list_item_bottom {
    height: 54px;
  }
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
  .need_confirm_email .c3mp_videos_list_wrap {
    //height: calc(100vh - 92px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 92px);
  }
}

@media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
  .c3lh_search_range_wrap {
    width: 100%;
  }

  .c3lh_search_filters_btn_wrap {
    background: unset;
    padding-left: 0;
    width: 100%;
    height: 37px;
    background-color: #fff;
    border: 1px solid #446077;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    text-align: center;
    margin: 0;
  }

  .c3lh_search_filters_btn_wrap img {
    display: inline-block;
  }

  .c3lh_search_filters_btn_wrap:after {
    display: none;
  }

  /*.c3lh_search_filters_list_wrap{
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
  }*/

  .c3lh_search_filters_list_wrap2 {
    height: 100%;
  }

  .c3lh_search_filters_list_wrap:before {
    display: none;
  }

  .c3lh_search_filters_list_wrap:after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .c3lh_search_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .need_confirm_email .c3lh_search_filters_list_wrap2 {
    max-height: unset;
  }

  .c3lh_search_filters_list_wrap2 {
    max-height: unset;
  }

  .c3lh_search_filters_list_wrap {
    border-radius: 0;
  }

  .c3mp_video_list {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-top: 0;
  }

  .c3mp_videos_list_wrap {
    padding-top: 0;
  }

  .c3mp_videos_list_wrap .c3mp_video_list_item {
    max-width: 390px;
  }

  .popup_mp_video_buttons:after {
    bottom: -8px;
    top: unset;
  }

  .popup_mp_video_buttons:before {
    bottom: -8px;
    top: unset;
    transform: rotate(45deg);
  }

  .popup_mp_video_buttons {
    top: unset;
    bottom: calc(100% - 0px);
  }
}

iframe #player {
  width: 100% !important;
  max-width: 100% !important;
  /*height: auto!important; */
}

/*
.c3lh_your_likes_video_item iframe {position:absolute;top:0;left:0;width:100%;height:100%;}
*/

.opacity_layer {
  opacity: 0.3;
}

.c3mp_video_list_item.main_video_list_item {
  width: 100%;
}

.main_video_list_item .c3mp_video_list_item_img {
  max-width: 100%;
  max-height: 100%;
  /*height: 100%;*/
}

