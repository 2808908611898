@import "@sitestyles";

/*LETTER MEDIA*/


@media screen and (max-width: 1749px) {

  .c2l_compose_btn {
    width: 230px;
  }
}

@media screen and (max-width: 1649px) {

  .c3l_letters_list_write_wrap {
    /*height: 300px;*/
  }

  .c3_write_msg_textareas textarea:focus {
    /*height: 200px;*/
  }

  .c3l_letters_list_box_wrap {
    /*height: calc(100% - 305px);*/
  }
}

@media screen and (max-width: 1549px) {
  .c3l_men_list_item_from_name {
    font-size: 16px;
  }

  .c3l_men_list_item_users {
    width: 310px;
  }
}

@media screen and (max-width: 1449px) {
  .c3l_men_list_item_main_top {
    padding: 10px 15px 10px 10px;
  }

  .c3l_men_list_item_main_bottom_cost_text {
    font-size: 10px;
  }

  .c3l_men_list_item_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 1139px) {
  //.c3l_letters_list_write_bottom .c3_smile_btn{
  //	width: 28px;
  //	height: 28px;
  //	background-image: url(../../img/halloween/emoji.svg);
  //	background-position: 50%;
  //}
}

@media screen and (max-height: 700px) {
  .popup_support_textarea {
    height: 150px;
  }
}

@media (hover: none) and (pointer: coarse) {
  @media screen and (min-width: 1140px) {
    .c3l_men_list_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c3l_men_list_wrap2 {
      //height: auto;
      flex: 1;
      padding-right: 0;
    }

    .need_confirm_email .c3l_men_list_wrap2 {
      //height: auto;
    }

    .c3l_men_list_wrap2::-webkit-scrollbar {
      width: 3px;
    }

    .c2l_letter_filters_wrap2 {
      //height: calc(100vh - 500px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 500px);
    }

    .c3l_letters_list_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c3l_letters_list_wrap2 {
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .c3l_letters_list_write_top {
      height: auto;
    }

    .c3_write_msg_textareas textarea {
      display: block;
    }

    .need_confirm_email .c3l_letters_list_wrap2 {
      height: auto;
    }

    .c3l_letters_list_write_wrap {
      height: max-content;
    }

    .c3l_letters_list_box_wrap {
      /*height: auto;*/
      /*flex: 1;*/
      padding-bottom: 0;
    }

    .column_2_letters_wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .column_2_letters_mob_scroll {
      flex: 1;
      overflow-y: auto;
      margin-right: 5px;
    }

    .column_2_letters_mob_scroll::-webkit-scrollbar {
      width: 3px;
      background: $whiteColor;
      border-radius: 3px;
    }

    .column_2_letters_mob_scroll::-webkit-scrollbar-thumb {
      background: $scrollbarThumbColor;
      border-radius: 3px;
    }

    .c3l_letters_list_write_bottom {
      margin-top: 10px;
    }

    .popup_write_letter .c3l_letters_list_write_area {
      display: flex;
      flex-direction: column;
    }

    .popup_write_letter .c3l_letters_list_write_top {
      flex: 1;
    }
  }
}

@media screen and (max-width: 999px) {
  .column_2_letters_wrap {
    width: 266px;
    background-color: $background1Color;
  }

  .column-2.letters .c2_close {
    right: 13px;
  }
}

@media (hover: none) and (pointer: coarse) {

  /*.c3l_letters_list_wrap2{*/
  /*	display: flex;*/
  /*	flex-direction: column;*/
  /*}*/

  .c3l_letters_list_box_wrap {
    /*height: auto;*/
    /*flex: 1;*/
  }

  .c3l_letters_list_write_wrap {
    height: auto;
  }

  .c3l_letters_list_write_top {
    height: auto;
  }

  .c3_write_msg_textareas textarea {
    height: 50px;
  }

  .c3_write_msg_textareas textarea.large {
    height: 177px;
  }

  @media screen and (min-width: 600px) {
    .c3l_letters_list_write_wrap {
      background-color: #fff;
      padding: 0;
      padding-bottom: 5px;
    }
  }

  @media screen and (min-width: 1000px) {
    .c2l_letter_folders {
      height: auto;
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: 1139px) {
    .c3l_men_list_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c3l_men_list_wrap2 {
      //height: auto;
      flex: 1;
      padding-right: 5px;
    }

    .need_confirm_email .c3l_men_list_wrap2 {
      //height: auto;
    }

    .c3l_men_list_wrap2::-webkit-scrollbar {
      width: 3px;
    }

    .c2l_letter_filters_wrap2 {
      //height: calc(100vh - 500px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 500px);
    }

    .c3l_letters_list_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .c3l_letters_list_wrap2 {
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .need_confirm_email .c3l_letters_list_wrap2 {
      height: auto;
    }

    .c3l_letters_list_box_wrap {
      /*height: calc(100% - 274px);*/
      padding-bottom: 0;
    }

    .column_2_letters_wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .column_2_letters_mob_scroll {
      flex: 1;
      overflow-y: auto;
      margin-right: 5px;
    }

    .column_2_letters_mob_scroll::-webkit-scrollbar {
      width: 3px;
      background: $whiteColor;
      border-radius: 3px;
    }

    .column_2_letters_mob_scroll::-webkit-scrollbar-thumb {
      background: $scrollbarThumbColor;
      border-radius: 3px;
    }
  }

  @media screen and (max-width: 999px) {
    .click_to_view {
      height: 40px;
      line-height: 40px;
    }

    .popup_write_letter .c3l_letters_list_write_go_to_chat {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 899px) {
    .c2l_letter_filters_wrap2 {
      /*height: calc(100vh - 400px);
      height: calc(calc(var(--vh, 1vh) * 100) - 400px);
*/
      height: 100%;
    }

    .c3l_big_avatar {
      display: none !important;
    }

    .c3l_men_list_item_from_photo:hover:before {
      display: none;
    }

    .c3l_men_list_item_from_photo:hover img {
      border-color: transparent;
    }
  }

  @media screen and (max-width: 700px) {
    .popup_compose_head {
      justify-content: center;
    }

    .popup_compose_head_choosen_filters {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .popup_compose_head_right {
      -ms-align-items: center;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 599px) {
    .c3l_men_list_wrap {
      padding-right: 5px;
    }

    .c3l_men_list_wrap .c3l_mob_folder {
      display: flex;
      align-items: center;
    }

    .c3l_mob_folder_text {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: $accentLeftColor;
    }

    .c3l_mob_folder svg{
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: $accentLeftColor;
    }

    .c3l_mob_folder.outbox svg{
      transform: rotate(180deg);
    }
    //
    //.c3l_mob_folder_img {
    //  width: 24px;
    //  height: 24px;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  margin-right: 8px;
    //}
    //
    //.c3l_mob_folder_img.inbox {
    //  background-image: url(../../img/ml-inbox.svg);
    //}
    //.c3l_mob_folder_img.drafts {
    //  background-image: url(../../img/ml-drafts.svg);
    //}
    //.c3l_mob_folder_img.outbox {
    //  background-image: url(../../img/ml-outbox.svg);
    //}
    //.c3l_mob_folder_img.trash {
    //  background-image: url(../../img/ml-trash.svg);
    //}
    //.c3l_mob_folder_img.bookmarks {
    //  background-image: url(../../img/ml-bookmarks.svg);
    //}

    .c3l_men_list_wrap .c2l_compose_wrap {
      display: flex;
      align-items: center;
      padding-top: 0;
      height: 47px;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 8px;
    }

    .c3l_men_list_wrap .c2l_compose_btn {
      width: auto;
      height: 35px;
      line-height: 1;
      position: relative;
      padding: 0 16px;
      box-sizing: border-box;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    //.c3l_men_list_wrap .c2l_compose_btn:before {
    //  content: "";
    //  display: block;
    //  width: 17px;
    //  height: 14px;
    //  //position: absolute;
    //  left: 25px;
    //  top: 10px;
    //  background-image: url(../../img/letter-white.svg);
    //  background-repeat: no-repeat;
    //  background-position: center;
    //}

    //.c3l_men_list_wrap .c2l_compose_btn img {
    //  display: none;
    //}

    .c3l_men_list_wrap2 {
      //height: calc(100vh - 147px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 147px);
      padding-bottom: 16px;
      padding-left: 15px;
      padding-top: 12px;
    }

    .need_confirm_email .c3l_men_list_wrap2 {
      //height: calc(100vh - 147px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 147px);
    }

    .c3l_letters_list_item {
      padding: 16px;
    }

    .c3l_men_list_item:last-child {
      margin-bottom: 0;
    }

    .c3l_letters_list_write_attach_btn {
      margin-right: 5px;
    }

    .c3l_letters_list_write_area .c3l_letters_list_write_attach_btn img {
      width: 22px;
    }

    .c3l_letters_list_write_smile {
      margin-right: 3em;
    }

    .c3l_letters_list_write_area .c3l_letters_list_write_smile img {
      width: 20px;
    }

    .c3l_letters_list_write_area .c3_send_btn_cost_text {
      top: calc(100% + 6px);
    }

    .c3l_men_list_item_main_top {
      display: block;
      padding: 8px;
    }

    .c3l_men_list_item_main_bottom {
      margin-top: 0;
      margin: 0 16px;
    }

    .c3l_men_list_item_text {
      padding-left: 6px;
      font-size: 12px;
      line-height: 1.5;
      max-height: 56px;
    }

    .c3l_men_list_item_time {
      top: 56px;
      right: 10px;
      font-size: 10px;
      line-height: 150%;
    }

    .c3l_men_list_item_from_name {
      font-size: 17px;
    }

    .letters_attach_files_list {
      padding-bottom: 8px;
    }

    .c3l_men_list_item_users {
      margin-right: 0;
    }

    .c3l_men_list_item {
      display: block;
    }

    .c3l_men_list_item_options_wrap {
      /*width: 100%;
      min-height: unset;
*/
      width: 49px;
    }

    .c3l_men_list_item_btn img {
      width: 17px;
    }

    .c3l_men_list_item_options {
      /*display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
*/
    }

    .c3l_men_list_item_main {
      width: 100%;
      //width: calc(100% - 39px);
    }

    .c3l_men_list_item_btn_wrap {
      margin: 5px;
    }

    .c3l_letters_list_wrap2 {
      padding: 10px 5px 10px 10px;
    }

    .need_confirm_email .c3l_letters_list_wrap2 {
      //height: calc(100vh - 180px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 180px);
    }


    .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn span {
      display: none;
      /*position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: $whiteColor;
      padding: 5px 10px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
*/
    }

    .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn img {
      margin-left: 0;
    }

    .c3l_men_list_item_btn_wrap:hover .c3l_bookmark_ico {
      margin-left: 0;
    }

    .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn.c3l_bookm_btn {
      padding-right: 7px;
    }

    .c3l_men_list_item_btn {
      border-radius: 5px 5px 0 0;
    }

    .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn svg {
      margin-left: 2px;
    }

    .c3l_letters_list_write_bottom {
      align-items: center;
      margin-top: 10px;
      padding-left: 7px;
      position: relative;
    }

    .c3l_letters_list_write_go_to_chat {
      margin-top: 0px;
      overflow: hidden;
    }

    .c3l_letters_list_write_attach_btn .c3_attach_text {
      left: 50%;
      top: calc(100% + 4px);
      transform: translateX(-50%);
    }

    .popup_write_letter .c3l_letters_list_write_go_to_chat span {
      display: none;
    }

    .popup_write_letter .c3l_letters_list_write_go_to_chat img {
      margin-right: 0;
    }

    .popup_write_letter .c3l_letters_list_write_go_to_chat svg {
      margin-right: 0;
    }

    .c3l_letters_list_write_bottom_right {
      justify-content: space-between;
    }

    .c3l_letters_list_write_bottom_right .c3_send_btn {
      margin-left: 18px;
    }

    .c3l_letters_list_write_top {
      background-color: $background5Color;
      height: auto;
    }

    .c3l_letters_list_write_top .c3_write_msg_textareas textarea {
      height: 100%;
      padding: 10px;
      font-size: 14px;
      background-color: $background5Color;
    }

    .c3_send_btn.unpaid {
    }

    .c3l_letters_list_write_go_to_chat {
      width: 49px;
    }

    .c3l_letters_list_write_go_to_chat img {
      margin-right: 0;
      width: 23px;
    }

    .c3l_letters_list_write_go_to_chat svg {
      margin-right: 0;
      width: 23px;
      height: 23px;
    }

    .c3l_letters_list_write_go_to_chat span {
      display: none;
    }

    .mob_notif_fixed {
      top: 7px;
      height: 36px;
      width: 62px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mob_notif_fixed img {
      height: 24px;
    }

    .c3l_men_list_item_options_wrap {
      /*margin-left: 0;
      padding-left: 0;
      padding-top: 10px;
      margin-top: -10px;
      -webkit-border-radius: 0 0 8px 8px;
      -moz-border-radius: 0 0 8px 8px;
      border-radius: 0 0 8px 8px;
*/
    }

    .c3l_men_list_item_from_photo img {
      width: 56px;
      height: 56px;
      margin: 0;
      border: none;
    }

    .c3l_men_list_item_from_photo:after {
      width: 6px;
      height: 6px;
      border-width: 1px;
      right: 3px;
      bottom: 4px;
    }

    .c3l_men_list_item_from_id {
      font-size: 10px;
    }

    .c3l_men_list_item_from_name {
      font-size: 14px;
      margin: 1px 0 2px;
    }

    .c3l_men_list_item_from_place {
      font-size: 10px;
    }

    .popup_compose_men_list_wrap2 {
      grid-template-columns: repeat(auto-fill, 230px);
    }

    .popup_compose_head {
      justify-content: center;
    }

    .popup_compose_head_right {
      margin-top: 16px;
    }

    .popup_compose_letter {
      padding: 10px;
    }

    .popup_compose_men_list_wrap {
      padding-left: 10px;
      padding-right: 10px;
    }

    .popup_compose_man_item_label {
      width: 230px;
      padding: 0 16px;
    }

    .popup_compose_man_item_photo img {
      width: 60px;
      height: 60px;
    }

    .popup_compose_man_item_name {
      font-size: 14px;
    }

    .c3_head_photo > img {
      height: 32px;
      width: 32px;
    }

    .react-tabs__tab-panel.react-tabs__tab-panel--selected {
      height: calc(100% - 110px);
      padding-top: 10px;
    }

    .popup_bottom_upload_btn {
      bottom: 10px;
      left: 10px;
      width: 53px;
    }

    .popup_bottom_upload_btn_info {
      margin-left: 53px;
      bottom: 0;
    }

    .popup_bottom_upload_btn:hover + .popup_bottom_upload_btn_info {
      display: none;
    }

    .rh5v-Overlay_inner {
      width: 70px;
      height: 70px;
    }

    .rh5v-DefaultPlayer_controls {
      opacity: 1;
      display: none;
    }

    .rh5v-DefaultPlayer_video {
      height: auto;
    }

    .popup_bottom_upload_btn img {
      margin-right: 0;
    }

    .popup_bottom_upload_btn span {
      display: none;
    }

    .popup_bottom_send_btn {
      right: 10px;
      bottom: 0;
      width: 177px;
    }

    .chat_message_wrap.right .message_text {
      background-color: $background3Color;
    }

    .chat_message_wrap.right .message_text.wink {
      background-color: transparent;
    }

    .upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video {
      margin-right: 0;
    }

    .upload_popup_wrap .close_one_popup {
      right: 5px;
      top: 5px;
    }

    .c3l_letters_list_box_wrap {
      /*height: calc(100% - 196px);*/
      margin-right: 0;
      padding-right: 3px;
      /*flex: 1;*/
    }

    .c3l_letters_list_box_wrap.active-smile {
      height: calc(100% - 320px);
    }

    .c3l_letters_list_item_text {
      /*max-height: 117px;
*/
    }

    /*.c3l_letters_list_write_wrap*/
    .c3_write_msg_textareas_files .letters_attach_files_item.file_photo img {
      height: 40px;
    }

    /*.c3l_letters_list_write_wrap*/
    .c3_write_msg_textareas_files .letters_attach_files_item.file_photo {
      /*width: 100px;
*/
    }

    /*.c3l_letters_list_write_wrap*/
    .c3_write_msg_textareas_files .letters_attach_files_item {
      padding: 3px 0 3px 3px;
      /*height: 46px;
*/
    }

    .c3_write_msg_textareas_files .letters_attach_files_item.file_audio {
      /*background-color: $whiteColor;
*/
      height: 40px;
    }

    .c3l_letters_list_write_wrap .c3_write_msg_textareas_files {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
      margin: 5px 10px 0;
      height: max-content;
      flex: none;
      background-color: transparent;
    }

    .c3_write_msg_textareas.with_files {
      background-color: transparent;
    }

    .c3l_letters_list_wrap .c3_top {
      /*display: none;
*/
    }

    .c3_write_msg_textareas .c3l_form_item_btn {
      bottom: 2px;
    }

    .c3_chat_send_btn_cost_text {
      top: 50%;
      right: calc(100% + 10px);
      width: 55px;
      text-align: right;
      font-size: 9px;
      transform: translateY(-50%);
    }

    .c3_chat_send_btn_cost_text span {
      display: none;
    }

    .c3l_letters_list_write_wrap {
      height: 186px;
      margin: 11px -5px 0 -10px;
      padding: 0px 10px;
      padding-bottom: 15px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-color: $whiteColor;
    }

    .popup_write_letter .c3l_letters_list_write_go_to_chat {
      width: 49px;
    }

    .c2l_letter_filters_wrap2 {
      height: auto;
    }

    .c2l_letter_filters_box {
      margin-bottom: 40px;
    }

    .c3l_letters_list_history_btn {
      width: 289px;
      font-size: 14px;
    }

    .c3l_letters_list_history_btn img {
      width: 14px;
    }

    .c3l_letters_list_history_btn svg {
      width: 16px;
      height: 16px;
    }

    .popup_compose_men_list_wrap3 {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 16px;
    }

    .popup_compose_men_list_wrap2 {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 0;
      grid-gap: 10px;
    }

    .popup_compose_men_list_wrap {
      flex: 1;
      overflow-x: hidden;
      padding: 0;
      margin-top: 10px;
    }

    .popup_compose_letter {
      display: flex;
      flex-direction: column;
      //height: 100%;
      //overflow: auto;
      padding: 16px;
      height: calc(100% - 16px);
      //overflow: hidden;
      //padding: 16px;
      //margin: 8px 0 auto 8px;
      width: calc(100% - 16px);
    }

    .popup_compose_man_item_label {
      width: 100%;
    }

    .popup_compose_add_men_btn {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    .popup_compose_head_search input {
      width: 215px;
    }

    .popup_compose_head_choosen_filters {
      margin-right: 0;
    }

    .popup_write_letter_head {
      padding: 0 20px;
    }

    .popup_write_letter_user:after {
      top: 50%;
      transform: translateY(-50%);
    }

    .popup_write_letter_user_to_id {
      display: none;
    }

    .popup_compose_head_right {
      margin-top: 10px;
    }

    .c3l_men_list_item_status {
      right: 5px;
      top: 5px;
    }

    .c3l_men_list_item_status.answered {
      width: auto;
      //right: 5px;
    }

    .c3l_men_list_item_status.unanswered {
      padding: 0;
      width: 70px;
    }

    .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn {
      background-color: transparent;
      box-shadow: unset;
    }

    .c3l_letters_list_history_wrap {
      padding: 9px 0;
    }

    .popup_write_letter .c3l_letters_list_write_bottom_count {
      max-width: 80px;
      margin-left: 0;
      position: absolute;
      right: 67px;
      text-align: right;
    }


    .popup_write_letter .c3_send_btn {
      position: unset;
    }

    .popup_write_letter .c3_send_btn_cost_text {
      top: calc(100% + 2px);
    }
  }

  @media screen and (max-height: 610px) {
    .popup_support_textarea {
      height: 110px;
    }
  }

  @media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
    .mob_notif_fixed {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 34px;
      left: 0;
      top: 11px;
      border-radius: 0 5px 5px 0;
    }

    .c3_chat_send_btn_cost_text {
      top: -1px;
      right: calc(100% + 10px);
      width: 82px;
      text-align: right;
      font-size: 9px;
    }

    .c2l_letter_filters_wrap2 {
      height: auto;
    }

    .c2l_letter_folders {
      height: auto;
      margin-bottom: 40px;
    }

    .c2l_letter_filters_wrap {
      overflow: visible;
    }

    .column_2_letters_wrap {
      height: 100%;
      overflow-y: auto;
    }

    .c3l_men_list_wrap2 {
      //height: 100vh;
      //height: calc(var(--vh, 1vh) * 100);
      padding-right: 5px;
      padding-left: 10px;
    }

    .need_confirm_email .c3l_men_list_wrap2 {
      //height: 100vh;
      //height: calc(var(--vh, 1vh) * 100);
    }

    .c3l_letters_list_wrap2 {
      /*height: calc(100vh - 75px);*/
      /*height: calc(calc(var(--vh, 1vh) * 100) - 75px);*/
      padding: 8px 16px 0;
      display: flex;
      flex-direction: row;
    }

    .need_confirm_email .c3l_letters_list_wrap2 {
      /*height: calc(100vh - 75px);*/
      /*height: calc(calc(var(--vh, 1vh) * 100) - 75px);*/
    }

    .c3l_letters_list_write_top .c3_write_msg_textareas textarea {
      height: 100%;
      padding: 10px;
      font-size: 14px;
    }

    .c3l_letters_list_write_top {
      /*height: calc(100% - 65px);*/
    }

    .c3l_letters_list_write_wrap {
      margin-top: 0px;
      height: 100%;
      padding: 16px;
      width: 40%;
      flex: none;
      margin-left: 0;
    }

    .c3l_letters_list_box_wrap {
      /*height: 100%;*/
      padding-right: 5px;
      padding-bottom: 0;
      width: 60%;
      margin-right: 10px;
    }

    .column_3_letters_page #mobile-chat .c3l_letters_list_box_wrap {
      width: 100%;
      margin-right: 0;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat {
      width: 36px;
      height: 32px;
      background-image: url(../../img/in-letter-back.svg);
      background-position: center;
      background-repeat: no-repeat;
    }

    .c3l_letters_list_write_attach_btn .c3_attach_text {
      left: 50%;
      top: calc(100% + 4px);
      transform: translateX(-50%);
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat span {
      display: none;
    }

    .c3l_letters_list_write_area .c3l_letters_list_write_smile img {
      width: 20px;
    }

    .c3l_letters_list_write_area .c3l_letters_list_write_attach_btn img {
      width: 22px;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_bottom_count {
      max-width: 66px;
      margin-left: 7px;
      font-size: 11px;
      text-align: center;
    }

    .c3l_letters_list_write_smile {
      margin-right: 5px;
    }

    .c3l_letters_list_write_attach_btn {
      margin-right: 13px;
    }

    .c3l_letters_list_item {
      padding: 16px;
    }

    .popup_compose_letter {
      min-height: unset;
      padding: 32px 16px 8px;
    }

    .popup_write_letter {
      height: auto;
      padding: 32px 16px;
    }

    .popup_write_letter .c3l_letters_list_write_top {
      height: 135px;
    }

    .c3l_letters_list_write_bottom_right .c3_send_btn {
      width: 48px;
      height: 32px;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 699px) and (max-height: 450px) and (min-width: 420px) {
    .c3l_letters_list_write_wrap .c3l_letters_list_write_go_to_chat {
      display: none;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_bottom_count {
      margin-left: 0;
    }

    .c3l_letters_list_write_wrap {
      padding: 8px;
    }
  }

  @media screen and (max-width: 599px) and (max-height: 450px) and (min-width: 420px) {
    .c3l_men_list_wrap2 {
      //height: calc(100vh - 60px);
      //height: calc(calc(var(--vh, 1vh) * 100) - 60px);
      padding-top: 0;
    }
    .c3l_men_list_wrap .c2l_compose_wrap {
      padding-bottom: 13px;
    }
    .popup_compose_letter {
      padding-top: 16px;
    }

    .popup_compose_head_right {
      margin-top: 0;
    }

    .popup_compose_men_list_wrap2 {
      grid-column-gap: 10px;
    }

    .popup_write_letter .c3l_letters_list_write_top {
      height: 110px;
    }

    .c3l_men_list_item_main {
      width: calc(100% - 56px);
    }

    .c3l_men_list_item_options_wrap {
      width: 66px;
      min-height: 130px;
      background-color: $bleachColor;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      justify-content: center;
      border-radius: 0 8px 8px 0;
      padding-left: 10px;
      margin-left: -10px;
      padding-top: 0;
      margin-top: 0;
    }

    .c3l_men_list_item {
      display: flex;
    }

    .c3l_men_list_item_options {
      display: block;
    }

    .popup_compose_man_item_label {
      height: 80px;
    }

    .popup_compose_men_list_wrap2 {
      grid-template-rows: repeat(1, 80px);
      padding-bottom: 16px;
    }

    .popup_compose_men_list_wrap3 {
      padding-top: 16px;
    }

    .popup_compose_men_list_wrap {
      padding-bottom: 16px;
    }

    .popup_compose_add_men_btn {
      margin-top: 16px;
    }

    .c3l_letters_list_write_wrap {
      padding: 8px;
    }

    .c3l_letters_list_write_wrap .c3l_letters_list_write_bottom_count {
      display: none;
    }

    .c3l_letters_list_write_bottom_right {
      width: 100%;
    }

    .popup_write_letter .c3l_letters_list_write_bottom_right {
      width: auto;
    }

    .popup_write_letter .c3l_letters_list_write_area .c3_send_btn_cost_text {
      width: max-content;
    }

    .c3l_letters_list_write_bottom_right .c3_send_btn {
      margin-left: 0;
    }

    .c3l_letters_list_write_area .c3_send_btn_cost_text {
      width: 140px;
      white-space: unset;
      top: calc(100% + 5px);
      text-align: right;
    }

    .c3l_letters_list_write_bottom {
      margin-top: 5px;
    }

    .c3l_letters_list_write_top {
      height: calc(100% - 70px);
    }
  }
}

@media screen and (max-width: 599px) {

  .c3l_men_list_item_from_to {
    margin-right: 8px;
    margin-left: 0;
  }

  .c2l_letter_filters_wrap {
    overflow: visible;
  }

  .c3l_men_list_wrap {
    padding-right: 0;
  }

  .c3l_men_list_wrap .c3l_mob_folder {
    display: flex;
    align-items: center;
  }

  .c3l_mob_folder_text {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $accentLeftColor;
  }

  .c3l_mob_folder_img {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
  }

  .c3l_mob_folder_img.inbox {
    background-image: url(../../img/ml-inbox.svg);
  }
  .c3l_mob_folder_img.drafts {
    background-image: url(../../img/ml-drafts.svg);
  }
  .c3l_mob_folder_img.outbox {
    background-image: url(../../img/ml-outbox.svg);
  }
  .c3l_mob_folder_img.trash {
    background-image: url(../../img/ml-trash.svg);
  }
  .c3l_mob_folder_img.bookmarks {
    background-image: url(../../img/ml-bookmarks.svg);
  }

  .c3l_men_list_wrap .c2l_compose_wrap {
    display: flex;
    height: auto;
    padding: 8px 8px 8px 15px;
    justify-content: space-between;
  }

  .c3l_men_list_wrap .mobile_c2l_compose_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    padding: 8px 3px 8px 8px;
    height: auto;
  }

  .c3l_men_list_wrap .c2l_compose_btn {
    width: 145px;
    height: 35px;
    line-height: 1;
    position: relative;
    padding-left: 22px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //.c3l_men_list_wrap .c2l_compose_btn:before {
  //  content: "";
  //  display: block;
  //  width: 24px;
  //  height: 24px;
  //  background-image: url(../../img/letter-white.svg);
  //  background-repeat: no-repeat;
  //  background-position: center;
  //  background-size: contain;
  //  margin: auto 4px auto 0;
  //}

  //.c3l_men_list_wrap .c2l_compose_btn img {
  //  display: none;
  //}

  .c3l_men_list_wrap2 {
    //height: calc(100vh - 147px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 147px);
    padding-bottom: 16px;
    padding-left: 10px;
    padding-top: 8px;
    padding-right: 10px;
  }

  .need_confirm_email .c3l_men_list_wrap2 {
    //height: calc(100vh - 147px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 147px);
  }

  .c3l_letters_list_item {
    padding: 16px;
  }

  .c3l_men_list_item:last-child {
    margin-bottom: 0;
  }

  .c3l_letters_list_write_attach_btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .c3l_letters_list_write_area .c3l_letters_list_write_attach_btn img {
    width: 22px;
  }

  .c3l_letters_list_write_smile {
    margin-right: 3em;
  }

  .c3l_letters_list_write_area .c3l_letters_list_write_smile img {
    width: 20px;
  }

  .c3l_letters_list_write_area .c3_send_btn_cost_text {
    top: calc(100% + 6px);
  }

  .c3l_men_list_item_main_top {
    display: block;
    padding: 10px;
  }

  .c3l_men_list_item_main_bottom {
    margin-top: 0;
    margin: 0 16px;
  }

  .c3l_men_list_item_text {
    padding-left: 6px;
    font-size: 12px;
    line-height: 1.5;
    max-height: 56px;
  }

  .c3l_men_list_item_time {
    top: 56px;
    right: 10px;
    bottom: auto;
  }

  .c3l_men_list_item_from_name {
    font-size: 17px;
  }

  .letters_attach_files_list {
    padding-bottom: 8px;
  }

  .c3l_men_list_item_users {
    margin-right: 0;
  }

  .c3l_men_list_item {
    display: block;
  }

  .c3l_men_list_item_options_wrap {
    /*width: 100%;
    min-height: unset;
*/
    width: 49px;
  }

  .c3l_men_list_item_btn img {
    width: 17px;
  }

  .c3l_men_list_item_options {
    /*display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
*/
  }

  .c3l_men_list_item_main {
    width: 100%;
    //width: calc(100% - 39px);
  }

  .c3l_men_list_item_btn_wrap {
    margin: 5px;
  }

  .c3l_letters_list_wrap2 {
    padding: 10px 5px 10px 10px;
  }

  .need_confirm_email .c3l_letters_list_wrap2 {
    //height: calc(100vh - 180px);
    //height: calc(calc(var(--vh, 1vh) * 100) - 180px);
  }


  .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn span {
    display: none;
    /*position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: $whiteColor;
    padding: 5px 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
*/
  }

  .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn img {
    margin-left: 0;
  }

  .c3l_men_list_item_btn_wrap:hover .c3l_bookmark_ico {
    margin-left: 0;
  }

  .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn.c3l_bookm_btn {
    padding-right: 7px;
  }

  .c3l_men_list_item_btn {
    border-radius: 5px 5px 0 0;
  }

  .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn svg {
    margin-left: 2px;
  }

  .c3l_letters_list_write_bottom {
    align-items: center;
    margin-top: 10px;
    //padding-left: 7px;
  }

  .c3l_letters_list_write_go_to_chat {
    margin-top: 0px;
    overflow: hidden;
  }

  .c3l_letters_list_write_attach_btn .c3_attach_text {
    position: static;
    transform: unset;
    margin-left: 8px;
  }

  .popup_write_letter .c3l_letters_list_write_go_to_chat span {
    display: none;
  }

  .popup_write_letter .c3l_letters_list_write_go_to_chat img {
    margin-right: 0;
  }

  .popup_write_letter .c3l_letters_list_write_go_to_chat svg {
    margin-right: 0;
  }

  .c3l_letters_list_write_bottom_right {
    justify-content: space-between;
    flex: 1;
  }

  .c3l_letters_list_write_bottom_right .c3_send_btn {
    margin-left: 18px;
  }

  .c3l_letters_list_write_top {
    background-color: $background5Color;
    height: auto;
  }

  .c3l_letters_list_write_top .c3_write_msg_textareas textarea {
    height: 50px;
    padding: 10px;
    font-size: 14px;
    background-color: $background5Color;
  }

  .c3l_letters_list_write_top .c3_write_msg_textareas textarea.large {
    height: 150px;
  }

  .c3_send_btn.unpaid {
  }

  .c3l_letters_list_write_go_to_chat {
    width: 49px;
  }

  .c3l_letters_list_write_go_to_chat img {
    margin-right: 0;
    width: 23px;
  }

  .c3l_letters_list_write_go_to_chat svg {
    margin-right: 0;
    width: 23px;
    height: 23px;
  }

  .c3l_letters_list_write_go_to_chat span {
    display: none;
    font-size: 15px;
  }

  .mob_notif_fixed {
    top: 7px;
    height: 36px;
    width: 62px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mob_notif_fixed img {
    height: 24px;
  }

  .c3l_men_list_item_options_wrap {
    /*margin-left: 0;
    padding-left: 0;
    padding-top: 10px;
    margin-top: -10px;
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
*/
  }

  .c3l_men_list_item_from_photo img {
    width: 56px;
    height: 56px;
    margin: 0;
    border: none;
  }

  .c3l_men_list_item_from_photo:after {
    width: 6px;
    height: 6px;
    border-width: 1px;
    right: 3px;
    bottom: 4px;
  }

  .c3l_men_list_item_from_id {
    font-size: 10px;
  }

  .c3l_men_list_item_from_name {
    font-size: 14px;
    margin: 1px 0 2px;
  }

  .c3l_men_list_item_from_place {
    font-size: 10px;
  }

  .popup_compose_men_list_wrap2 {
    grid-template-columns: repeat(auto-fill, 230px);
  }

  .popup_compose_head {
    justify-content: center;
  }

  .popup_compose_head_right {
    margin-top: 16px;
  }

  .popup_compose_letter {
    padding: 10px;
  }

  .popup_compose_men_list_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup_compose_man_item_label {
    width: 230px;
    padding: 0 16px;
  }

  .popup_compose_man_item_photo img {
    width: 60px;
    height: 60px;
  }

  .popup_compose_man_item_name {
    font-size: 14px;
  }

  .c3_head_photo > img {
    height: 32px;
    width: 32px;
  }

  .react-tabs__tab-panel.react-tabs__tab-panel--selected {
    height: calc(100% - 110px);
    padding-top: 10px;
  }

  .popup_bottom_upload_btn {
    bottom: 10px;
    left: 10px;
    width: 53px;
  }

  .popup_bottom_upload_btn_info {
    margin-left: 53px;
    bottom: 0;
  }

  .popup_bottom_upload_btn:hover + .popup_bottom_upload_btn_info {
    display: none;
  }

  .rh5v-Overlay_inner {
    width: 70px;
    height: 70px;
  }

  .rh5v-DefaultPlayer_controls {
    opacity: 1;
    display: none;
  }

  .rh5v-DefaultPlayer_video {
    height: auto;
  }

  .popup_bottom_upload_btn img {
    margin-right: 0;
  }

  .popup_bottom_upload_btn span {
    display: none;
  }

  .popup_bottom_send_btn {
    right: 10px;
    bottom: 0;
    width: 177px;
  }

  .chat_message_wrap.right .message_text {
    background-color: $background3Color;
  }

  .chat_message_wrap.right .message_text.wink {
    background-color: transparent;
  }

  .upload_popup_tabs_content_middle.photo, .upload_popup_tabs_content_middle.video {
    margin-right: 0;
  }

  .upload_popup_wrap .close_one_popup {
    right: 5px;
    top: 5px;
  }

  .c3l_letters_list_box_wrap {
    /*height: calc(100% - 196px);*/
    margin-right: 0;
    padding-right: 3px;
    /*flex: 1;*/
  }

  .c3l_letters_list_box_wrap.active-smile {
    height: calc(100% - 320px);
  }

  .c3l_letters_list_item_text {
    /*max-height: 117px;
*/
  }

  /*.c3l_letters_list_write_wrap*/
  .c3_write_msg_textareas_files .letters_attach_files_item.file_photo img {
    height: 40px;
  }

  /*.c3l_letters_list_write_wrap*/
  .c3_write_msg_textareas_files .letters_attach_files_item.file_photo {
    /*width: 100px;
*/
  }

  /*.c3l_letters_list_write_wrap*/
  .c3_write_msg_textareas_files .letters_attach_files_item {
    padding: 3px 0 3px 3px;
    /*height: 46px;
*/
  }

  .c3_write_msg_textareas_files .letters_attach_files_item.file_audio {
    /*background-color: $whiteColor;
*/
    height: 40px;
  }

  .c3l_letters_list_write_wrap .c3_write_msg_textareas_files {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin: 5px 10px 0;
    height: max-content;
    flex: none;
    background-color: transparent;
  }

  .c3_write_msg_textareas.with_files {
    background-color: transparent;
  }

  .c3l_letters_list_wrap .c3_top {
    /*display: none;
*/
  }

  .c3_write_msg_textareas .c3l_form_item_btn {
    bottom: 2px;
  }

  .c3_chat_send_btn_cost_text {
    top: 50%;
    right: calc(100% + 10px);
    //width: 46px;
    text-align: right;
    font-size: 9px;
    transform: translateY(-50%);
  }

  .c3_chat_send_btn_cost_text span {
    display: none;
  }

  .c3l_letters_list_write_area .c3_write_msg_textareas,
  .c3l_letters_list_write_area {
    height: auto;
  }

  .c3l_letters_list_write_wrap {
    height: auto;
    margin: 11px -5px 0 -10px;
    padding: 0px 10px;
    padding-bottom: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: $whiteColor;
  }

  .c3l_letters_list_write_area .mob-smiles {
    margin-bottom: 85px;
    margin-top: 25px;
  }

  .popup_write_letter .c3l_letters_list_write_go_to_chat {
    width: 49px;
  }

  .c2l_letter_filters_wrap2 {
    height: auto;
  }

  .c2l_letter_filters_box {
    margin-bottom: 40px;
  }

  .c3l_letters_list_history_btn {
    width: 289px;
    font-size: 14px;
  }

  .c3l_letters_list_history_btn img {
    width: 14px;
  }

  .c3l_letters_list_history_btn svg {
    width: 16px;
    height: 16px;
  }

  .popup_compose_men_list_wrap3 {
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 16px;
  }

  .popup_compose_men_list_wrap2 {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    grid-gap: 10px;
  }

  .popup_compose_men_list_wrap {
    flex: 1;
    overflow-x: hidden;
    padding: 0;
    margin-top: 10px;
    max-height: 358px;
  }

  .popup_compose_letter {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    position: relative;
    width: calc(100% - 16px);
    margin: auto;
    height: 486px;
  }

  .popup_compose_man_item_label {
    width: 100%;
  }

  .popup_compose_add_men_btn {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .popup_compose_head_search input {
    width: 215px;
  }

  .popup_compose_head_choosen_filters {
    margin-right: 0;
  }

  .popup_write_letter_head {
    padding: 0 20px;
  }

  .popup_write_letter_user:after {
    top: 50%;
    transform: translateY(-50%);
  }

  .popup_write_letter_user_to_id {
    display: none;
  }

  .popup_compose_head_right {
    margin-top: 10px;
  }

  .c3l_men_list_item_status {
    right: 10px;
    top: 10px;
  }

  //.c3l_men_list_item_status.answered {
  //  right: 5px;
  //}

  .c3l_men_list_item_status.unanswered {
    padding: 0;
    width: 70px;
  }

  .c3l_men_list_item_btn_wrap:hover .c3l_men_list_item_btn {
    background-color: transparent;
    box-shadow: unset;
  }

  .c3l_letters_list_history_wrap {
    padding: 9px 0;
  }

  .popup_write_letter .c3l_letters_list_write_bottom_count {
    max-width: 80px;
    margin-left: 0;
    position: absolute;
    right: 67px;
    text-align: right;
  }

  .popup_write_letter .c3_send_btn_cost_text {
    top: calc(100% + 2px);
    white-space: unset;
  }

  .emoji-mart {

  }

  .c3l_letters_list_write_bottom .c3_smile_btn_wrap {
    justify-content: flex-start;
  }

  .c3l_letters_list_write_bottom .c3_smile_btn {
    width: 21px;
    height: 21px;
    background-size: contain;
  }

  .popup_compose_letter .close_one_popup,
  .popup_write_letter .close_one_popup {
    right: 8px;
    top: 8px;
  }
}

@media screen and (max-width: 899px) and (max-height: 450px) and (min-width: 420px) {
  .mob_notif_fixed {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 34px;
    left: 0;
    top: 11px;
    border-radius: 0 5px 5px 0;
  }

  .c2l_letter_filters_wrap {
    overflow: visible;
  }

  .column_2_letters_mob_scroll {
    overflow: auto;
  }
}